import React, {useState, useEffect} from 'react';
import { Line, Bar, Pie, Doughnut, Radar, PolarArea } from "react-chartjs-2";
import 'chart.js/auto';
import { Chart, CategoryScale } from 'chart.js';
Chart.register(CategoryScale);
import styles from './Analytics.module.css'

const PieChartAnalytics = ({data, labels}) => {
  const [position, setPosition] = useState('right');

  useEffect(() => {
    const legendPosition = () => {
      if (window.innerWidth > 700) {
        setPosition('right');
      } else {
        setPosition('top');
      }
    };
    legendPosition();

    window.addEventListener('resize', legendPosition);

    return () => {
      window.removeEventListener('resize', legendPosition);
    };
  }, []);

    const colors = [
      "#FF6384",
      "#36A2EB",
      "#FFCE56",
      "#4BC0C0",
      "#9966FF",
      "#FF9F40",
      "#32CD32",
      "#FF0000",
      "#800080",
      "#00f080",
      "#fffd9c",
      "#92c1e8",
    ];
    const chartData = {
        labels: labels,
        datasets: [
            {
                data: data,
                backgroundColor: colors.map(color => color + '9A'),
                hoverBackgroundColor: colors,
                hoverBorderColor: "#fff"
            }
        ]
    };

    const options = {
        plugins: {
            legend: {
                position: position,
                labels: {
                    font: {
                        family: "Montserrat",
                        size: "18px"
                    },
                    padding: 3
                }
            }
        }
    };
    return (
        <React.Fragment>
            <div className={styles.container}>
                <Pie data={chartData} options={options} className="chartjs-chart" />
            </div>
        </React.Fragment>
    );
};



export default PieChartAnalytics;
