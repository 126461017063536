import React, { useState, useEffect } from 'react';
import '../Common.css';
import {progressUpdateFromKaspiAPI} from "../../helpers/backend_helper";

const ProgressBar = ({isUpdating, setIsUpdating}) => {
    const [progress, setProgress] = useState(0);
    // const [error, setError] = useState(null);

    useEffect(() => {
        let intervalId;
        if (isUpdating) {
            // if (isUpdating) {
            // toast.warning('Идёт обновление товаров...', { position: 'top-right' });
            // }
            intervalId = setInterval(async () => {
                progressUpdateFromKaspiAPI()
                    .then((data) => {
                        const { progress, is_updating } = data;
                        setProgress(progress);
                        if (!is_updating) {
                            clearInterval(intervalId);
                            setIsUpdating(false);
                        }
                    })
                    .catch((error) => {
                        clearInterval(intervalId);
                        setIsUpdating(false);
                    });
            }, 2000);
        }
        return () => clearInterval(intervalId);
    }, []);

    return (
        <div style={{width: '100%'}}>
            {isUpdating && (
                <div id="progress-container">
                    <div
                        id="progress-bar"
                        style={{
                            width: `${progress}%`,
                            height: "15px",
                            backgroundColor: "#859ecc",
                            color: "black",
                            textAlign: 'center',
                            lineHeight: '30px',
                            borderRadius: '5px',
                        }}
                    >
                        <span>{`${progress}%`}</span>
                    </div>
                </div>
            )}
            {/*{error && <div className="error" style={{color: 'red', textAlign: 'center', marginTop: '5px'}}>{error}</div>}*/}
        </div>
    );
};

export default ProgressBar;