import React from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import axios from "axios";
import { Button } from "reactstrap";
import {createReceptionDraft} from "../../helpers/backend_helper";
import getCurrentDateTime from "../../slices/invoice/utils";


function ReceptionCreate() {
  // const accessToken = "t2nsWEmkLxVpwW2h3UnFBaRlSQDVo2";
  // axios.defaults.baseURL = "http://207.154.232.92/api";
  // axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  const navigate = useNavigate();

  // Function to create a reception and redirect
  const handleCreateReception = () => {
    // Define the reception data you want to create
    const receptionData = {
      draft: true,
      received_date: getCurrentDateTime()
    };

    // Send a POST request to create the reception
    // axios
    //   .post("/core/reception/", receptionData)
      createReceptionDraft(receptionData)
      .then((response) => {
        // Extract the created reception's ID from the response
        const createdReceptionId = response.id;

        // Redirect to the detail page with the created reception's ID
        navigate(`/reception-new/${createdReceptionId}`);
      })
      .catch((error) => {
        console.error("Error creating reception:", error);
      });
  };

  return (
    <div>
      {/* <a href="/apps-invoices-create"> */}
      <Button
        onClick={handleCreateReception}
        color="success"
        className="add-btn"
        id="create-btn"
      >
        <i className="ri-add-line align-bottom me-1"></i> Добавить приёмку
      </Button>{" "}
      {/* </a> */}
    </div>
    // <div>
    //     <h2>Create Reception</h2>
    //     <button onClick={handleCreateReception}>Create Reception</button>
    // </div>
  );
}

export default ReceptionCreate;
