import React from 'react';
import { Spinner } from 'reactstrap';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProgressBar from "./ProgressBar";

const Loader = ({isUpdating, setIsUpdating, ...props}) => {
    return (
        <React.Fragment>
            <div className="d-flex justify-content-center mx-2 mt-2"
                 style={isUpdating ? {alignItems: 'center', height: '20vh', flexDirection: 'column'} : {}}>
                <Spinner color="primary"> Загрузка... </Spinner>
                {isUpdating &&
                    <ProgressBar isUpdating={isUpdating} setIsUpdating={setIsUpdating} />
                }
            </div>
            {toast.error(props.error, { position: "top-right", hideProgressBar: false, progress: undefined, toastId: "" })}
        </React.Fragment>
    );
};

export default Loader;
