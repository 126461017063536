import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane,
} from "reactstrap";
import classnames from "classnames";

//import images
import userImg from "../../../../assets/images/users/user.png";
import warehouse_1 from "../../../../assets/images/warehouse_1.png";
import plus_2 from "../../../../assets/images/plus_2.png";
import trend_3 from "../../../../assets/images/trend_3.png";
import setting_4 from "../../../../assets/images/setting_4.png";
import edit from "../../../../assets/images/edit.png";

import {
  getInvitationsApi,
  getAccountsApi,
  postEditProfile,
  postPasswordChange,
  getChangeAccount,
  getAcceptInvitation,
  postCreateInvite,
  updateInvite,
  getSalesChannelsApi,
  createSalesChannelApi,
  deleteSalesChannelApi,
} from "../../../../helpers/backend_helper";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import BreadCrumb from "../../../../Components/Common/BreadCrumb";
import styles from "./Settings.module.css";

const Settings = () => {
  const [modalOpenOrganization, setModalOpenOrganization] = useState(false);
  const [modalOpenPermissions, setModalOpenPermissions] = useState(false);

  const [invitation, setInvitation] = useState({});
  const allAccessObjects = {
    'orders': {description: 'смотреть, создавать, редактировать', label: 'Заказы'},
    'receptions': {description: 'смотреть, создавать, редактировать', label: 'Приёмки'},
    'analytics': {description: 'отчеты прибыльность, обороты', label: 'Аналитика'},
    'products': {description: 'остатки, себестоимость', label: 'Товары'}
  };
  const handleModalPermissions = (invitation) => {
    // const data = {
    //   id: invitation.id,
    //   username: invitation.invited_user.username,
    //   email: invitation.invited_user.email,
    //   access: invitation.access,
    //   access_objects: invitation.access_objects
    // }
    setInvitation(invitation);
    // setErrorsPermissions({});
    setModalOpenPermissions(true);
  };

  const modalPermissionsClose = () => {
    setInvitation({});
    setModalOpenPermissions(false);
  };

  const handleModalOrganization = () => {
    setErrorsOrganization({});
    validationOrganization.resetForm();
    setModalOpenOrganization(true);
  };

  const modalOrganizationClose = () => {
    setModalOpenOrganization(false);
  };

  const dispatch = useDispatch();
  const history = useNavigate();

  const [errorsInvite, setErrorsInvite] = useState({});
  const [errorsOrganization, setErrorsOrganization] = useState({});
  // const [errorsPermissions, setErrorsPermissions] = useState({});
  const [activeTab, setActiveTab] = useState("1");

  const [authUser, setAuthUser] = useState({});
  const [accounts, setAccounts] = useState({});
  const [invitations, setInvitations] = useState({
    user_invitations: [],
    // "accepted_invitations": [],
    pending_invitations: [],
  });
  const [selectedAccount, setSelectedAccount] = useState("");
  const [updateFormData, setUpdateFormData] = useState({
    // firstName: "",
    // lastName: "",
    username: "",
    // email: "",
  });
  // Sales Channels
  const [salesChannels, setSalesChannels] = useState([]);
  const [isModalSalesChannelOpen, setIsModalSalesChannelOpen] = useState(false);
  const [isSalesAddModalOpen, setIsSalesAddModalOpen] = useState(false);
  const [salesChannelInput, setSalesChannelInput] = useState("");
  const [errorsSuppliers, setErrorsSuppliers] = useState({});

  const hasPermissions = (permissions) => {
    return (
      accounts.results &&
      accounts.current &&
      permissions.includes(accounts.results[accounts.current].access)
    );
  };

  useEffect(() => {
    if (sessionStorage.getItem("authUser")) {
      const obj = JSON.parse(sessionStorage.getItem("authUser"));
      setAuthUser(obj);
      // Initialize formData with existing data
      setUpdateFormData({
        // firstName: obj?.client?.first_name || "",
        // lastName: obj?.client?.last_name || "",
        username: obj?.username || "",
        // phone: obj?.client?.phone || "",
        // email: obj?.email || "",
      });
    }
    fetchInvitations();
    fetchAccounts();
    fetchSalesChannels();
  }, []);

  const fetchInvitations = () => {
    getInvitationsApi()
      .then((data) => {
        setInvitations(data);
      })
      .catch(() => {
        toast.error("Не удалось получить данные о приглашениях", {
          position: "top-right",
        });
      });
  };
  const fetchAccounts = () => {
    getAccountsApi()
      .then((data) => {
        setAccounts(data);
        setSelectedAccount(data.current);
      })
      .catch(() => {
        // toast.error("Не удалось получить список аккаунтов", {
        //   position: "top-right",
        // });
      });
  };
  const fetchSalesChannels = () => {
    getSalesChannelsApi()
      .then((response) => {
        setSalesChannels(response);
      })
      .catch(() => {});
  };

  const handleUpdateFormSubmit = async (event) => {
    try {
      event.preventDefault();
      const updatedProfile = {
        ...authUser,
        // client: {
        //     ...authUser.client,
        // first_name: updateFormData.firstName,
        // last_name: updateFormData.lastName,
        // phone: updateFormData.phone,
        username: updateFormData.username,
        // },
        // email: updateFormData.email,
      };

      // Send a PATCH request to update the user profile
      let response = postEditProfile(updatedProfile);
      var data = await response;

      if (data?.email) {
        sessionStorage.setItem("authUser", JSON.stringify(data));
        const obj = JSON.parse(sessionStorage.getItem("authUser"));
        setAuthUser(obj);
        toast.success("Данные успешно изменены", { autoClose: 3000 });
      }
    } catch (error) {
      toast.error("Возникла ошибка на редактировании профиля", {
        autoClose: 3000,
      });
      // console.error(error);
    }
  };

  const handleChangeAccount = async (event) => {
    try {
      event.preventDefault();
      let response = getChangeAccount({ email: selectedAccount });
      let data = await response;
      if (data?.message) {
        sessionStorage.removeItem("authUser");
        history("/products", {
          state: {
            message: data.message,
          },
        });
      }
    } catch (error) {
      if (
        error?.response?.data?.message &&
        String(error.response.status).startsWith("4")
      ) {
        toast.error(error.response.data.message, { position: "top-right" });
      } else {
        toast.error("Неожиданная ошибка при переключении на аккаунт", {
          position: "top-right",
        });
      }
    }
  };

  const handleInvitation = async (invitationId, reject = false) => {
    try {
      const response = getAcceptInvitation(invitationId, { reject: reject });
      const data = await response;

      if (data?.message) {
        reject
          ? toast.warning(data.message, { position: "top-right" })
          : toast.success(data.message, { position: "top-right" });
      }
    } catch (error) {
      if (
        error?.response?.data?.message &&
        String(error.response.status).startsWith("4")
      ) {
        toast.error(error.response.data.message, { position: "top-right" });
      } else {
        toast.error("Неожиданная ошибка при обработке приглашения", {
          position: "top-right",
        });
      }
    }
    fetchInvitations();
    fetchAccounts();
  };

  // const handleInviteCreate = async (event) => {
  //     try {
  //         event.preventDefault();
  //         const response = postInviteCreate({invited_user_email: reject});
  //         const data = await response;
  //
  //         if (data?.message) {
  //             reject
  //                 ? toast.warning(data.message, {position: 'top-right'})
  //                 : toast.success(data.message, {position: 'top-right'});
  //
  //         }
  //     } catch (error) {
  //         if (error?.response?.data?.message && String(error.response.status).startsWith('4')) {
  //             toast.error(error.response.data.message, {position: 'top-right'})
  //         } else {
  //             toast.error('Неожиданная ошибка при обработке приглашения', {position: 'top-right'})
  //         }
  //     }
  //     fetchInvitations();
  //     fetchAccounts();
  // };

  const handleAcceptInvitation = async (invitationId) => {
    await handleInvitation(invitationId);
  };
  const handleRejectInvitation = async (invitationId) => {
    await handleInvitation(invitationId, true);
  };

  const tabChange = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      old_password: "",
      new_password: "",
    },
    validationSchema: Yup.object({
      old_password: Yup.string().required("Обязательное поле"),
      new_password: Yup.string().required("Обязательное поле"),
    }),
    onSubmit: (values) => {
      dispatch(handlePasswordChangeFormSubmit(values));
    },
  });

  const handlePasswordChangeFormSubmit = (values) => async (dispatch) => {
    try {
      // Send a PATCH request to update the user profile
      let data = await postPasswordChange(values);

      if (data?.success) {
        toast.success(data.message, { autoClose: 3000 });
        // validation.resetForm();
      }
    } catch (error) {
      if (error?.response?.data && error?.response?.status === 400) {
        let err = error.response.data;
        if (err?.error) {
          toast.error(err.error[0], { autoClose: 3000 });
        }
        if (err?.message) {
          toast.error(err.message, { autoClose: 3000 });
        }
      } else {
        toast.error("Возникла неожиданная ошибка.", { autoClose: 3000 });
      }
    }
    validation.resetForm();
  };

  const handleUploadImage = async (event) => {
    let file = event.target.files[0];

    if (file) {
      let formData = new FormData();
      formData.append("profile_picture_upload", file);
      try {
        // Send a POST request to your server to update the user's profile image
        const response = await postEditProfile(formData);
        // Handle the response, e.g., update the user's data in state
        if (response?.email) {
          setAuthUser({
            ...authUser,
            profile_picture: response.profile_picture,
          });
          sessionStorage.setItem("authUser", JSON.stringify(response));
          toast.success("Картинка профиля успешно изменена", {
            autoClose: 3000,
          });
        }
      } catch (error) {
        // Handle errors
        if (error?.response?.data?.errors && error?.response?.status === 400) {
          let err = error.response.data.errors;
          if (err?.profile_picture_upload) {
            toast.error(err.profile_picture_upload[0], {
              position: "top-right",
            });
          }
        } else {
          toast.error("Ошибка обновления картинки профиля", {
            position: "top-right",
          });
        }
      }
    } else {
      // ...
    }
  };

  const validationInvite = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      email: "",
      access: "manager",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Обязательное поле"),
    }),
    onSubmit: (values) => {
      const invitationData = {
        email: values.email,
        access: values.access,
      };
      postCreateInvite(invitationData)
        .then(() => {
          setErrorsInvite({});
          validationInvite.resetForm();
          toast.success("Приглашение было отправлено", {
            position: "top-right",
          });
        })
        .catch((error) => {
          if (
            error?.response?.data?.errors &&
            String(error?.response?.status).startsWith("4")
          ) {
            let err = error.response.data.errors;
            if (err.error) {
              toast.error(err.error[0], { position: "top-right" });
            }
            if (err.email) {
              setErrorsInvite({ email: err.email });
            } else {
              setErrorsInvite({});
            }
          } else {
            toast.error("Не удалось отправить приглашение", {
              position: "top-right",
            });
          }
        });
      if (!errorsInvite) {
        validationInvite.resetForm();
      }
    },
  });

  const validationOrganization = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: authUser?.organization?.name || "",
      phone: authUser?.organization?.phone || "",
      email: authUser?.organization?.email || "",
      address: authUser?.organization?.address || "",
      warranty: authUser?.organization?.warranty || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Обязательное поле"),
      phone: Yup.string().required("Обязательное поле"),
      email: Yup.string().required("Обязательное поле"),
    }),
    onSubmit: async (values) => {
      const updatedProfile = {
          ...authUser,
          organization: {
            name: values.name,
            phone: values.phone,
            email: values.email,
            address: values.address,
            warranty: values.warranty
          }
        };
      postEditProfile(updatedProfile)
        .then((data) => {
          sessionStorage.setItem("authUser", JSON.stringify(data));
          const obj = JSON.parse(sessionStorage.getItem("authUser"));
          setAuthUser(obj);
          setErrorsOrganization({});
          validationOrganization.resetForm();
          modalOrganizationClose();
          toast.success("Данные организации успешно изменены", {position: "top-right"});
        })
        .catch((error) => {
          if (
            error?.response?.data?.errors?.organization &&
            String(error?.response?.status).startsWith("4")
          ) {
            let err = error.response.data.errors.organization;
            if (err.error) {
              toast.error(err.error[0], { position: "top-right" });
            }
            if (err) {
              setErrorsOrganization(err);
            } else {
              setErrorsOrganization({});
            }
          } else {
            toast.error("Не удалось обновить данные организации", {
              position: "top-right",
            });
          }
        });
      if (!errorsOrganization) {
        validationOrganization.resetForm();
      }
    },
  });

  const handleSalesChannelChange = () => {
    setIsModalSalesChannelOpen(!isModalSalesChannelOpen);
  };

  const resultsRef = useRef(null);
  const resultsAddRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (resultsRef.current && !resultsRef.current.contains(event.target)) {
        if (!resultsAddRef.current) {
          setIsModalSalesChannelOpen(false);
        }
      }
      if (
        resultsAddRef.current &&
        !resultsAddRef.current.contains(event.target)
      ) {
        setIsSalesAddModalOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleAddSalesChannel = () => {
    setErrorsSuppliers({});
    setIsSalesAddModalOpen(true);
  };

  const handleSalesChannelInputChange = (event) => {
    setSalesChannelInput(event.target.value);
  };
  const handleSalesChannelModalClose = (event) => {
    setIsModalSalesChannelOpen(false);
  };

  const handleAddSalesChannelModalClose = (event) => {
    setIsSalesAddModalOpen(false);
  };

  const addSalesChannel = async () => {
    await createSalesChannelApi({ name: salesChannelInput })
      .then((response) => {
        setSalesChannelInput("");
        fetchSalesChannels();
        setIsSalesAddModalOpen(false);
      })
      .catch((error) => {
        if (
          error?.response?.data?.errors &&
          String(error?.response?.status).startsWith("4")
        ) {
          setErrorsSuppliers(error.response.data.errors);
        } else {
          toast.error("Не удалось добавить поставщика", {
            position: "top-right",
          });
        }
      });
  };

  const handleDeleteSalesChannelInline = async (id) => {
    try {
      await deleteSalesChannelApi({ id });
      toast.success("Канал продаж успешно удалён");
      fetchSalesChannels();
    } catch (error) {
      toast.error("Не удалось удалить канал продаж");
    }
  };

  const handleUpdateInvitation = (event) => {
    setInvitation(prevInvitation => ({
        ...prevInvitation,
        [event.target.name]: event.target.value
    }));
  };

  const handlePermissionsAccessObjectChange = (accessObjectName, checked) => {
    setInvitation(prevInvitation => {
      let updatedAccessObjects;
      if (checked) {
        // Add the access object if it doesn't already exist
        updatedAccessObjects = [...prevInvitation.access_objects, { name: accessObjectName }];
      } else {
        // Remove the access object if it exists
        updatedAccessObjects = prevInvitation.access_objects.filter(obj => obj.name !== accessObjectName);
      }
      return { ...prevInvitation, access_objects: updatedAccessObjects };
    });
  };

  const handlePermissionsSelectAllChange = (checked) => {
    if (checked) {
      // Select all access objects
      setInvitation(prevInvitation => {
        return { ...prevInvitation, access_objects: Object.keys(allAccessObjects).map(name => ({ name })) };
      });
    } else {
      // Deselect all access objects
      setInvitation(prevInvitation => ({ ...prevInvitation, access_objects: [] }));
    }
  };

  function handlePermissionFormSubmit(event) {
    event.preventDefault();
    const updatedData = {
      id: invitation.id,
      access: invitation.access,
      access_objects: invitation.access_objects
    }
    updateInvite(updatedData)
      .then(() => {
        toast.success("Права пользователя обновлены");
        modalPermissionsClose();
        fetchInvitations();
      })
      .catch(() => {
        toast.error("Не удалось обновить права пользователя");
      })
  }

  document.title = "CRMBEK : Профиль";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Профиль" pageTitle="Настройки" />
          {/*BACKGROUND PROFILE*/}
          {/*<div className="position-relative mx-n4 mt-n4">*/}
          {/*    <div className="profile-wid-bg profile-setting-img">*/}
          {/*        <img src={profileBg} className="profile-wid-img" alt="" />*/}
          {/*        <div className="overlay-content">*/}
          {/*            <div className="text-end p-3">*/}
          {/*                <div className="p-0 ms-auto rounded-circle profile-photo-edit">*/}
          {/*                    <Input id="profile-foreground-img-file-input" type="file"*/}
          {/*                        className="profile-foreground-img-file-input" />*/}
          {/*                    <Label htmlFor="profile-foreground-img-file-input"*/}
          {/*                        className="profile-photo-edit btn btn-light">*/}
          {/*                        <i className="ri-image-edit-line align-bottom me-1"></i> Change Cover*/}
          {/*                    </Label>*/}
          {/*                </div>*/}
          {/*            </div>*/}
          {/*        </div>*/}
          {/*    </div>*/}
          {/*</div>*/}
          <Row style={{ padding: "0 10px" }}>
            <Col xxl={3}>
              <Card>
                {" "}
                {/*className="mt-n5"*/}
                <CardBody className="p-4">
                  <div className="text-center">
                    <div className="profile-user position-relative d-inline-block mx-auto  mb-4">
                      <img
                        src={authUser?.profile_picture || userImg}
                        className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                        alt="user-profile"
                      />
                      <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                        <Input
                          id="profile-img-file-input"
                          type="file"
                          className="profile-img-file-input"
                          onChange={handleUploadImage}
                        />
                        <Label
                          htmlFor="profile-img-file-input"
                          className="profile-photo-edit avatar-xs"
                        >
                          <span className="avatar-title rounded-circle bg-light text-body">
                            <i className="ri-camera-fill"></i>
                          </span>
                        </Label>
                      </div>
                    </div>
                    <h5 className="fs-16 mb-1">
                      {authUser?.username || authUser?.email}
                    </h5>
                    {/*<p className="text-muted mb-0">Status</p>*/}
                  </div>
                </CardBody>
              </Card>
              {/*<Card>*/}
              {/*    <CardBody>*/}
              {/*        <div className="d-flex align-items-center mb-5">*/}
              {/*            <div className="flex-grow-1">*/}
              {/*                <h5 className="card-title mb-0">Complete Your Profile</h5>*/}
              {/*            </div>*/}
              {/*            <div className="flex-shrink-0">*/}
              {/*                <Link to="#" className="badge bg-light text-primary fs-12"><i*/}
              {/*                    className="ri-edit-box-line align-bottom me-1"></i> Edit</Link>*/}
              {/*            </div>*/}
              {/*        </div>*/}
              {/*        <div className="progress animated-progress custom-progress progress-label">*/}
              {/*            <div className="progress-bar bg-danger" role="progressbar" style={{ "width": "30%" }}*/}
              {/*                aria-valuenow="30" aria-valuemin="0" aria-valuemax="100">*/}
              {/*                <div className="label">30%</div>*/}
              {/*            </div>*/}
              {/*        </div>*/}
              {/*    </CardBody>*/}
              {/*</Card>*/}

              {/*<Card>*/}
              {/*    <CardBody>*/}
              {/*        <div className="d-flex align-items-center mb-4">*/}
              {/*            <div className="flex-grow-1">*/}
              {/*                <h5 className="card-title mb-0">Portfolio</h5>*/}
              {/*            </div>*/}
              {/*            <div className="flex-shrink-0">*/}
              {/*                <Link to="#" className="badge bg-light text-primary fs-12"><i*/}
              {/*                    className="ri-add-fill align-bottom me-1"></i> Add</Link>*/}
              {/*            </div>*/}
              {/*        </div>*/}
              {/*        <div className="mb-3 d-flex">*/}
              {/*            <div className="avatar-xs d-block flex-shrink-0 me-3">*/}
              {/*                <span className="avatar-title rounded-circle fs-16 bg-dark text-light">*/}
              {/*                    <i className="ri-github-fill"></i>*/}
              {/*                </span>*/}
              {/*            </div>*/}
              {/*            <Input type="email" className="form-control" id="gitUsername" placeholder="Username"*/}
              {/*                defaultValue="@daveadame" />*/}
              {/*        </div>*/}
              {/*        <div className="mb-3 d-flex">*/}
              {/*            <div className="avatar-xs d-block flex-shrink-0 me-3">*/}
              {/*                <span className="avatar-title rounded-circle fs-16 bg-primary">*/}
              {/*                    <i className="ri-global-fill"></i>*/}
              {/*                </span>*/}
              {/*            </div>*/}
              {/*            <Input type="text" className="form-control" id="websiteInput"*/}
              {/*                placeholder="www.example.com" defaultValue="www.velzon.com" />*/}
              {/*        </div>*/}
              {/*        <div className="mb-3 d-flex">*/}
              {/*            <div className="avatar-xs d-block flex-shrink-0 me-3">*/}
              {/*                <span className="avatar-title rounded-circle fs-16 bg-success">*/}
              {/*                    <i className="ri-dribbble-fill"></i>*/}
              {/*                </span>*/}
              {/*            </div>*/}
              {/*            <Input type="text" className="form-control" id="dribbleName" placeholder="Username"*/}
              {/*                defaultValue="@dave_adame" />*/}
              {/*        </div>*/}
              {/*        <div className="d-flex">*/}
              {/*            <div className="avatar-xs d-block flex-shrink-0 me-3">*/}
              {/*                <span className="avatar-title rounded-circle fs-16 bg-danger">*/}
              {/*                    <i className="ri-pinterest-fill"></i>*/}
              {/*                </span>*/}
              {/*            </div>*/}
              {/*            <Input type="text" className="form-control" id="pinterestName"*/}
              {/*                placeholder="Username" defaultValue="Advance Dave" />*/}
              {/*        </div>*/}
              {/*    </CardBody>*/}
              {/*</Card>*/}
            </Col>

            <Col xxl={9}>
              <Card>
                {" "}
                {/*className="mt-xxl-n5"*/}
                <CardHeader>
                  <Nav
                    className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames(
                          { active: activeTab === "1" },
                          "text-body"
                        )}
                        onClick={() => {
                          tabChange("1");
                        }}
                      >
                        <i className="fas fa-home"></i>
                        Персональные Данные
                      </NavLink>
                    </NavItem>
                    {hasPermissions(["owner"]) && (
                      <NavItem>
                        <NavLink
                          to="#"
                          className={classnames(
                            { active: activeTab === "2" },
                            "text-body"
                          )}
                          onClick={() => {
                            tabChange("2");
                          }}
                          type="button"
                        >
                          <i className="far fa-user"></i>
                          Изменить Пароль
                        </NavLink>
                      </NavItem>
                    )}
                    {hasPermissions(["owner"]) && (
                      <NavItem>
                        <NavLink
                          to="#"
                          className={classnames(
                            { active: activeTab === "3" },
                            "text-body"
                          )}
                          onClick={() => {
                            tabChange("3");
                          }}
                          type="button"
                        >
                          <i className="far fa-envelope"></i>
                          Добавить пользователя
                        </NavLink>
                      </NavItem>
                    )}
                    {/*<NavItem>*/}
                    {/*    <NavLink to="#"*/}
                    {/*        className={classnames({ active: activeTab === "4" }, "text-body")}*/}
                    {/*        onClick={() => {*/}
                    {/*            tabChange("4");*/}
                    {/*        }}*/}
                    {/*        type="button">*/}
                    {/*        <i className="far fa-envelope"></i>*/}
                    {/*        Privacy Policy*/}
                    {/*    </NavLink>*/}
                    {/*</NavItem>*/}
                  </Nav>
                </CardHeader>
                <CardBody className="p-4">
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <Form onSubmit={handleUpdateFormSubmit}>
                        <Row>
                          {/*<Col lg={6}>*/}
                          {/*    <div className="mb-3">*/}
                          {/*        <Label htmlFor="firstnameInput" className="form-label">First*/}
                          {/*            Name</Label>*/}
                          {/*        <Input type="text" className="form-control" id="firstnameInput"*/}
                          {/*               placeholder="Enter your firstname" // defaultValue={authUser?.client?.first_name}*/}
                          {/*               value={updateFormData.firstName}*/}
                          {/*               onChange={(e) => setUpdateFormData({ ...updateFormData, firstName: e.target.value })}/>*/}
                          {/*    </div>*/}
                          {/*</Col>*/}
                          <Col lg={4}>
                            <div className="mb-3">
                              <Label
                                htmlFor="usernameInput"
                                className="form-label"
                              >
                                Имя Пользователя
                              </Label>
                              <Input
                                readOnly={!hasPermissions(["owner"])}
                                type="text"
                                className="form-control"
                                id="usernameInput"
                                placeholder="Введите ваше имя"
                                value={updateFormData.username}
                                onChange={(e) =>
                                  setUpdateFormData({
                                    ...updateFormData,
                                    username: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </Col>
                          {/*<Col lg={6}>*/}
                          {/*    <div className="mb-3">*/}
                          {/*        <Label htmlFor="phonenumberInput" className="form-label">Phone*/}
                          {/*            Number</Label>*/}
                          {/*        <Input type="text" className="form-control"*/}
                          {/*               id="phonenumberInput"*/}
                          {/*               placeholder="Enter your phone number"*/}
                          {/*               value={updateFormData.phone}*/}
                          {/*               onChange={(e) => setUpdateFormData({ ...updateFormData, phone: e.target.value })}/>*/}
                          {/*    </div>*/}
                          {/*</Col>*/}
                          <Col lg={4}>
                            <div className="mb-3">
                              <Label
                                htmlFor="emailInput"
                                className="form-label"
                              >
                                Электронная Почта
                              </Label>
                              <Input
                                type="email"
                                className="form-control"
                                id="emailInput"
                                placeholder="Enter your email"
                                readOnly={true}
                                contentEditable={false}
                                defaultValue={authUser?.email}
                              />
                            </div>
                          </Col>
                          {/*<Col lg={12}>*/}
                          {/*    <div className="mb-3">*/}
                          {/*        <Label htmlFor="JoiningdatInput" className="form-label">Joining*/}
                          {/*            Date</Label>*/}
                          {/*        <Flatpickr*/}
                          {/*            className="form-control"*/}
                          {/*            options={{*/}
                          {/*                dateFormat: "d M, Y"*/}
                          {/*            }}*/}
                          {/*        />*/}
                          {/*    </div>*/}
                          {/*</Col>*/}
                          {/*<Col lg={12}>*/}
                          {/*    <div className="mb-3">*/}
                          {/*        <Label htmlFor="skillsInput" className="form-label">Skills</Label>*/}
                          {/*        <select className="form-select mb-3">*/}
                          {/*            <option >Select your Skill </option>*/}
                          {/*            <option value="Choices1">CSS</option>*/}
                          {/*            <option value="Choices2">HTML</option>*/}
                          {/*            <option value="Choices3">PYTHON</option>*/}
                          {/*            <option value="Choices4">JAVA</option>*/}
                          {/*            <option value="Choices5">ASP.NET</option>*/}
                          {/*        </select>*/}
                          {/*    </div>*/}
                          {/*</Col>*/}
                          {/*<Col lg={6}>*/}
                          {/*    <div className="mb-3">*/}
                          {/*        <Label htmlFor="designationInput"*/}
                          {/*            className="form-label">Designation</Label>*/}
                          {/*        <Input type="text" className="form-control"*/}
                          {/*            id="designationInput" placeholder="Designation"*/}
                          {/*            defaultValue="Lead Designer / Developer" />*/}
                          {/*    </div>*/}
                          {/*</Col>*/}
                          {/*<Col lg={6}>*/}
                          {/*    <div className="mb-3">*/}
                          {/*        <Label htmlFor="websiteInput1"*/}
                          {/*            className="form-label">Website</Label>*/}
                          {/*        <Input type="text" className="form-control" id="websiteInput1"*/}
                          {/*            placeholder="www.example.com" defaultValue="www.velzon.com" />*/}
                          {/*    </div>*/}
                          {/*</Col>*/}
                          {/*<Col lg={4}>*/}
                          {/*    <div className="mb-3">*/}
                          {/*        <Label htmlFor="cityInput" className="form-label">City</Label>*/}
                          {/*        <Input type="text" className="form-control" id="cityInput"*/}
                          {/*            placeholder="City" defaultValue="California" />*/}
                          {/*    </div>*/}
                          {/*</Col>*/}
                          {/*<Col lg={4}>*/}
                          {/*    <div className="mb-3">*/}
                          {/*        <Label htmlFor="countryInput" className="form-label">Country</Label>*/}
                          {/*        <Input type="text" className="form-control" id="countryInput"*/}
                          {/*            placeholder="Country" defaultValue="United States" />*/}
                          {/*    </div>*/}
                          {/*</Col>*/}
                          {/*<Col lg={4}>*/}
                          {/*    <div className="mb-3">*/}
                          {/*        <Label htmlFor="zipcodeInput" className="form-label">Zip*/}
                          {/*            Code</Label>*/}
                          {/*        <Input type="text" className="form-control" minLength="5"*/}
                          {/*            maxLength="6" id="zipcodeInput"*/}
                          {/*            placeholder="Enter zipcode" defaultValue="90011" />*/}
                          {/*    </div>*/}
                          {/*</Col>*/}
                          {/*<Col lg={12}>*/}
                          {/*    <div className="mb-3 pb-2">*/}
                          {/*        <Label htmlFor="exampleFormControlTextarea"*/}
                          {/*            className="form-label">Description</Label>*/}
                          {/*        <textarea className="form-control"*/}
                          {/*            id="exampleFormControlTextarea"*/}
                          {/*            rows="3" defaultValue="Hi I'm Anna Adame, It will be as simple as Occidental; in fact, it will be Occidental. To an English person, it will seem like simplified English, as a skeptical Cambridge friend of mine told me what Occidental is European languages are members of the same family."></textarea>*/}
                          {/*    </div>*/}
                          {/*</Col>*/}
                          <Col lg={12}>
                            <div className="hstack gap-2 justify-content-end">
                              <button
                                disabled={!hasPermissions(["owner"])}
                                // type={hasPermissions(['owner']) ? 'submit': 'button'}
                                // style={!hasPermissions(['owner']) ? {opacity: 0, cursor: "unset"} : null}
                                className="btn btn-info"
                              >
                                Изменить
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </TabPane>

                    <TabPane tabId="2">
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col lg={4}>
                            <div>
                              <Label
                                htmlFor="oldpasswordInput"
                                className="form-label"
                              >
                                Старый Пароль
                              </Label>
                              <Input
                                type="password"
                                className="form-control"
                                id="oldpasswordInput"
                                name="old_password"
                                placeholder="Введите действующий пароль"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.old_password || ""}
                                invalid={
                                  !!(
                                    validation.touched.old_password &&
                                    validation.errors.old_password
                                  )
                                }
                              />

                              {validation.touched.old_password &&
                              validation.errors.old_password ? (
                                <FormFeedback type="invalid">
                                  <div>{validation.errors.old_password}</div>
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>

                          <Col lg={4}>
                            <div>
                              <Label
                                htmlFor="newpasswordInput"
                                className="form-label"
                              >
                                Новый Пароль
                              </Label>
                              <Input
                                type="password"
                                className="form-control"
                                id="newpasswordInput"
                                name="new_password"
                                placeholder="Введите новый пароль"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.new_password || ""}
                                invalid={
                                  !!(
                                    validation.touched.new_password &&
                                    validation.errors.new_password
                                  )
                                }
                              />

                              {validation.touched.new_password &&
                              validation.errors.new_password ? (
                                <FormFeedback type="invalid">
                                  <div>{validation.errors.new_password}</div>
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>

                          {/*<Col lg={4}>*/}
                          {/*    <div>*/}
                          {/*        <Label htmlFor="confirmpasswordInput" className="form-label">Confirm*/}
                          {/*            Password*</Label>*/}
                          {/*        <Input type="password" className="form-control"*/}
                          {/*            id="confirmpasswordInput"*/}
                          {/*            placeholder="Confirm password" />*/}
                          {/*    </div>*/}
                          {/*</Col>*/}

                          <Col
                            lg={12}
                            className="d-flex justify-content-between align-items-start"
                          >
                            <div className="mb-3">
                              <Link
                                to="#"
                                className="link-primary text-decoration-underline"
                              >
                                Забыли пароль ?
                              </Link>
                            </div>
                            <div
                              style={{ marginBottom: "5px" }}
                              className="text-end"
                            >
                              <button type="submit" className="btn btn-info">
                                Изменить пароль
                              </button>
                            </div>
                          </Col>

                          {/* <Col lg={12}>
                                                        <div className="text-end">
                                                            <button type="submit" className="btn btn-success">
                                                                Изменить пароль
                                                            </button>
                                                        </div>
                                                    </Col> */}
                        </Row>
                      </Form>
                      {/*<div className="mt-4 mb-3 border-bottom pb-2">*/}
                      {/*    <div className="float-end">*/}
                      {/*        <Link to="#" className="link-primary">All Logout</Link>*/}
                      {/*    </div>*/}
                      {/*    <h5 className="card-title">Login History</h5>*/}
                      {/*</div>*/}
                      {/*<div className="d-flex align-items-center mb-3">*/}
                      {/*    <div className="flex-shrink-0 avatar-sm">*/}
                      {/*        <div className="avatar-title bg-light text-primary rounded-3 fs-18">*/}
                      {/*            <i className="ri-smartphone-line"></i>*/}
                      {/*        </div>*/}
                      {/*    </div>*/}
                      {/*    <div className="flex-grow-1 ms-3">*/}
                      {/*        <h6>iPhone 12 Pro</h6>*/}
                      {/*        <p className="text-muted mb-0">Los Angeles, United States - March 16 at*/}
                      {/*            2:47PM</p>*/}
                      {/*    </div>*/}
                      {/*    <div>*/}
                      {/*        <Link to="#">Logout</Link>*/}
                      {/*    </div>*/}
                      {/*</div>*/}
                      {/*<div className="d-flex align-items-center mb-3">*/}
                      {/*    <div className="flex-shrink-0 avatar-sm">*/}
                      {/*        <div className="avatar-title bg-light text-primary rounded-3 fs-18">*/}
                      {/*            <i className="ri-tablet-line"></i>*/}
                      {/*        </div>*/}
                      {/*    </div>*/}
                      {/*    <div className="flex-grow-1 ms-3">*/}
                      {/*        <h6>Apple iPad Pro</h6>*/}
                      {/*        <p className="text-muted mb-0">Washington, United States - November 06*/}
                      {/*            at 10:43AM</p>*/}
                      {/*    </div>*/}
                      {/*    <div>*/}
                      {/*        <Link to="#">Logout</Link>*/}
                      {/*    </div>*/}
                      {/*</div>*/}
                      {/*<div className="d-flex align-items-center mb-3">*/}
                      {/*    <div className="flex-shrink-0 avatar-sm">*/}
                      {/*        <div className="avatar-title bg-light text-primary rounded-3 fs-18">*/}
                      {/*            <i className="ri-smartphone-line"></i>*/}
                      {/*        </div>*/}
                      {/*    </div>*/}
                      {/*    <div className="flex-grow-1 ms-3">*/}
                      {/*        <h6>Galaxy S21 Ultra 5G</h6>*/}
                      {/*        <p className="text-muted mb-0">Conneticut, United States - June 12 at*/}
                      {/*            3:24PM</p>*/}
                      {/*    </div>*/}
                      {/*    <div>*/}
                      {/*        <Link to="#">Logout</Link>*/}
                      {/*    </div>*/}
                      {/*</div>*/}
                      {/*<div className="d-flex align-items-center">*/}
                      {/*    <div className="flex-shrink-0 avatar-sm">*/}
                      {/*        <div className="avatar-title bg-light text-primary rounded-3 fs-18">*/}
                      {/*            <i className="ri-macbook-line"></i>*/}
                      {/*        </div>*/}
                      {/*    </div>*/}
                      {/*    <div className="flex-grow-1 ms-3">*/}
                      {/*        <h6>Dell Inspiron 14</h6>*/}
                      {/*        <p className="text-muted mb-0">Phoenix, United States - July 26 at*/}
                      {/*            8:10AM</p>*/}
                      {/*    </div>*/}
                      {/*    <div>*/}
                      {/*        <Link to="#">Logout</Link>*/}
                      {/*    </div>*/}
                      {/*</div>*/}
                    </TabPane>

                    <TabPane tabId="3">
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validationInvite.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col lg={4}>
                            <div>
                              <Label
                                htmlFor="invited_user_email"
                                className="form-label"
                              >
                                Электронная почта сотрудника
                              </Label>
                              <Input
                                type="email"
                                className="form-control"
                                id="invited_user_email"
                                name="email"
                                placeholder="Введите email сотрудника"
                                onChange={validationInvite.handleChange}
                                onBlur={validationInvite.handleBlur}
                                value={validationInvite.values.email || ""}
                                invalid={
                                  !!(
                                    validationInvite.touched.email &&
                                    validationInvite.errors.email
                                  ) || !!errorsInvite?.email
                                }
                              />
                              {validationInvite.touched.email &&
                              validationInvite.errors.email ? (
                                <FormFeedback type="invalid">
                                  <div>{validationInvite.errors.email}</div>
                                </FormFeedback>
                              ) : errorsInvite && errorsInvite?.email ? (
                                <FormFeedback type="invalid">
                                  <div>{errorsInvite.email}</div>
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>

                          <Col lg={4}>
                            <div>
                              <Label
                                htmlFor="invited_user_email"
                                className="form-label"
                              >
                                Доступ
                              </Label>
                              <select
                                // type="email"
                                className="form-select"
                                id="access"
                                name="access"
                                value={
                                  validationInvite.values.access || "manager"
                                } // Для обработки значения по умолчанию
                                onChange={validationInvite.handleChange}
                                onBlur={validationInvite.handleBlur}
                                // invalid={validationInvite.touched.access && !!validationInvite.errors.access}
                                // invalid={
                                //     !!(validationInvite.touched.email && validationInvite.errors.email)
                                //     ||
                                //     !!errorsInvite?.email
                                // }
                              >
                                {accounts?.access_options &&
                                  Object.entries(accounts.access_options).map(
                                    ([value, label], key) =>
                                      value !== "owner" && (
                                        <option key={key} value={value}>
                                          {label}
                                        </option>
                                      )
                                  )}
                              </select>

                              {validationInvite.touched.access &&
                              validationInvite.errors.access ? (
                                <FormFeedback type="invalid">
                                  <div>{validationInvite.errors.access}</div>
                                </FormFeedback>
                              ) : errorsInvite && errorsInvite?.access ? (
                                <FormFeedback type="invalid">
                                  <div>{errorsInvite.access}</div>
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>

                          <Col
                            lg={12}
                            className="d-flex justify-content-end align-items-start"
                          >
                            <div
                              style={{ marginBottom: "5px" }}
                              className="text-end"
                            >
                              <button type="submit" className="btn btn-info">
                                Отправить приглашение
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </TabPane>

                    {/*<TabPane tabId="3">*/}
                    {/*    <form>*/}
                    {/*        <div id="newlink">*/}
                    {/*            <div id="1">*/}
                    {/*                <Row>*/}
                    {/*                    <Col lg={12}>*/}
                    {/*                        <div className="mb-3">*/}
                    {/*                            <Label htmlFor="jobTitle" className="form-label">Job*/}
                    {/*                                Title</Label>*/}
                    {/*                            <Input type="text" className="form-control"*/}
                    {/*                                id="jobTitle" placeholder="Job title"*/}
                    {/*                                defaultValue="Lead Designer / Developer" />*/}
                    {/*                        </div>*/}
                    {/*                    </Col>*/}

                    {/*                    <Col lg={6}>*/}
                    {/*                        <div className="mb-3">*/}
                    {/*                            <Label htmlFor="companyName" className="form-label">Company*/}
                    {/*                                Name</Label>*/}
                    {/*                            <Input type="text" className="form-control"*/}
                    {/*                                id="companyName" placeholder="Company name"*/}
                    {/*                                defaultValue="Themesbrand" />*/}
                    {/*                        </div>*/}
                    {/*                    </Col>*/}

                    {/*                    <Col lg={6}>*/}
                    {/*                        <div className="mb-3">*/}
                    {/*                            <label htmlFor="experienceYear"*/}
                    {/*                                className="form-label">Experience Years</label>*/}
                    {/*                            <Row>*/}
                    {/*                                <Col lg={5}>*/}
                    {/*                                    <select className="form-control" data-choices*/}
                    {/*                                        data-choices-search-false*/}
                    {/*                                        name="experienceYear"*/}
                    {/*                                        id="experienceYear">*/}
                    {/*                                        <option defaultValue="">Select years</option>*/}
                    {/*                                        <option value="Choice 1">2001</option>*/}
                    {/*                                        <option value="Choice 2">2002</option>*/}
                    {/*                                        <option value="Choice 3">2003</option>*/}
                    {/*                                        <option value="Choice 4">2004</option>*/}
                    {/*                                        <option value="Choice 5">2005</option>*/}
                    {/*                                        <option value="Choice 6">2006</option>*/}
                    {/*                                        <option value="Choice 7">2007</option>*/}
                    {/*                                        <option value="Choice 8">2008</option>*/}
                    {/*                                        <option value="Choice 9">2009</option>*/}
                    {/*                                        <option value="Choice 10">2010</option>*/}
                    {/*                                        <option value="Choice 11">2011</option>*/}
                    {/*                                        <option value="Choice 12">2012</option>*/}
                    {/*                                        <option value="Choice 13">2013</option>*/}
                    {/*                                        <option value="Choice 14">2014</option>*/}
                    {/*                                        <option value="Choice 15">2015</option>*/}
                    {/*                                        <option value="Choice 16">2016</option>*/}
                    {/*                                        <option value="Choice 17" >2017</option>*/}
                    {/*                                        <option value="Choice 18">2018</option>*/}
                    {/*                                        <option value="Choice 19">2019</option>*/}
                    {/*                                        <option value="Choice 20">2020</option>*/}
                    {/*                                        <option value="Choice 21">2021</option>*/}
                    {/*                                        <option value="Choice 22">2022</option>*/}
                    {/*                                    </select>*/}
                    {/*                                </Col>*/}

                    {/*                                <div className="col-auto align-self-center">*/}
                    {/*                                    to*/}
                    {/*                                </div>*/}

                    {/*                                <Col lg={5}>*/}
                    {/*                                    <select className="form-control" data-choices*/}
                    {/*                                        data-choices-search-false*/}
                    {/*                                        name="choices-single-default2">*/}
                    {/*                                        <option defaultValue="">Select years</option>*/}
                    {/*                                        <option value="Choice 1">2001</option>*/}
                    {/*                                        <option value="Choice 2">2002</option>*/}
                    {/*                                        <option value="Choice 3">2003</option>*/}
                    {/*                                        <option value="Choice 4">2004</option>*/}
                    {/*                                        <option value="Choice 5">2005</option>*/}
                    {/*                                        <option value="Choice 6">2006</option>*/}
                    {/*                                        <option value="Choice 7">2007</option>*/}
                    {/*                                        <option value="Choice 8">2008</option>*/}
                    {/*                                        <option value="Choice 9">2009</option>*/}
                    {/*                                        <option value="Choice 10">2010</option>*/}
                    {/*                                        <option value="Choice 11">2011</option>*/}
                    {/*                                        <option value="Choice 12">2012</option>*/}
                    {/*                                        <option value="Choice 13">2013</option>*/}
                    {/*                                        <option value="Choice 14">2014</option>*/}
                    {/*                                        <option value="Choice 15">2015</option>*/}
                    {/*                                        <option value="Choice 16">2016</option>*/}
                    {/*                                        <option value="Choice 17">2017</option>*/}
                    {/*                                        <option value="Choice 18">2018</option>*/}
                    {/*                                        <option value="Choice 19">2019</option>*/}
                    {/*                                        <option value="Choice 20">2020</option>*/}
                    {/*                                        <option value="Choice 21">2021</option>*/}
                    {/*                                        <option value="Choice 22">2022</option>*/}
                    {/*                                    </select>*/}
                    {/*                                </Col>*/}
                    {/*                            </Row>*/}
                    {/*                        </div>*/}
                    {/*                    </Col>*/}

                    {/*                    <Col lg={12}>*/}
                    {/*                        <div className="mb-3">*/}
                    {/*                            <Label htmlFor="jobDescription" className="form-label">Job*/}
                    {/*                                Description</Label>*/}
                    {/*                            /!* <textarea className="form-control" id="jobDescription"*/}
                    {/*                            defaultValue=""*/}
                    {/*                                rows="3"*/}
                    {/*                                placeholder="Enter description">You always want to make sure that your fonts work well together and try to limit the number of fonts you use to three or less. Experiment and play around with the fonts that you already have in the software you're working with reputable font websites. </textarea> *!/*/}
                    {/*                        </div>*/}
                    {/*                    </Col>*/}

                    {/*                    <div className="hstack gap-2 justify-content-end">*/}
                    {/*                        <Link className="btn btn-success"*/}
                    {/*                            to="#">Delete</Link>*/}
                    {/*                    </div>*/}
                    {/*                </Row>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div id="newForm" style={{ "display": "none" }}>*/}
                    {/*        </div>*/}

                    {/*        <Col lg={12}>*/}
                    {/*            <div className="hstack gap-2">*/}
                    {/*                <button type="submit" className="btn btn-success">Update</button>*/}
                    {/*                <Link to="#" className="btn btn-primary">Add*/}
                    {/*                    New</Link>*/}
                    {/*            </div>*/}
                    {/*        </Col>*/}
                    {/*    </form>*/}
                    {/*</TabPane>*/}

                    {/*<TabPane tabId="4">*/}
                    {/*    <div className="mb-4 pb-2">*/}
                    {/*        <h5 className="card-title text-decoration-underline mb-3">Security:</h5>*/}
                    {/*        <div className="d-flex flex-column flex-sm-row mb-4 mb-sm-0">*/}
                    {/*            <div className="flex-grow-1">*/}
                    {/*                <h6 className="fs-13 mb-1">Two-factor Authentication</h6>*/}
                    {/*                <p className="text-muted">Two-factor authentication is an enhanced*/}
                    {/*                    security meansur. Once enabled, you'll be required to give*/}
                    {/*                    two types of identification when you log into Google*/}
                    {/*                    Authentication and SMS are Supported.</p>*/}
                    {/*            </div>*/}
                    {/*            <div className="flex-shrink-0 ms-sm-3">*/}
                    {/*                <Link to="#"*/}
                    {/*                    className="btn btn-sm btn-primary">Enable Two-facor*/}
                    {/*                    Authentication</Link>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className="d-flex flex-column flex-sm-row mb-4 mb-sm-0 mt-2">*/}
                    {/*            <div className="flex-grow-1">*/}
                    {/*                <h6 className="fs-13 mb-1">Secondary Verification</h6>*/}
                    {/*                <p className="text-muted">The first factor is a password and the*/}
                    {/*                    second commonly includes a text with a code sent to your*/}
                    {/*                    smartphone, or biometrics using your fingerprint, face, or*/}
                    {/*                    retina.</p>*/}
                    {/*            </div>*/}
                    {/*            <div className="flex-shrink-0 ms-sm-3">*/}
                    {/*                <Link to="#" className="btn btn-sm btn-primary">Set*/}
                    {/*                    up secondary method</Link>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className="d-flex flex-column flex-sm-row mb-4 mb-sm-0 mt-2">*/}
                    {/*            <div className="flex-grow-1">*/}
                    {/*                <h6 className="fs-13 mb-1">Backup Codes</h6>*/}
                    {/*                <p className="text-muted mb-sm-0">A backup code is automatically*/}
                    {/*                    generated for you when you turn on two-factor authentication*/}
                    {/*                    through your iOS or Android Twitter app. You can also*/}
                    {/*                    generate a backup code on twitter.com.</p>*/}
                    {/*            </div>*/}
                    {/*            <div className="flex-shrink-0 ms-sm-3">*/}
                    {/*                <Link to="#"*/}
                    {/*                    className="btn btn-sm btn-primary">Generate backup codes</Link>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div className="mb-3">*/}
                    {/*        <h5 className="card-title text-decoration-underline mb-3">Application Notifications:</h5>*/}
                    {/*        <ul className="list-unstyled mb-0">*/}
                    {/*            <li className="d-flex">*/}
                    {/*                <div className="flex-grow-1">*/}
                    {/*                    <label htmlFor="directMessage"*/}
                    {/*                        className="form-check-label fs-14">Direct messages</label>*/}
                    {/*                    <p className="text-muted">Messages from people you follow</p>*/}
                    {/*                </div>*/}
                    {/*                <div className="flex-shrink-0">*/}
                    {/*                    <div className="form-check form-switch">*/}
                    {/*                        <Input className="form-check-input" type="checkbox"*/}
                    {/*                            role="switch" id="directMessage" defaultChecked />*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*            </li>*/}
                    {/*            <li className="d-flex mt-2">*/}
                    {/*                <div className="flex-grow-1">*/}
                    {/*                    <Label className="form-check-label fs-14"*/}
                    {/*                        htmlFor="desktopNotification">*/}
                    {/*                        Show desktop notifications*/}
                    {/*                    </Label>*/}
                    {/*                    <p className="text-muted">Choose the option you want as your*/}
                    {/*                        default setting. Block a site: Next to "Not allowed to*/}
                    {/*                        send notifications," click Add.</p>*/}
                    {/*                </div>*/}
                    {/*                <div className="flex-shrink-0">*/}
                    {/*                    <div className="form-check form-switch">*/}
                    {/*                        <Input className="form-check-input" type="checkbox"*/}
                    {/*                            role="switch" id="desktopNotification" defaultChecked />*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*            </li>*/}
                    {/*            <li className="d-flex mt-2">*/}
                    {/*                <div className="flex-grow-1">*/}
                    {/*                    <Label className="form-check-label fs-14"*/}
                    {/*                        htmlFor="emailNotification">*/}
                    {/*                        Show email notifications*/}
                    {/*                    </Label>*/}
                    {/*                    <p className="text-muted"> Under Settings, choose Notifications.*/}
                    {/*                        Under Select an account, choose the account to enable*/}
                    {/*                        notifications for. </p>*/}
                    {/*                </div>*/}
                    {/*                <div className="flex-shrink-0">*/}
                    {/*                    <div className="form-check form-switch">*/}
                    {/*                        <Input className="form-check-input" type="checkbox"*/}
                    {/*                            role="switch" id="emailNotification" />*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*            </li>*/}
                    {/*            <li className="d-flex mt-2">*/}
                    {/*                <div className="flex-grow-1">*/}
                    {/*                    <Label className="form-check-label fs-14"*/}
                    {/*                        htmlFor="chatNotification">*/}
                    {/*                        Show chat notifications*/}
                    {/*                    </Label>*/}
                    {/*                    <p className="text-muted">To prevent duplicate mobile*/}
                    {/*                        notifications from the Gmail and Chat apps, in settings,*/}
                    {/*                        turn off Chat notifications.</p>*/}
                    {/*                </div>*/}
                    {/*                <div className="flex-shrink-0">*/}
                    {/*                    <div className="form-check form-switch">*/}
                    {/*                        <Input className="form-check-input" type="checkbox"*/}
                    {/*                            role="switch" id="chatNotification" />*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*            </li>*/}
                    {/*            <li className="d-flex mt-2">*/}
                    {/*                <div className="flex-grow-1">*/}
                    {/*                    <Label className="form-check-label fs-14"*/}
                    {/*                        htmlFor="purchaesNotification">*/}
                    {/*                        Show purchase notifications*/}
                    {/*                    </Label>*/}
                    {/*                    <p className="text-muted">Get real-time purchase alerts to*/}
                    {/*                        protect yourself from fraudulent charges.</p>*/}
                    {/*                </div>*/}
                    {/*                <div className="flex-shrink-0">*/}
                    {/*                    <div className="form-check form-switch">*/}
                    {/*                        <Input className="form-check-input" type="checkbox"*/}
                    {/*                            role="switch" id="purchaesNotification" />*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*            </li>*/}
                    {/*        </ul>*/}
                    {/*    </div>*/}
                    {/*    <div>*/}
                    {/*        <h5 className="card-title text-decoration-underline mb-3">Delete This*/}
                    {/*            Account:</h5>*/}
                    {/*        <p className="text-muted">Go to the Data & Privacy section of your profile*/}
                    {/*            Account. Scroll to "Your data & privacy options." Delete your*/}
                    {/*            Profile Account. Follow the instructions to delete your account :*/}
                    {/*        </p>*/}
                    {/*        <div>*/}
                    {/*            <Input type="password" className="form-control" id="passwordInput"*/}
                    {/*                placeholder="Enter your password" defaultValue="make@321654987"*/}
                    {/*                style={{ maxWidth: "265px" }} />*/}
                    {/*        </div>*/}
                    {/*        <div className="hstack gap-2 mt-3">*/}
                    {/*            <Link to="#" className="btn btn-soft-danger">Close &*/}
                    {/*                Delete This Account</Link>*/}
                    {/*            <Link to="#" className="btn btn-light">Cancel</Link>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</TabPane>*/}
                  </TabContent>
                  <ToastContainer closeButton={false} />
                </CardBody>
              </Card>
            </Col>
            <Col xxl={3} className={styles.navigate_btns}>
              <Card className={styles.navigate_btns__card}>
                <Link to={"/storages"} className="text-center">
                  <button className="text-center btn btn-success w-100">
                    <CardBody className={`p-0 ${styles.navigate_btns__text}`}>
                      <img src={warehouse_1}></img>
                      <span>Добавить / изменить склад</span>
                    </CardBody>
                  </button>
                </Link>
              </Card>
            </Col>
            {/*<Col xxl={3} className={styles.navigate_btns}>*/}
            {/*    <Card className={styles.navigate_btns__card}>*/}
            {/*        <Link to={'/products'} className="text-center">*/}
            {/*            <button className="text-center btn btn-primary w-100">*/}
            {/*                <CardBody className={`p-0 ${styles.navigate_btns__text}`}>*/}
            {/*                    <img src={plus_2}></img><span>Добавить пользователя</span>*/}
            {/*                </CardBody>*/}
            {/*            </button>*/}
            {/*        </Link>*/}
            {/*    </Card>*/}
            {/*</Col>*/}
            <Col xxl={3} className={styles.navigate_btns}>
              <Card className={styles.navigate_btns__card}>
                {/*<Link to={'/add-sales-channel'} className="text-center">*/}
                <button
                  className="text-center btn btn-info w-100"
                  onClick={handleSalesChannelChange}
                >
                  <CardBody className={`p-0 ${styles.navigate_btns__text}`}>
                    <img src={trend_3}></img>
                    <span>Добавить канал продаж</span>
                  </CardBody>
                </button>
                {/*</Link>*/}
              </Card>
            </Col>
            {hasPermissions(["owner"]) && (
              <Col xxl={3} className={styles.navigate_btns}>
                <Card className={styles.navigate_btns__card}>
                  <button
                    onClick={handleModalOrganization}
                    className="text-center btn btn-secondary w-100"
                  >
                    <CardBody className={`p-0 ${styles.navigate_btns__text}`}>
                      <img src={setting_4}></img>
                      <span>Изменить данные о компании</span>
                    </CardBody>
                  </button>
                </Card>
              </Col>
            )}

            {/*<Col xxl={9} className={styles.navigate_btns}>*/}
            <Row>
              {accounts && (
                <Col xxl={5}>
                  <h4 className="mt-2">Аккаунты</h4>
                  <div className={`d-flex px-5 py-4 ${styles.table_div}`}>
                    <select
                      className="form-select"
                      name="account_select"
                      id="account_select"
                      onChange={(event) =>
                        setSelectedAccount(event.target.value)
                      }
                      value={selectedAccount}
                    >
                      {accounts?.results &&
                        Object.entries(accounts.results).map(
                          ([email, data], key) => (
                            <option key={key + 1} value={email}>
                              {email} ({accounts?.access_options[data.access]})
                            </option>
                          )
                        )}
                    </select>
                    <button
                      className="btn btn-outline-info btn-sm fs-12"
                      disabled={selectedAccount === accounts?.current}
                      style={{
                        marginLeft: "15px",
                        opacity: selectedAccount === accounts?.current ? 0 : 1,
                      }}
                      onClick={handleChangeAccount}
                    >
                      Переключить
                    </button>
                  </div>
                </Col>
              )}
              <Col xxl={12}>
                <h4 className="mt-2">Сотрудники</h4>
                {invitations?.pending_invitations.length > 0 && (
                  <div
                    className={`table-responsive px-5 pt-3 pb-2 ${styles.table_div}`}
                  >
                    <h6>Приглашения</h6>
                    <Table className="table mb-0">
                      <thead className="table-light">
                        <tr>
                          <th scope="col">От</th>
                          <th scope="col">Доступ</th>
                        </tr>
                      </thead>
                      <tbody>
                        {invitations.pending_invitations.map(
                          (invitation, key) => (
                            <tr key={key} className="position-relative">
                              <td style={{ textDecoration: "underline" }}>
                                {invitation.account.email}
                              </td>
                              <td>
                                <span
                                  className={styles.role_span}
                                  style={{ paddingRight: "155px" }}
                                >
                                  {accounts?.access_options && invitation?.access && accounts?.access_options[invitation?.access]}
                                </span>
                                <div className={styles.accept_btn}>
                                  <button
                                    // value={}
                                    className="btn btn-success btn-sm"
                                    onClick={() =>
                                      handleAcceptInvitation(invitation.id)
                                    }
                                  >
                                    Принять
                                  </button>
                                  <span className="px-1">/</span>
                                  <button
                                    className="btn btn-danger btn-sm"
                                    onClick={() =>
                                      handleRejectInvitation(invitation.id)
                                    }
                                  >
                                    Отклонить
                                  </button>
                                </div>
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </Table>
                  </div>
                )}

                <div
                  className={`table-responsive px-5 py-4 ${styles.table_div}`}
                >
                  <Table
                    className="table mb-0 fs-14"
                    striped={true}
                    hover={true}
                    responsive={true}
                    reflow="true"
                    bordered={true}
                  >
                    <thead className="table-light">
                      <tr
                        style={{
                          borderTop: "1px solid #999",
                          borderBottom: "1px solid #999",
                        }}
                      >
                        <th className={styles.th_p} scope="col">
                          Пользователь:
                        </th>
                        <th className={styles.th_p} scope="col">
                          Email
                        </th>
                        <th className={styles.th_p} scope="col">
                          Доступ
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr key={0} className="position-relative">
                        <td className={styles.td_p}>
                          {authUser.username || "-"}
                        </td>
                        <td
                          className={styles.td_p}
                          style={{ textDecoration: "underline" }}
                        >
                          {authUser.email}
                        </td>
                        <td className={styles.td_p}>
                          <span className={styles.role_span}>Владелец</span>
                          {/*<div className={styles.edit_btn}>*/}
                          {/*    <img src={edit} alt="редактировать"/>*/}
                          {/*</div>*/}
                        </td>
                      </tr>
                      {invitations.user_invitations.map((invitation, key) => (
                        <tr key={key + 1} className="position-relative">
                          <td className={styles.td_p}>
                            {invitation?.invited_user.username}
                          </td>
                          <td
                            className={styles.td_p}
                            style={{ textDecoration: "underline" }}
                          >
                            {invitation?.invited_user.email}
                          </td>
                          <td className={styles.td_p}>
                            <span className={styles.role_span}>
                              {accounts?.access_options && invitation?.access && accounts.access_options[invitation.access]}
                            </span>
                            {hasPermissions(["owner"]) && (
                              <div
                                onClick={() => handleModalPermissions(invitation)}
                                className={styles.edit_btn}
                              >
                                <img src={edit} alt="редактировать" />
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}
                      {/*<tr className="position-relative">
                            <td className={styles.td_p}>Валентин</td>
                            <td className={styles.td_p}
                                style={{textDecoration: 'underline'}}>aishanoshopkz@gmail.com
                            </td>
                            <td className={styles.td_p}>
                                <span className={styles.role_span}>Админ</span>
                                <div className={styles.edit_btn}>
                                    <img src={edit} alt="редактировать"/>
                                </div>
                            </td>
                        </tr>*/}
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
            <div className={styles.spacer}></div>
          </Row>

          <Modal isOpen={isModalSalesChannelOpen}>
            <div ref={resultsRef}>
              <ModalHeader toggle={handleSalesChannelModalClose}>
                Каналы продаж
              </ModalHeader>
              <ModalBody>
                <div
                  className={styles.header_modal}
                  style={{ marginBottom: "15px" }}
                >
                  <Button
                    onClick={handleAddSalesChannel}
                    color="success"
                    className="add-btn"
                    id="create-btn"
                  >
                    <i className="ri-add-line align-bottom me-1"></i> Добавить
                  </Button>
                  {/*<div
                        className={styles.search_prod}
                    >
                      <i className={`ri-search-line ${styles["add_prod"]}`}></i>
                      <input
                          style={{ border: "none" }}
                          placeholder="Поиск по каналам продаж..."
                          type="text"
                          // onChange={onChangeModalValue}
                          // value={searchModalValue}
                      />
                    </div>*/}
                </div>
                <div
                  style={{
                    overflowY: "auto",
                    height: "47vh",
                    marginTop: "15px",
                  }}
                >
                  {salesChannels && salesChannels.length > 0 ? (
                    <table className={styles.table_customer}>
                      <thead>
                        <tr>
                          <th>Название</th>
                        </tr>
                      </thead>
                      <tbody>
                        {salesChannels.map((sales_channel, key) => (
                          <tr
                            key={key}
                            className="text-center position-relative"
                          >
                            <td
                            // className={styles.td_picker}
                            // onClick={() => handleSupplierClick(supplier)}
                            >
                              {sales_channel.name}
                            </td>
                            <div
                              className={styles.delete_inline}
                              onClick={() =>
                                handleDeleteSalesChannelInline(sales_channel.id)
                              }
                            >
                              <img src="/delete-inline.svg" alt="delete" />
                            </div>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <h5
                      style={{ lineHeight: "1.6", color: "grey" }}
                      className="text-center mt-3"
                    >
                      Каналов продаж не найдено
                    </h5>
                  )}
                </div>
              </ModalBody>
            </div>
          </Modal>

          <Modal isOpen={isSalesAddModalOpen}>
            <div ref={resultsAddRef}>
              <ModalHeader toggle={handleAddSalesChannelModalClose}>
                Канал продаж
              </ModalHeader>
              <ModalBody>
                <div>
                  {" "}
                  {/*className="cart"*/}
                  <Label htmlFor="articleInput" className="form-label">
                    <h6>Введите название</h6>
                  </Label>
                  <Input
                    type="text"
                    value={salesChannelInput}
                    onChange={handleSalesChannelInputChange}
                    id="nameInput"
                    invalid={!!errorsSuppliers?.name}
                  />
                  {errorsSuppliers && errorsSuppliers?.name ? (
                    <FormFeedback className={styles.feedback}>
                      <span className="lh-1">{errorsSuppliers.name}</span>
                    </FormFeedback>
                  ) : null}
                </div>
              </ModalBody>
              <ModalFooter>
                <Button onClick={addSalesChannel} type="submit" color="primary">
                  Сохранить
                </Button>{" "}
              </ModalFooter>
            </div>
          </Modal>

          {modalOpenPermissions && (
            <>
              <Modal
                className={styles.modal_product}
                isOpen={modalOpenPermissions}
                toggle={modalPermissionsClose}
              >
                <Form onSubmit={handlePermissionFormSubmit}>
                  <ModalHeader
                    toggle={modalPermissionsClose}
                    className={styles.padding_modal}
                  >
                    Данные пользователя
                  </ModalHeader>
                  <ModalBody className={styles.padding_modal}>
                    <div className={styles.form_name}>
                      <div className={styles.cart}>
                        <Label htmlFor="price-field" className="form-label mb-0">
                          <h5>Имя</h5>
                        </Label>
                        <Input
                          name="name"
                          id="name-field"
                          type="text"
                          className="form-control"
                          value={invitation?.invited_user.username || ""}
                          readOnly={true}
                        />
                      </div>
                      <div className={styles.cart}>
                        <Label htmlFor="email-field" className="form-label mb-0">
                          <h5>Email</h5>
                        </Label>
                        <Input
                          name="email"
                          id="email-field"
                          type="text"
                          className="form-control"
                          placeholder="Введите email..."
                          value={invitation?.invited_user.email || ""}
                          readOnly={true}
                        />
                      </div>
                    </div>
                    <div className={styles.choose_user_type}>
                      <h5>Тип пользователя</h5>
                      <div className={styles.user_type}>
                        <Input
                            type="radio"
                            className={styles.check}
                            name="access"
                            value="admin"
                            checked={invitation?.access === 'admin'}
                            onChange={handleUpdateInvitation}
                        />
                        <span>Администратор</span>
                        <p>
                          Полный доступ, возможность создания и удаления заказов,
                          товаров, приемок, себестоимости, аналитики продаж.
                        </p>{" "}
                      </div>

                      <div className={styles.user_type}>
                        {" "}
                        <Input
                            type="radio"
                            className={styles.check}
                            name="access"
                            value="manager"
                            checked={invitation?.access === 'manager'}
                            onChange={handleUpdateInvitation}
                        />
                        <span>Менеджер</span>
                        <p>
                          Ограниченный доступ: просмотр товаров и заказов.
                          Запрещено удаление и исправление сохраненных заказов.
                        </p>
                      </div>
                    </div>
                    <div className={styles.modal_settings}>
                      <h5>Настройка доступа</h5>
                      <div className={styles.table_settings}>
                        <table className="w-100">
                          <tbody>
                            <tr key={0}>
                              <td>
                                {" "}
                                <Input
                                    type="checkbox"
                                    onChange={e => handlePermissionsSelectAllChange(e.target.checked)}
                                />
                              </td>
                              <td>Выбрать все</td>
                            </tr>
                            {Object.entries(allAccessObjects).map(([name, data], key) => (
                              <tr key={key + 1}>
                                <td>
                                  {" "}
                                  <Input
                                      type="checkbox"
                                      name={name}
                                      checked={invitation?.access_objects.some(obj => obj.name === name)}
                                      onChange={e => handlePermissionsAccessObjectChange(
                                          name, e.target.checked
                                      )}
                                  />
                                </td>
                                <td>
                                  <span className={styles.bold_name}>{data.label}: </span>
                                  {data.description}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </ModalBody>
                  <ModalFooter className={styles.padding_modal}>
                    <Button type="submit" color="success">
                      Сохранить
                    </Button>{" "}
                  </ModalFooter>
                </Form>
              </Modal>
            </>
          )}

          {handleModalOrganization && (
            <>
              <Modal
                // className={styles.modal_product}
                isOpen={modalOpenOrganization}
                toggle={modalOrganizationClose}
              >
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    validationOrganization.handleSubmit();
                    return false;
                  }}
                >
                  <ModalHeader toggle={modalOrganizationClose}>
                    Настройки организации
                  </ModalHeader>
                  <ModalBody>
                    <div className={styles.form_name_organization}>
                      <div className={styles.cart_organization}>
                        <Label htmlFor="name-field" className="form-label mb-0">
                          <h5>Наименование ИП / ТОО</h5>
                        </Label>
                        <Input
                          name="name"
                          id="name-field"
                          type="text"
                          className="form-control"
                          placeholder="Введите наименование организации..."
                          onChange={validationOrganization.handleChange}
                          onBlur={validationOrganization.handleBlur}
                          value={validationOrganization.values.name || ""}
                          invalid={
                            !!(
                              validationOrganization.touched.name &&
                              validationOrganization.errors.name
                            ) || !!errorsOrganization?.name
                          }
                        />
                        {validationOrganization.touched.name &&
                          validationOrganization.errors.name ? (
                            <FormFeedback type="invalid">
                              <div>{validationOrganization.errors.name}</div>
                            </FormFeedback>
                          ) : errorsOrganization && errorsOrganization?.name ? (
                            <FormFeedback type="invalid">
                              <div>{errorsOrganization.name}</div>
                            </FormFeedback>
                          ) : null
                        }
                      </div>
                      <div className={styles.cart_organization}>
                        <Label htmlFor="phone-field" className="form-label mb-0">
                          <h5>Телефон</h5>
                        </Label>
                        <Input
                          name="phone"
                          id="phone-field"
                          type="text"
                          className="form-control"
                          placeholder="Введите телефон организации..."
                          onChange={validationOrganization.handleChange}
                          onBlur={validationOrganization.handleBlur}
                          value={validationOrganization.values.phone || ""}
                          invalid={
                            !!(
                              validationOrganization.touched.phone &&
                              validationOrganization.errors.phone
                            ) || !!errorsOrganization?.phone
                          }
                        />
                        {validationOrganization.touched.phone &&
                          validationOrganization.errors.phone ? (
                            <FormFeedback type="invalid">
                              <div>{validationOrganization.errors.phone}</div>
                            </FormFeedback>
                          ) : errorsOrganization && errorsOrganization?.phone ? (
                            <FormFeedback type="invalid">
                              <div>{errorsOrganization.phone}</div>
                            </FormFeedback>
                          ) : null
                        }
                      </div>
                      <div className={styles.cart_organization}>
                        <Label htmlFor="email-field" className="form-label mb-0">
                          <h5>Email </h5>
                        </Label>
                        <Input
                          name="email"
                          id="email-field"
                          type="text"
                          className="form-control"
                          placeholder="Введите email организации..."
                          onChange={validationOrganization.handleChange}
                          onBlur={validationOrganization.handleBlur}
                          value={validationOrganization.values.email || ""}
                          invalid={
                            !!(
                              validationOrganization.touched.email &&
                              validationOrganization.errors.email
                            ) || !!errorsOrganization?.email
                          }
                        />
                        {validationOrganization.touched.email &&
                          validationOrganization.errors.email ? (
                            <FormFeedback type="invalid">
                              <div>{validationOrganization.errors.email}</div>
                            </FormFeedback>
                          ) : errorsOrganization && errorsOrganization?.email ? (
                            <FormFeedback type="invalid">
                              <div>{errorsOrganization.email}</div>
                            </FormFeedback>
                          ) : null
                        }
                      </div>
                      <div className={styles.cart_organization}>
                        <Label className="form-label mb-0">
                          {" "}
                          <h5>Адрес</h5>
                        </Label>

                        <Col xxl={3} md={6} style={{ width: "100%" }}>
                          <Input
                            type="text"
                            name='address'
                            className="form-control"
                            id="address"
                            rows="3"
                            placeholder="Введите адрес организации..."
                            style={{ width: "100%", fontSize: "13px" }}
                            onChange={validationOrganization.handleChange}
                            onBlur={validationOrganization.handleBlur}
                            value={validationOrganization.values.address || ""}
                            // invalid={
                            //   !!(
                            //     validationOrganization.touched.name &&
                            //     validationOrganization.errors.name
                            //   ) || !!errorsOrganization?.name
                            // }
                          ></Input>
                          {validationOrganization.touched.address &&
                            validationOrganization.errors.address ? (
                              <FormFeedback type="invalid">
                                <div>{validationOrganization.errors.address}</div>
                              </FormFeedback>
                            ) : errorsOrganization && errorsOrganization?.address ? (
                              <FormFeedback type="invalid">
                                <div>{errorsOrganization.address}</div>
                              </FormFeedback>
                            ) : null
                          }
                        </Col>
                      </div>
                      <div className={styles.cart_organization}>
                        <Label className="form-label mb-0">
                          {" "}
                          <h5>Описание гарантии</h5>
                        </Label>

                        <Col xxl={3} md={6} style={{ width: "100%" }}>
                          <textarea
                              className="form-control"
                              id="warranty"
                              rows="6"
                              placeholder="Заполните описание гарантии для распечатки заказов"
                              style={{ width: "100%", fontSize: "13px" }}
                              onChange={validationOrganization.handleChange}
                              onBlur={validationOrganization.handleBlur}
                              value={validationOrganization.values.warranty || ""}
                              // invalid={
                              //   !!(
                              //     validationOrganization.touched.name &&
                              //     validationOrganization.errors.name
                              //   ) || !!errorsOrganization?.name
                              // }
                          ></textarea>
                          {validationOrganization.touched.warranty &&
                          validationOrganization.errors.warranty ? (
                              <FormFeedback type="invalid">
                                <div>{validationOrganization.errors.warranty}</div>
                              </FormFeedback>
                          ) : errorsOrganization && errorsOrganization?.warranty ? (
                              <FormFeedback type="invalid">
                                <div>{errorsOrganization.warranty}</div>
                              </FormFeedback>
                          ) : null
                          }
                        </Col>
                      </div>
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <Button type="submit" color="success">
                      Сохранить
                    </Button>{" "}
                  </ModalFooter>
                </Form>
              </Modal>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Settings;
