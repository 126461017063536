import React from 'react';
import {GoogleOAuthProvider} from "@react-oauth/google";
import {google} from "../../../config";
import CustomGoogleLogin from "./CustomGoogleLogin";


const GoogleWrapper = () => {


    return (
        <GoogleOAuthProvider clientId={google.CLIENT_ID}>
            <CustomGoogleLogin />
        </GoogleOAuthProvider>
    );
};

export default GoogleWrapper;