import React from 'react';
import { Chip, Stack } from '@mui/material';
import styled from 'styled-components';

const Container = styled.div`
    max-height: 93px; // Максимальная высота контейнера
    overflow-y: auto; // Автоматическая прокрутка по вертикали
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    gap: 4px;
`;

function SelectedProducts({ selectedProducts, products, onDelete }) {
    const selectedItems = products.filter(product => selectedProducts.includes(product.id));


    return (
        <Container>
            {selectedItems.length === 0 ? (
                <div>Нет выбранных товаров</div>
            ) : (
                selectedItems.map(product => {
                    const truncatedTitle = product.title.length > 30 ? `${product.title.slice(0, 30)}...` : product.title;
                    return (
                        <Chip
                            style={{justifyContent: 'space-between'}}
                            key={product.id}
                            label={truncatedTitle}
                            onDelete={() => onDelete(product.id)}
                            size="small"
                        />
                    );
                })
            )}
        </Container>
    );
}

export default SelectedProducts;
