import React from 'react';
import './index.css';
import {Input} from "reactstrap"; // Подключаем CSS файл для стилей таблицы

const EventLogTable = ({ events, rows }) => {
  return (
    <div className="table-container">
      <table className="event-log-table">
        <thead>
          <tr>
            <th>Время события</th>
            <th>Тип события</th>
            {events[0]?.uploaded_pricelist && <th>Загруженный файл</th>}
            <th>Описание события</th>
          </tr>
        </thead>
        <tbody className="even_log_table__tbody">
          {events && events.length > 0 && events.map((event, index) => (
            <tr key={index} className={index % 2 === 0 ? 'even-row' : 'odd-row'}>
              <td>{event.formatted_created}</td>
              <td>{event.log_type}</td>
              {event?.uploaded_pricelist &&
                  // <td style={{minWidth: '400px'}}>
                  <td >
                    <Input
                        readOnly={true}
                        value={event.uploaded_pricelist}
                    />
                  </td>
              }
              {event?.uploaded_pricelist ?
                  <td>
                    {event.description}
                  </td>
                  :
                  <td className="textarea-td">
                    <textarea readOnly={true} rows={rows} value={event.description}></textarea>
                  </td>
              }
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default EventLogTable;