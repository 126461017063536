import React from 'react';
import { Col, Container, Row } from 'reactstrap';

const Footer = () => {
    return (
        <React.Fragment>
            <footer className="footer">
                <Container fluid>
                    <Row className="d-flex justify-content-between" >
                        <Col sm={6} style={{maxWidth: '50%'}}>
                            {new Date().getFullYear()} © CRMBEK
                        </Col>
                        <Col sm={6} style={{textAlign: 'right', maxWidth: '50%'}}>
                            <a href="https://crmbek.kz/privacy-policy/" target='_blank'>privacy-policy</a>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </React.Fragment>
    );
};

export default Footer;