import React, { useState, useEffect } from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart, CategoryScale } from 'chart.js';
Chart.register(CategoryScale);
import styles from '../Analytics/Analytics.module.css'

const PieChart = ({ data }) => {
  const [position, setPosition] = useState('right');
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    const legendPosition = () => {
      if (window.innerWidth > 600) {
        setPosition('right');
      } else {
        setPosition('top');
      }
    };

    legendPosition();

    window.addEventListener('resize', legendPosition);

    return () => {
      window.removeEventListener('resize', legendPosition);
    };
  }, []);

  useEffect(() => {
    if (data && Array.isArray(data)) {
      const colors = [
        "#FF6384",
        "#36A2EB",
        "#FFCE56",
        "#4BC0C0",
        "#9966FF",
        "#FF9F40",
        "#32CD32",
        "#FF0000",
        "#800080",
        "#00f080",
        "#fffd9c",
        "#92c1e8",
      ];

      const updatedChartData = {
        labels: data.map(item => item.title),
        datasets: [
          {
            data: data.map(item => item.total),
            backgroundColor: colors.map(color => color + '9A'),
            hoverBackgroundColor: colors,
            hoverBorderColor: "#fff"
          }
        ]
      };

      setChartData(updatedChartData);
    }
  }, [data]);

  const options = {
    plugins: {
      legend: {
        position: position,
        labels: {
          font: {
            family: "Montserrat",
            size: "18px"
          },
          padding: 1
        }
      }
    }
  };

  return (
    <div className={styles.container}>
      {chartData && <Pie data={chartData} options={options} className="chartjs-chart" />}
    </div>
  );
};

export default PieChart;