import React, { useEffect, useState } from "react";
import ReceptionCreate from "./ReceptionCreate";
import { Link, useLocation } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import {
  deleteReceptionApi,
  getAccountsApi,
  getReceptionsDataApi,
} from "../../helpers/backend_helper";
import styles from "./InvoiceCreate.module.css";

import {
  padNumberWithZeros,
  convertToReadableDateTime,
} from "../../helpers/utils";
import Pagination from "../../Components/Common/Pagination";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import Loader from "../../Components/Common/Loader";
import WriteOffsCreate from "./WriteOffsCreate";

const Reception = () => {
  const [loading, setLoading] = useState(true);
  const [receptions, setReceptions] = React.useState([]);
  const location = useLocation();

  const [currentPage, setCurrentPage] = useState(1); // Initial page
  const perPageData = 16;

  const [accounts, setAccounts] = useState({});
  const hasPermissions = (permissions) => {
    return (
      accounts.results &&
      accounts.current &&
      permissions.includes(accounts.results[accounts.current].access)
    );
  };
  const getAccessObjects = () => {
    return String(
      accounts?.results &&
        accounts.current &&
        accounts?.results[accounts.current]?.access_objects
    );
  };

  useEffect(() => {
    const fetchAccounts = () => {
      getAccountsApi()
        .then((data) => {
          setAccounts(data);
        })
        .catch(() => {
          // toast.error("Не удалось получить список аккаунтов", {
          //   position: "top-right",
          // });
        });
    };
    fetchAccounts();
  }, []);

  React.useEffect(() => {
    const message = location.state && location.state.message;
    if (message) {
      toast.success(message);
    }

    const fetchReceptionsData = () => {
      getReceptionsDataApi()
        .then((data) => {
          setReceptions(data);
        })
        .catch(() => {
          toast.error("Не удалось получить данные о приемках");
        })
        .finally(() => {
          setLoading(false);
        });
    };

    fetchReceptionsData();
  }, []);

  const handleDelete = (itemId) => {
    // axios
    deleteReceptionApi({ id: itemId })
      .then((response) => {
        const itemToDelete = receptions.find((item) => item.id === itemId);
        setReceptions(receptions.filter((item) => item.id !== itemId));
        toast.success(
          itemToDelete.write_off
            ? "Списание успешно удалено"
            : "Приёмка успешно удалена",
          { position: "top-right" }
        );
      })
      .catch((error) => {
        toast.error("Не удалось удалить елемент", {
          position: "top-right",
        });
      });
  };

  const filteredReceptions = receptions.filter((reception) => {
    return true;
  });
  // Calculate the start and end index for the current page
  const startIndex = (currentPage - 1) * perPageData;
  const endIndex = startIndex + perPageData;

  // Get the slice of orders to display on the current page
  const receptionsToShow = filteredReceptions.slice(startIndex, endIndex);

  //////////////////////////////////////////////////

  document.title = "CRMBEK : Приемки";

  return (
    <React.Fragment>
      <ToastContainer closeButton={false} />
      <div className="page-content pb-5">
        {" "}
        {/*px-5*/}
        <Container fluid>
          <BreadCrumb title="Приемки" />
          {/*<h4>Все приемки</h4>*/}
          <Row>
            <Col lg={12}>
              {hasPermissions(["owner"]) ||
              getAccessObjects().includes("receptions") ? (
                <Card>
                  <CardBody>
                    <div className="listjs-table" id="customerList">
                      <Row className="g-4 mb-3">
                        <Col className="col-sm-auto d-flex">
                          <ReceptionCreate />

                          <div style={{ padding: "0 5px" }}>
                            <WriteOffsCreate />
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div
                      className="table-responsive table-card mt-3 mb-1"
                      style={{ overflowX: `${loading ? "unset" : "auto"}` }}
                    >
                      {loading ? ( // Show loader only when loading
                        <Loader />
                      ) : filteredReceptions &&
                        filteredReceptions.length > 0 ? (
                        <table
                          className={`table align-middle table-nowrap table-hover ${styles["table_reception"]}`}
                          id="customerTable"
                          style={{ marginBottom: "0.7rem" }}
                        >
                          <thead className="table-light">
                            <tr>
                              <th data-sort="customer_name">
                                <span className="py-1">Название</span>
                              </th>
                              <th data-sort="date">
                                <span>Дата приемки/списания</span>
                              </th>

                              <th data-sort="email">
                                <span className="py-1">Поставщик</span>
                              </th>
                              <th data-sort="phone">
                                <span>Склад</span>
                              </th>

                              <th>
                                <span>Себестоимость</span>
                              </th>
                              {/* <th>
                                <span>Причина списания</span>
                              </th> */}
                              <th>
                                <span>Статус</span>
                              </th>
                              <th>
                                {" "}
                                <span></span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {receptionsToShow.map((item, id) => (
                              <tr key={id}>
                                <td>
                                  {!item.write_off ? (
                                    <Link to={`/reception-new/${item.id}`}>
                                      Приемка #
                                      {padNumberWithZeros(item.number, 3)}
                                    </Link>
                                  ) : (
                                    <Link to={`/writeoffs-new/${item.id}`}>
                                      Списание #
                                      {padNumberWithZeros(item.number, 3)}
                                    </Link>
                                  )}
                                </td>
                                <td>
                                  {/* <span>
                                    {item.received_date
                                      ? String(item.received_date)
                                          .replace("T", " ")
                                          .replace("Z", "")
                                      : ""}
                                  </span> */}
                                  {item.received_date ? (
                                    <div style={{ lineHeight: "1.3" }}>
                                      <div>
                                        {
                                          convertToReadableDateTime(
                                            item.received_date
                                          ).split(" ")[0]
                                        }
                                      </div>
                                      <div>
                                        {
                                          convertToReadableDateTime(
                                            item.received_date
                                          ).split(" ")[1]
                                        }
                                      </div>
                                    </div>
                                  ) : (
                                    "-"
                                  )}
                                </td>
                                <td>
                                  <span>
                                    {!item.write_off ? (
                                      String(
                                        item.supplier_obj
                                          ? item.supplier_obj.name
                                          : "нет"
                                      )
                                    ) : (
                                      <span>---</span>
                                    )}
                                  </span>
                                </td>
                                <td>
                                  <span>
                                    {String(
                                      item.storage_obj
                                        ? item.storage_obj?.full_name
                                        : "нет"
                                    )}
                                  </span>
                                </td>

                                <td>
                                  <span>
                                    {String(item.final_self_cost) + " ₸"}
                                  </span>
                                </td>
                                {/* <td><span>
                                  {!item.write_off ? (
                                     <span>---</span>
                                  ) : (
                                    <span>{item.cause ? item.cause : ""}</span>
                                  )}
                                   
                                  </span></td> */}
                                {item.draft ? (
                                  <>
                                    <td>
                                      <span className="badge bg-danger-subtle text-danger fs-11">
                                        Черновик
                                      </span>
                                    </td>

                                    <td>
                                      <div className="hstack gap-3">
                                        <span className="">
                                          <Link
                                            to={`/reception-new/${item.id}`}
                                            className="link-success"
                                          >
                                            <i className="ri-edit-2-line"></i>
                                          </Link>
                                        </span>
                                        <Link to="#" className="link-danger">
                                          <span
                                            className="badge bg-danger"
                                            onClick={() =>
                                              handleDelete(item.id)
                                            }
                                          >
                                            Удалить
                                          </span>
                                        </Link>
                                      </div>
                                    </td>
                                  </>
                                ) : (
                                  <>
                                    <td>
                                      <span className="badge bg-success-subtle text-success py-1 fs-11">
                                        Загружено
                                      </span>
                                    </td>
                                    <td>
                                      {" "}
                                      <span className="py-1"></span>
                                    </td>
                                  </>
                                )}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <h5
                          style={{ lineHeight: "1.6", color: "grey" }}
                          className="text-center mt-3"
                        >
                          Приемок не найдено.
                        </h5>
                      )}
                    </div>

                    {filteredReceptions && filteredReceptions.length > 0 ? (
                      <Pagination
                        data={filteredReceptions}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        perPageData={perPageData}
                        className="pagination" /* pagination-separated */
                        numResults={receptionsToShow.length}
                      />
                    ) : null}
                  </CardBody>
                </Card>
              ) : (
                <h3 className="text-center my-5" style={{ opacity: 0.4 }}>
                  У вас нет прав для просмотра данной страницы
                </h3>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Reception;
