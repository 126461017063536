import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  CardBody,
  Row,
  Col,
  Card,
  Container,
  Form,
  Input,
  Label,
  Button,
  Table,
  ModalFooter,
  FormFeedback,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";

// import Dropzone from "react-dropzone";
import { Link, useNavigate, useParams } from "react-router-dom";

import styles from "./InvoiceCreate.module.css";

//formik
import { useFormik } from "formik";
import * as Yup from "yup";

//redux
import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import {
  addSupplierApi,
  createProductApi,
  getProductsApi,
  getReceptionDetailApi,
  getStoragesApi,
  getSuppliersApi,
  updateReceptionApi,
} from "../../helpers/backend_helper";
import getCurrentDateTime from "../../slices/invoice/utils";
import { padNumberWithZeros } from "../../helpers/utils";
import Loader from "../../Components/Common/Loader";
import PrintButton from "../Prints/PrintButton";
import AddModal from "../../Components/Common/AddModal";


const DetailsReception = () => {
  const [loading, setLoading] = useState(true);
  const [productsLoading, setProductsLoading] = useState(true);

  const dispatch = useDispatch();
  const history = useNavigate();

  const [ispaymentDetails, setispaymentDetails] = useState(null);
  const [isCurrency, setisCurrency] = useState("₸");

  function handleispaymentDetails(ispaymentDetails) {
    setispaymentDetails(ispaymentDetails);
  }

  function handleisCurrency(isCurrency) {
    setisCurrency(isCurrency);
  }

  const allcurrency = [
    {
      options: [{ label: "₸", value: "(₸)" }],
    },
  ];

  document.title = "CRMBEK : Приемка";
  // const accessToken = "t2nsWEmkLxVpwW2h3UnFBaRlSQDVo2";
  // axios.defaults.baseURL = "http://207.154.232.92/api";
  // axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

  const { receptionId } = useParams();
  const [receptionData, setReceptionData] = useState({
    number: 0,
    draft: true,
    // write_off: false,
    cause: "",
    storage: null,
    storage_obj: null,
    final_self_cost: null,
    supplier: null,
    created: null,
    received_date: null,
    received_products: [],
    supplier_obj: {},
  });
  const [productData, setProductData] = useState({
    title: "",
    code: "",
    self_cost: null,
    image: null,
    price: null,
    parameters: [],
  });

  const [newSupplier, setNewSupplier] = useState({
    name: "",
  });

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  // ДАННЫЕ
  const [storages, setStorages] = useState([]);
  const [supplierInput, setSupplierInput] = useState("");
  const [selectedSupplier, setSelectedSupplier] = useState("");
  const [date, setDate] = useState(receptionData.received_date);
  const [selectedItem, setSelectedItem] = useState(null);
  const [products, setProducts] = useState([]);
  const [supplier, setSupplier] = useState([]);
  // МОДАЛЬНЫЕ ОКНА
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddSupplier, setIsAddSupplier] = useState(false);
  const [isModalSupplierOpen, setIsModalSupplierOpen] = useState(false);
  const [isModalProductsOpen, setIsModalProductsOpen] = useState(false);

  useEffect(() => {
    const fetchReceptionData = async () => {
      try {
        // const response = await axios.get(`/core/reception/${receptionId}/`);
        const response = await getReceptionDetailApi({ id: receptionId });
        if (response) {
          setReceptionData(response);
          setLoading(false);
          // console.log("response.data: " + response);
        } else {
          console.error("Failed to fetch reception data");
        }
      } catch (error) {
        console.error("Error fetching reception data:", error);
      }
    };

    fetchReceptionData();
    // console.log(fetchReceptionData());
    // console.log(receptionId);
  }, [receptionId]);

  useEffect(() => {
    getProductsApi({ all: true })
    // axios
    //   .get("/core/product/")
    .then((response) => {
      if (response) {
        setProducts(response);
      } else {
        // console.error("Failed to fetch products");
      }
    })
    .catch((error) => {
      // console.error("Error fetching products:", error);
    })
    .finally(() => {
      setProductsLoading(false);
    });

    getStoragesApi()
      // axios
      //   .get("/core/storage/")
      .then((response) => {
        if (response) {
          setStorages(response);
        } else {
          // console.error("Failed to fetch products");
        }
      })
      .catch((error) => {
        // console.error("Error fetching products:", error);
      });

    fetchSuppliers();
  }, []); // Empty dependency array to run this effect only on mount

  useEffect(() => {
    if (!receptionData.draft) {
      toast.warn(
        receptionData?.write_off ?
            "Это списание уже было оформлено" :
            "Приёмка уже была загружена"
      );
    }
  }, [receptionData]);
  ////////////ДОБАВЛЯЕМ ПОСТАВЩИКА////////////////////

  const handleSupplierInputChange = (event) => {
    setSupplierInput(event.target.value);
    // setIsAddSupplier(false);
    // setIsModalSupplierOpen(false);
  };

  const fetchSuppliers = () => {
    getSuppliersApi()
      .then((response) => {
        setSupplier(response);
      })
      .catch((error) => {
        // console.error("Error fetching products:", error);
      });
  };

  const addSupplier = async () => {
    await addSupplierApi({ name: supplierInput })
      .then((response) => {
        setSupplierInput("");
        setSelectedSupplier(response.name);

        handleSupplierClick(response);
        fetchSuppliers();

        setIsAddSupplier(false);
        setIsModalSupplierOpen(false);
      })
      .catch((error) => {
        if (error?.response?.data?.errors && error?.response?.status === 400) {
          setErrors(error.response.data.errors);
        } else {
          toast.error("Не удалось добавить поставщика", {
            position: "top-right",
          });
        }
      });
  };

  ////////ОТПРАВКА ЗАПРОСА ТОВАРЫ///////////////
  // const addProduct = (newProductData) => {
  //   // axios
  //   //   .post("/core/product/", newProductData)
  //   createProductApi(newProductData)
  //     .then((response) => {
  //       setProducts([response, ...products]);
  //     })
  //     .catch((error) => {
  //       // console.error("Error creating product:", error);
  //     });
  //   handleCloseAddProduct();
  //
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProductData({ ...productData, [name]: value });
  };

  // const handleSubmitProduct = (e) => {
  //   e.preventDefault();
  //   addProduct(productData);
  // };

  //////////////////////////////////
  const updateReception = async (updatedData) => {
    try {
      setErrors({});
      // const response = await axios.patch(
      //   `/core/reception/${receptionId}/`,
      //   updatedData
      // );
      const response = await updateReceptionApi(receptionId, updatedData);
      setReceptionData(response);
      if (response.draft === false) {
        history("/receptions", {
          state: {
            message: receptionData?.write_off ?
                "Списание оформлено успешно. Остатки обновлены" :
                "Приёмка успешно загружена. Остатки обновлены",
          },
        });
      }
    } catch (error) {
      if (error?.response?.data?.errors && error?.response?.status === 400) {
        setErrors(error.response.data.errors);
      } else {
        toast.error("Не удалось обновить приемку");
      }
    }
  };

  const handleProductChange = () => {
    setIsModalOpen(true);
    setSearchModalValue("");
  };
  const handleProductClose = () => {
    setIsModalOpen(false);
    setSearchModalValue("");
  };

  const handleSupplierChange = () => {
    setIsModalSupplierOpen(true);
  };
  const handleSupplierClose = () => {
    setIsModalSupplierOpen(false);
  };

  const handleAddProductChange = () => {
    setProductData({});
    validationAddProduct.resetForm();
    setErrors({});
    setSelectedFile(null);
    setSelectedImage("");
    setIsModalProductsOpen(true);
  };

  const handleCloseAddProduct = () => {
    setIsModalProductsOpen(false);
  };
  const handleAddSupplier = () => {
    setIsAddSupplier(true);
  };

  const handleAddSupplierClose = () => {
    setIsAddSupplier(false);
  };
  const handleAddReceptionProduct = async (selected) => {
    // alert(selected);
    if (selected) {
      const productId = selected?.product_data
        ? selected.product_data.id
        : selected.id;
      const newReceivedProduct = {
        product: productId,
      };

      const updatedData = {
        ...receptionData,
        received_products: [
          ...receptionData.received_products,
          newReceivedProduct,
        ],
      };
      await updateReception(updatedData);
    }
    // setIsModalOpen(false);
  };

  const [updateTimeout, setUpdateTimeout] = useState(null);

  const handleFieldChange = (index, field, value) => {
    const updatedProducts = [...receptionData.received_products];
    updatedProducts[index][field] = value;

    setReceptionData((prevData) => ({
      ...prevData,
      received_products: updatedProducts,
    }));
    if (updateTimeout) {
      clearTimeout(updateTimeout);
    }
    const newUpdateTimeout = setTimeout(() => {
      updateReception({ received_products: updatedProducts });
    }, 1000);

    setUpdateTimeout(newUpdateTimeout);
  };

  const handleQtyChange = (index, value) => {
    if (value > 500) {
      value = 500;
    }
    if (value < 1) {
      value = 1;
    }
    handleFieldChange(index, "quantity", value);
  };

  const handleSelfCostChange = (index, value) => {
    handleFieldChange(index, "self_cost", value);
  };

  const handleStorageChange = (selectedStorageId) => {
    const updatedReceptionData = {
      ...receptionData,
      storage: selectedStorageId,
    };
    updateReception(updatedReceptionData);
  };

  const handleCommentChange = (newCommment) => {
    const updatedReceptionData = {
      ...receptionData,
      comment: newCommment,
    };
    setReceptionData(updatedReceptionData);
  };

  const handleCauseChange = (index, newCause) => {
    const updatedReceivedProducts = [...receptionData.received_products];
    updatedReceivedProducts[index] = {
      ...updatedReceivedProducts[index],
      cause: newCause,
    };

    const updatedReceptionData = {
      ...receptionData,
      received_products: updatedReceivedProducts,
    };

    setReceptionData(updatedReceptionData);
  };

  const handleUpdateReceptionData = () => {
    updateReception(receptionData);
  };

  const handleRemoveReceptionProduct = async (index) => {
    const updatedProducts = [...receptionData.received_products];
    updatedProducts.splice(index, 1);

    const updatedData = {
      ...receptionData,
      received_products: updatedProducts,
    };
    await updateReception(updatedData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const updatedData = {
      ...receptionData,
      draft: false,
    };
    await updateReception(updatedData);
    // .then(() => {
    //   history('/receptions', {
    //     state: { message: "Приёмка успешно загружена. Остатки были обновлены" },
    //   });
    // })
    // .catch((err) => {
    //   // console.error("Error updating reception:", error);
    //   toast.error("Ошибка при загрузке приемки");
    // })
  };

  const handleSupplierClick = (supplier) => {
    const updatedSupplier = { ...receptionData, supplier: supplier.id };

    updateReception(updatedSupplier);
    setSelectedSupplier(supplier.name);

    setIsModalSupplierOpen(false); // TODO: new
    setIsAddSupplier(false); // TODO: new
  };

  ///////////////////////////
  const receivedProducts = receptionData.received_products;
  const filteredProducts = products.filter((product) => {
    return !receivedProducts.some(
      (receivedProduct) => receivedProduct.product_data.id === product.id
    );
  });

  const handleProductSelect = (product) => {
    handleAddReceptionProduct(product);
    setSearchValue("");
    setIsModalOpen(false);
  };

  //////ОБНОВЛЕНИЕ ДАТЫ//////
  const handleDateChange = (event) => {
    const newDate = event.target.value;
    const newReceptionData = {
      ...receptionData,
      received_date: newDate,
    };
    updateReception(newReceptionData);
  };

  const newReceivedDate =
    receptionData.received_date && receptionData.received_date.includes("Z")
      ? receptionData.received_date.replace("Z", "")
      : receptionData.received_date;

  //////////////ДАТА КОТОРАЯ СЕГОДНЯ///////////////////
  const [currentDateTime, setCurrentDateTime] = useState(getCurrentDateTime());

  const handleDate = (e) => {
    setCurrentDateTime(e.target.value);
  };

  ///////////СОЗДАНИЕ ТОВАРОВ//////////////////
  const [selectedFiles, setselectedFiles] = useState([]);
  const [files, setFiles] = useState([]);
  const [fields, setFields] = useState([]);
  const [input1, setInput1] = useState("");
  const [input2, setInput2] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleAcceptedFiles = (files) => {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedFiles(files);
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const handleAddField = () => {
    setFields([...fields, { input1, input2 }]);
    const newParameter = { name: input1, value: input2 };

    // Обновите parameters внутри productData
    setProductData({
      ...productData,
      parameters: [...productData.parameters, newParameter],
    });

    // Очистите input1 и input2lue
    setInput1("");
    setInput2("");
  };

  const handleDeleteField = (index) => {
    const updatedFields = [...fields];
    updatedFields.splice(index, 1);
    setFields(updatedFields);
  };

  ///////////////ПОИСК ПО ТОВАРАМ///////////////////////////////
  const [searchValue, setSearchValue] = React.useState("");
  const [searchModalValue, setSearchModalValue] = React.useState("");

  const onChangeValue = (event) => {
    setSearchValue(event.target.value);
  };
  const onChangeModalValue = (event) => {
    setSearchModalValue(event.target.value);
  };
  /////////////////ОБРАБОТЧИК КЛИКОВ//////////////
  const resultsRef = useRef(null);
  // const resultsModalRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (resultsRef.current && !resultsRef.current.contains(event.target)) {
        setSearchValue(""); // Set searchValue to an empty string when clicking outside
      }
      // if (resultsModalRef.current && !resultsModalRef.current.contains(event.target)) {
      //   setSearchModalValue(""); // Set searchValue to an empty string when clicking outside
      // }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  ////////////////УДАЛИТЬТОВАР////////////////////////
  const handleDeleteProduct = (index) => {
    const updatedReceivedProducts = [...receptionData.received_products];
    updatedReceivedProducts.splice(index, 1);

    const updatedData = {
      ...receptionData,
      draft: true,
      received_products: updatedReceivedProducts,
    };

    setReceptionData(updatedData)
    updateReception(updatedData);

    // setReceptionData(updatedData);
  };

  const [errors, setErrors] = useState({});
  const [selectedImage, setSelectedImage] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  const validationAddProduct = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      image: (productData && productData.image) || "",
      title: (productData && productData.title) || "",
      code: (productData && productData.code) || "",
      description: (productData && productData.description) || "",
      price: (productData && productData.price) || "",
    },
    validationSchema: Yup.object({
      // image: Yup.string().required("Пожалуйста выберите картинку"),
      title: Yup.string().required("Пожалуйста введите название Товара"),
      code: Yup.string().required("Пожалуйста введите Артикул"),
      // price: Yup.number().required("Пожалуйста выставите Цену Товара")
    }),
    validateOnChange: true,

    validate: (values) => {
      const errors = {};
      if (values.price === 0) {
        values.price = "";
      } else if (values.price < 0) {
        errors.price = "Цена продажи не может быть отрицательной";
      } else if (values.price > 9999999) {
        errors.price = "Цена продажи не может быть больше 9999999";
      }
      return errors;
    },

    onSubmit: (values) => {
      setErrors({});

      const newProductData = new FormData();
      if (selectedFile) {
        // TODO PRODUCT
        newProductData.append("image", selectedFile);
      }
      newProductData.append("title", values.title);
      newProductData.append("code", values.code);
      newProductData.append("description", values.description);
      newProductData.append("price", values.price);

      createProductApi(newProductData)
        .then((createdProductData) => {
          // Call the function to update the element in teamList
          let updatedProductsData = [createdProductData, ...products];
          setProducts(updatedProductsData);
          setIsModalProductsOpen(false);
          // setModal(false);  // TODO PRODUCT
          toast.success("Товар был создан успешно", { position: "top-right" });
        })
        .catch((error) => {
          if (
            error?.response?.data?.errors &&
            error?.response?.status === 400
          ) {
            setErrors(error.response.data.errors);
          } else {
            // setErrors('Unexpected error occurred.')
            toast.error("Не удалось создать товар", { position: "top-right" });
          }
        });

      if (!errors) {
        validationAddProduct.resetForm();
        // toggle();  // TODO PRODUCT
      }
    },
  });

  // Checked All
  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
  const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);
  const [addModalMulti, setAddModalMulti] = useState(false);

  /*const addMultiple = async () => {
    // const checkall = document.getElementById("checkBoxAll");
    // Extract the selected IDs from the selectedCheckBoxDelete array
    const selectedIds = Array.from(selectedCheckBoxDelete).map(element => element.value);
    // Filter the products based on whether their id exists in the selectedIds array
    const filteredProducts = products.filter(product => selectedIds.includes(String(product.id)));

    for (const product of filteredProducts) {
      await handleAddReceptionProduct(product)
          .then(() => {
            // setProducts((prevProduct) =>
            //   prevProduct.filter((item) => String(item.id) !== element.value)
            // );
          })
          .catch((error) => {
            toast.error("Не удалось добавить товар", { position: "top-right" });
          });
      console.log(product);
      setTimeout(() => {
        toast.clearWaitingQueue();
      }, 3000);
    }
    // fetchProducts(fetchParams);
    // setDeleteModal(false);
    toast.success("Выбранные товары были успешно добавлены", {
      position: "top-right",
    });
    setIsMultiDeleteButton(false);
    // checkall.checked = false;
    // checkedAll();
  };*/
  const addMultiple = async () => {
    const selectedIds = Array.from(selectedCheckBoxDelete).map(element => element.value);
    const filteredProducts = products.filter(product => selectedIds.includes(String(product.id)));
    let updatedReceivedProducts = [...receptionData.received_products];

    for (const product of filteredProducts) {
      const newReceivedProduct = {
        product: product.id,
      };
      updatedReceivedProducts.push(newReceivedProduct);
    }

    const updatedData = {
      ...receptionData,
      received_products: updatedReceivedProducts,
    };

    await updateReception(updatedData)
      .then(() => {
        toast.success("Выбранные товары были успешно добавлены", {
          position: "top-right",
        });
        setIsMultiDeleteButton(false);
      })
      .catch(() => {
        toast.error("Не удалось добавить товары", { position: "top-right" });
      });
  };


  const deleteCheckbox = () => {
    const ele = document.querySelectorAll(".contactCheckBox:checked");
    ele.length > 0
        ? setIsMultiDeleteButton(true)
        : setIsMultiDeleteButton(false);
    setSelectedCheckBoxDelete(ele);
  };

  const checkedAll = useCallback(() => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".contactCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []);


  ///////////////////////////////////////////////
  return (
    <div className="page-content">
      {/* Modals */}
      {/*<AddModal*/}
      {/*    show={addModalMulti}*/}
      {/*    onAddClick={() => {*/}
      {/*      addMultiple();*/}
      {/*      setAddModalMulti(false);*/}
      {/*      setIsModalOpen(false);*/}
      {/*    }}*/}
      {/*    onCloseClick={() => setAddModalMulti(false)}*/}
      {/*/>*/}
      <Container fluid>
        {loading ? ( // Show loader only when loading
          <Loader />
        ) : receptionData ? (
          <Row className="justify-content-center pb-5">
            {/*px-5*/}
            <Col xxl={9}>
              <Card className={styles.card}>
                <Form
                  // onSubmit={handleSubmit}
                  onSubmit={(event) => event.preventDefault()}
                  className="needs-validation"
                  id="invoice_form"
                >
                  <CardBody className="border-bottom border-bottom-dashed p-4">
                    <div className={styles.header_reception}>
                      <div
                        className={styles.header_form}
                        style={{ alignItems: "baseline" }}
                      >
                        {" "}
                        <div className={styles.order_title}>
                          <Link to="/receptions" style={{ width: "30px" }}>
                            <i
                              className="ri-reply-fill"
                              style={{ fontSize: "20px" }}
                            ></i>
                          </Link>
                          <h5 style={{ paddingRight: "15px", marginBottom: 0 }}>
                            {receptionData.write_off ? "Списание " : "Приемка "}
                            #{padNumberWithZeros(receptionData.number, 3)}
                          </h5>
                        </div>
                        <div className={styles.nav}>
                          <div className={styles.container}>
                            <Input
                              className={styles.containered}
                              id="exampleInputdate"
                              readOnly={!receptionData?.draft}
                              disabled={!receptionData?.draft}
                              type="datetime-local"
                              min="2023-01-01T00:00"
                              max="2030-01-01T00:00"
                              required
                              value={newReceivedDate || getCurrentDateTime()}
                              onChange={handleDateChange}
                              invalid={!!errors?.received_date}
                            />
                            {errors && errors?.received_date ? (
                              <FormFeedback className={styles.feedback}>
                                <span className="lh-1">
                                  {errors.received_date}
                                </span>
                              </FormFeedback>
                            ) : null}
                          </div>
                          <Col lg={3} style={{ minWidth: "125px" }}>
                            <select
                              value={receptionData.storage || ""}
                              onChange={(e) => {
                                const selectedStorageId = e.target.value;
                                handleStorageChange(selectedStorageId);
                              }}
                              className={`form-select ${styles["input_select"]}`}
                              aria-label="Default select example"
                              disabled={!receptionData?.draft}
                            >
                              <option value="">Склад</option>
                              {storages.map((storage) => (
                                <option key={storage.id} value={storage.id}>
                                  {storage.name}
                                </option>
                              ))}
                              {/* Добавляем опцию для архивного склада */}
                              {receptionData?.storage_obj?.id &&
                                  !storages.some((storage) => storage.id === receptionData.storage_obj.id) && (
                                  <option key={receptionData.storage_obj.id} value={receptionData.storage_obj.id}>
                                    {receptionData.storage_obj?.full_name}
                                  </option>
                              )}
                            </select>
                            {errors && errors?.storage ? (
                              <div
                                style={{
                                  color: "#ed5e5e",
                                  maxWidth: "270px",
                                  marginTop: "5px",
                                  paddingLeft: "2px",
                                }}
                              >
                                {errors.storage}
                              </div>
                            ) : null}
                          </Col>
                        </div>
                      </div>
                    </div>
                    <Row>
                      <div className={styles.cart_block}>
                        {!receptionData.write_off ? (
                          <div className={styles.cart_reception}>
                            <Label htmlFor="basiInput" className="form-label">
                              <h5 className={styles.input_label}>Поставщик</h5>
                            </Label>
                            <div className={styles.provider}>
                              {" "}
                              <Input
                                type="text"
                                readOnly
                                disabled={!receptionData?.draft}
                                className={`form-control ${styles["input"]}`}
                                id="basiInput"
                                style={{ width: "75%" }}
                                value={
                                  selectedSupplier
                                    ? selectedSupplier
                                    : (receptionData &&
                                        receptionData.supplier_obj &&
                                        receptionData.supplier_obj.name) ||
                                      "Не выбрано"
                                }
                                invalid={!!errors?.supplier}
                              />
                              <i
                                type="button"
                                disabled={!receptionData?.draft}
                                onClick={
                                  receptionData.draft
                                    ? handleSupplierChange
                                    : () => {}
                                }
                                className={`ri-search-line ${styles["search"]}`}
                              ></i>
                            </div>
                            {errors && errors?.supplier ? (
                              <div
                                style={{
                                  color: "#ed5e5e",
                                  maxWidth: "270px",
                                  marginTop: "5px",
                                }}
                              >
                                {errors.supplier}
                              </div>
                            ) : null}
                          </div>
                        ) : null}

                        {/*<img*/}
                        {/*  src="/print.png"*/}
                        {/*  className={styles["print"]}*/}
                        {/*  alt="print"*/}
                        {/*/>*/}
                        <PrintButton id={receptionData.id} number={receptionData.number} documentType={'reception'} />
                      </div>
                    </Row>
                  </CardBody>

                  <CardBody className="p-4">
                    {errors?.error ? (
                      <div style={{ color: "#ed5e5e" }}>
                        <ul>
                          <li>{errors.error}</li>
                        </ul>
                      </div>
                    ) : null}
                    <div
                      className={`table-responsive ${styles["table-responsive"]}`}
                    >
                      <div className={styles.addProduct}>
                        <div className={styles.input_prod}>
                          <i
                            style={
                              !receptionData?.draft
                                ? { backgroundColor: "#eff2f7" }
                                : null
                            }
                            className={`ri-search-line ${styles["add_prod"]}`}
                          ></i>
                          <input
                            type="text"
                            className={`form-control ${styles["input"]}`}
                            id="basiInput"
                            onChange={onChangeValue}
                            value={searchValue}
                            style={{ border: "none" }}
                            disabled={!receptionData?.draft}
                            readOnly={!receptionData?.draft}
                            placeholder="Добавить товар"
                          />
                        </div>

                        <button
                          type="button"
                          className={styles.catalog}
                          // disabled={false}
                          onClick={
                            receptionData.draft ? handleProductChange : () => {}
                          }
                        >
                          Каталог
                        </button>
                      </div>

                      {searchValue ? (
                        filteredProducts.filter(
                          (product) =>
                            product.title
                              .toLowerCase()
                              .includes(searchValue.toLowerCase()) ||
                            product.code
                              .toLowerCase()
                              .includes(searchValue.toLowerCase())
                        ).length > 0 || productsLoading ? (
                          <div ref={resultsRef} className={styles.results}>
                            <table
                              className={`table-nowrap table table-hover ${styles["table_detail_reception"]}`}
                              style={{ width: "100%" }}
                            >
                              <thead>
                                <tr>
                                  <th>Название</th>
                                  <th>Артикул</th>
                                  <th>Фото</th>
                                </tr>
                              </thead>
                              <tbody
                                className={styles.table_modal_product}
                                style={{ verticalAlign: "baseline" }}
                              >
                                {productsLoading ? ( // Show loader only when loading
                                  <Loader />
                                ) : (
                                  filteredProducts
                                    .filter(
                                      (product) =>
                                        product.title
                                          .toLowerCase()
                                          .includes(
                                            searchValue.toLowerCase()
                                          ) ||
                                        product.code
                                          .toLowerCase()
                                          .includes(searchValue.toLowerCase())
                                    )
                                    .slice(0, 5)
                                    .map((product) => (
                                      <tr key={product.id}>
                                        <th
                                          style={{
                                            cursor: "pointer",
                                            color: "#0066ff",
                                          }}
                                          onClick={() =>
                                            handleProductSelect(product)
                                          }
                                        >
                                          <span
                                            style={{
                                              wordWrap: "break-word",
                                              whiteSpace: "normal",
                                              lineHeight: 1,
                                            }}
                                          >
                                            {product.title}
                                          </span>
                                        </th>
                                        <th>{product.code}</th>
                                        <th>
                                          {(product.image || product?.url_image) ? (
                                            <div
                                              style={{
                                                height: "30px",
                                                textAlign: "center",
                                                width: "35px",
                                              }}
                                            >
                                              <img
                                                src={product.image || product?.url_image}
                                                alt=" "
                                                style={{
                                                  width: "auto",
                                                  maxWidth: "100%",
                                                  maxHeight: "100%",
                                                }}
                                              />
                                            </div>
                                          ) : (
                                            <span>---</span>
                                          )}
                                        </th>
                                      </tr>
                                    ))
                                )}
                              </tbody>
                            </table>
                          </div>
                        ) : (
                          <div className={styles.results}>
                            Ничего не найдено
                          </div>
                        )
                      ) : null}
                      <div class="table-wrapper" style={{overflowX: 'auto', maxWidth: '100%'}}>
                        <Table
                          className={`invoice-table table-borderless table-nowrap mb-0 mt-4 ${styles["table_detail_reception"]}`}
                        >
                          <thead className="align-middle">
                            <tr>
                              {/* <th scope="col">Картинка</th> */}
                              <th scope="col">{receptionData?.write_off ? "Списание" : "Приемка"} товары:</th>
                              <th scope="col" style={{ width: "120px" }}>
                                <div className="d-flex currency-select input-light align-items-center">
                                {receptionData?.write_off ? "Цена" : "  Себестоимость"}
                                </div>
                              </th>
                              <th scope="col" style={{ width: "120px" }}>
                                {receptionData?.write_off ? "Списано шт." : "Принято шт."}
                              </th>
                              <th scope="col">Сумма</th>
                              {receptionData?.write_off && (
                                <th scope="col" >
                                  Причина списания
                                </th>
                              )}
                              {receptionData?.draft && (
                                <th
                                  scope="col"
                                  className="text-end"
                                  style={{ width: "105px" }}
                                ></th>
                              )}
                            </tr>
                          </thead>
                          <tbody
                            id="newlink"
                            style={{ verticalAlign: "baseline" }}
                          >
                            {receptionData.received_products.map(
                              (rProduct, index) => (
                                <tr id="1" className="product">
                                  <td className="text-start">
                                    <div
                                      className={`form-control border-0 ${styles["input_form"]}`}
                                      name="product_name"
                                    >
                                      <div>
                                        {" "}
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "left",
                                            alignItems: "center",
                                          }}
                                        >
                                          <div
                                            style={{
                                              textAlign: "center",
                                              width: "35px",

                                              marginRight: "20px",
                                              verticalAlign: "middle",
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                            }}
                                          >
                                            {(rProduct?.product_data?.image || rProduct.product_data?.url_image) && (
                                              <div style={{minWidth: '30px'}}>
                                                <img
                                                  src={
                                                    rProduct.product_data.image || rProduct.product_data?.url_image
                                                  }
                                                  alt=" "
                                                  style={{
                                                    width: "auto",
                                                    maxWidth: "100%",
                                                    maxHeight: "35px",
                                                  }}
                                                />
                                              </div>
                                            )}
                                          </div>
                                          <span
                                            style={{
                                              maxWidth: "200px",
                                              minWidth: "170px",
                                              wordWrap: "break-word",
                                              whiteSpace: "normal",
                                              lineHeight: 1,
                                            }}
                                          >
                                            {rProduct?.product_data?.title}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <Input
                                      style={{
                                        border: !receptionData?.draft && 0,
                                        // minWidth: "65px",
                                      }}
                                      className={`form-control product-price py-1 px-1 ${styles["input_form"]}`}
                                      readOnly={!receptionData?.draft}
                                      placeholder="0"
                                      type="text"
                                      value={rProduct?.self_cost | 0}
                                      onChange={(e) =>
                                        handleSelfCostChange(
                                          index,
                                          e.target.value
                                        )
                                      }
                                      invalid={
                                        errors?.received_products
                                          ? errors?.received_products[index]
                                              ?.self_cost
                                          : false
                                      }
                                      maxLength={7}
                                    />
                                    <div className="invalid-feedback">
                                      {errors?.received_products
                                        ? errors?.received_products[index]
                                            ?.self_cost
                                        : null}
                                    </div>
                                  </td>
                                  <td>
                                    <div
                                      className={`input-step ${styles["input-step"]}`}
                                      style={
                                        !receptionData?.draft
                                          ? { border: 0 }
                                          : null
                                      }
                                    >
                                      <button
                                        style={{
                                          display: receptionData.draft
                                            ? "block"
                                            : "none",
                                        }}
                                        type="button"
                                        className="minus"
                                        onClick={() =>
                                          handleQtyChange(
                                            index,
                                            rProduct.quantity - 1
                                          )
                                        }
                                      >
                                        –
                                      </button>
                                      <input
                                        type="number"
                                        // style={{border: 0, outline: 0}}
                                        style={{ width: "3rem" }}
                                        className="product-quantity"
                                        id="product-qty-1"
                                        value={rProduct.quantity}
                                        onChange={(e) =>
                                          handleQtyChange(index, e.target.value)
                                        }
                                        // readOnly
                                      />
                                      <button
                                        style={{
                                          display: receptionData.draft
                                            ? "block"
                                            : "none",
                                        }}
                                        type="button"
                                        className="plus"
                                        onClick={() =>
                                          handleQtyChange(
                                            index,
                                            rProduct.quantity + 1
                                          )
                                        }
                                      >
                                        +
                                      </button>
                                    </div>
                                  </td>
                                  <td
                                    style={{
                                      minWidth: "100px",
                                      maxWidth: "100px",
                                    }}
                                    className="text-end"
                                  >
                                    <div>
                                      <input
                                        type="text"
                                        className={`border-0 product-line-price w-100 ${styles["input_form"]}`}
                                        id="productPrice-1"
                                        placeholder="0.00 ₸"
                                        value={
                                          rProduct.final_self_cost !== null
                                            ? rProduct.final_self_cost + " ₸"
                                            : 0
                                        }
                                        readOnly
                                      />
                                    </div>
                                  </td>

                                  {receptionData.write_off && (
                                    <td>
                                      <Input
                                        type="text"
                                        className={`form-control product-price py-1 px-1 ${styles["input_form"]}`}
                                        value={rProduct.cause || ""}
                                        onChange={(e) =>
                                          handleCauseChange(index, e.target.value)
                                        }
                                        disabled={!receptionData.draft}
                                        readOnly={!receptionData.draft}
                                        onBlur={() => handleUpdateReceptionData()}
                                      />
                                    </td>
                                  )}

                                  {receptionData.draft && (
                                    <td style={{ position: "relative", display: "flex", justifyContent: "center" }}>
                                      <div
                                        variant="outlined"
                                        color="secondary"
                                        onClick={() => handleDeleteProduct(index)}
                                        className=" btn-soft-danger btn btn-secondary"
                                        style={{
                                          padding: "4px 10px",


                                          // display: receptionData.draft
                                          //   ? "block"
                                          //   : "none",
                                        }}
                                      >
                                        <i className="ri-delete-bin-2-line"></i>
                                      </div>
                                    </td>
                                  )}
                                </tr>
                              )
                            )}
                          </tbody>
                        </Table>
                      </div>
                      <div className={styles.total_block}>
                        <div className={styles.total}>
                          <h3 style={{ color: "black", marginRight: "10px" }}>
                            Итого:
                          </h3>
                          <span
                            style={{ fontSize: "20px" }}
                            type="text"
                            className={`fs-3 text border-0 ${styles["input_form"]}`}
                            id="productPrice-1"
                            placeholder="0.00 ₸"
                            // value={rate * count + " ₸"}
                            readOnly
                          >
                            {receptionData?.final_self_cost
                              ? receptionData.final_self_cost + " ₸"
                              : 0 + " ₸"}
                          </span>
                        </div>
                      </div>
                      <div className={styles.submit_block}>
                        <button
                          className="btn btn-success"
                          // type="submit"
                          onClick={handleSubmit}
                          style={{
                            display: receptionData.draft ? "block" : "none",
                          }}
                        >
                          {receptionData.write_off
                            ? "Оформить списание"
                            : "Загрузить приёмку"}
                        </button>
                      </div>
                    </div>
                  </CardBody>

                  <CardBody className="p-4">
                    <div className={styles.comment_block}>
                      <div className={styles.comment_title}>
                        <img src="/comment.png" alt="comment" />
                        <h5 style={{ marginLeft: "10px" }}>Комментарий</h5>
                      </div>
                      <div className={styles.comment}>
                        <Col xxl={3} md={6} style={{ width: "100%" }}>
                          <textarea
                            disabled={!receptionData?.draft}
                            readOnly={!receptionData?.draft}
                            className="form-control mt-2"
                            id="exampleFormControlTextarea5"
                            value={receptionData.comment || ""}
                            onChange={(e) =>
                              handleCommentChange(e.target.value)
                            }
                            onBlur={() => handleUpdateReceptionData()}
                            // onInput={(e) => handleCommentChange(e.target.value)}
                            rows="3"
                            style={{ width: "100%", fontSize: "16px" }}
                          ></textarea>
                        </Col>
                      </div>
                    </div>
                  </CardBody>
                </Form>
              </Card>
            </Col>
          </Row>
        ) : (
          <></>
        )}
        {isModalOpen && (
          <Modal
            className={styles.modal_product}
            isOpen={handleProductChange}
            toggle={handleProductClose}
            style={
              {
                "--vz-modal-width": "unset",
              }
            }
          >
            <ModalHeader toggle={handleProductClose}>
              Выберите товары
            </ModalHeader>
            <ModalBody>
              <div
                className={styles.header_modal}
                style={{ marginBottom: "15px" }}
              >
                <Button
                  onClick={handleAddProductChange}
                  color="success"
                  className="add-btn"
                  id="create-btn"
                >
                  <i className="ri-add-line align-bottom me-1"></i> Создать
                  товар
                </Button>
                <div className={styles.search_prod}>
                  <i className={`ri-search-line ${styles["add_prod"]}`}></i>
                  <input
                    style={{ border: "none" }}
                    placeholder="Поиск по товарам..."
                    type="text"
                    onChange={onChangeModalValue}
                    value={searchModalValue}
                  />
                </div>
              </div>
              <div>
                <div style={{ overflowY: "auto", height: "47vh" }}>
                  {productsLoading ? ( // Show loader only when loading
                    <Loader />
                  ) : filteredProducts.length > 0 ? (
                    filteredProducts.filter(
                      (product) =>
                        product.title
                          .toLowerCase()
                          .includes(searchModalValue.toLowerCase()) ||
                        product.code
                          .toLowerCase()
                          .includes(searchModalValue.toLowerCase())
                    ).length > 0 ? (
                      <table className={styles.table_modal}>
                        <thead>
                          <tr className="p-1">
                            <th className="p-1">
                              <input
                                  type="checkbox"
                                  id="checkBoxAll"
                                  className="form-check-input"
                                  onClick={() => checkedAll()}
                              />
                            </th>
                            <th className="p-1">Название</th>
                            <th className="p-1">Артикул</th>
                            <th className="p-1">Фото</th>
                          </tr>
                        </thead>
                        <tbody className={styles.table_modal_product}>
                          {filteredProducts
                            .filter(
                              (product) =>
                                product.title
                                  .toLowerCase()
                                  .includes(searchModalValue.toLowerCase()) ||
                                product.code
                                  .toLowerCase()
                                  .includes(searchModalValue.toLowerCase())
                            )
                            .map((product) => (
                              <tr
                                className={styles.table_modal_tr}
                                key={product.id}
                              >
                                <th
                                    className="p-1"
                                    // onClick={() => handleProductSelect(product)}
                                >
                                  <input
                                      type="checkbox"
                                      className="contactCheckBox form-check-input"
                                      value={product.id}
                                      onChange={() => deleteCheckbox()}
                                  />
                                </th>
                                <th
                                  className="p-1"
                                  style={{
                                    cursor: "pointer",
                                    color: "#0066ff",
                                    wordBreak: 'break-word',
                                    minWidth: '150px'
                                  }}
                                  onClick={() => handleProductSelect(product)}
                                >
                                  {product.title}
                                </th>
                                <th
                                    className="p-1"
                                    style={{
                                      wordBreak: 'break-word',
                                      minWidth: '100px'
                                    }}
                                >
                                  {product.code}
                                </th>
                                <th className="p-1">
                                  {(product.image || product?.url_image) ? (
                                    <div
                                      style={{
                                        height: "30px",
                                        textAlign: "center",
                                        width: "35px",
                                      }}
                                    >
                                      <img
                                        src={product.image || product?.url_image}
                                        alt=" "
                                        style={{
                                          width: "auto",
                                          maxWidth: "100%",
                                          maxHeight: "100%",
                                        }}
                                      />
                                    </div>
                                  ) : (
                                    <span>---</span>
                                  )}
                                </th>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    ) : (
                      <h5 className="mt-5 mb-3 text-center">
                        Ничего не найдено
                      </h5>
                    )
                  ) : (
                    <h5 className="mt-5 mb-3 text-center">Товаров нет</h5>
                  )}
                </div>
              </div>
            </ModalBody>
            {isMultiDeleteButton &&
                <ModalFooter>
                  <div className="d-flex w-100 justify-content-end">
                    <Button
                        // onClick={() => setAddModalMulti(true)}
                        onClick={() => {
                            addMultiple();
                            setAddModalMulti(false);
                            setIsModalOpen(false);
                        }}
                        color="success"
                        className="add-btn btn-sm"
                        id="create-btn"
                    >
                      Добавить
                    </Button>
                  </div>
                </ModalFooter>
            }
          </Modal>
        )}

        {isModalSupplierOpen && (
          <Modal
            className={styles.modal_product}
            isOpen={handleSupplierChange}
            toggle={handleSupplierClose}
          >
            <ModalHeader toggle={handleSupplierClose}>
              Выберите поставщика
            </ModalHeader>

            <ModalBody>
              <Button
                onClick={handleAddSupplier}
                color="success"
                className="add-btn"
                id="create-btn"
              >
                <i className="ri-add-line align-bottom me-1"></i> Добавить
                поставщика
              </Button>
              <div
                style={{ overflowY: "auto", height: "47vh", marginTop: "15px" }}
              >
                {supplier && supplier.length > 0 ? (
                  <table className={styles.table_supplier}>
                    <thead>
                      <tr>
                        <th>Имя</th>
                      </tr>
                    </thead>
                    <tbody>
                      {supplier.map((supplier) => (
                        <tr key={supplier.id}>
                          <th onClick={() => handleSupplierClick(supplier)}>
                            {supplier.name}
                          </th>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <h5
                    style={{ lineHeight: "1.6", color: "grey" }}
                    className="text-center mt-3"
                  >
                    Поставщиков не найдено
                  </h5>
                )}
              </div>
            </ModalBody>
          </Modal>
        )}
        {isModalProductsOpen && (
          <Modal
            className={styles.modal_product}
            isOpen={isModalOpen}
            toggle={handleCloseAddProduct}
          >
            <form
              // onSubmit={handleSubmitProduct}
              onSubmit={(e) => {
                e.preventDefault();
                validationAddProduct.handleSubmit();
                return false;
              }}
            >
              <ModalHeader toggle={handleCloseAddProduct}>
                Создать товар
              </ModalHeader>
              <ModalBody>
                <div className="text-center">
                  <div className="position-relative d-inline-block">
                    <div className="position-absolute  bottom-0 end-0">
                      <Label htmlFor="customer-image-input" className="mb-0">
                        <div className="avatar-xs cursor-pointer">
                          <div className="avatar-title bg-light border rounded-circle text-muted">
                            <i className="ri-image-fill"></i>
                          </div>
                        </div>
                      </Label>
                      <Input
                        className="form-control d-none"
                        id="customer-image-input"
                        type="file"
                        name="image"
                        accept="image/png, image/gif, image/jpeg, image/webp"
                        onChange={(event) => {
                          const file = event.target.files[0];
                          if (file) {
                            setSelectedFile(file);
                            const imageUrl = URL.createObjectURL(file);
                            setSelectedImage(imageUrl);
                          } else {
                            setSelectedFile(null);
                            setSelectedImage("");
                          }
                          validationAddProduct.handleChange(event);
                        }}
                        onBlur={validationAddProduct.handleBlur}
                        // value={validation.values.image || ""}
                        value={""}
                        invalid={
                          validationAddProduct.touched.image &&
                          validationAddProduct.errors.image
                            ? true
                            : false
                        }
                      />
                    </div>
                    <div className="avatar-lg p-1">
                      <div className="avatar-title bg-light text-primary fs-12 lh-1">
                        {" "}
                        {/*rounded-circle */}
                        {selectedImage ? (
                          <img
                            src={selectedImage}
                            alt="картинка товара"
                            id="customer-img"
                            className="avatar-md object-fit-cover"
                          /> /*rounded-circle*/
                        ) : (
                          "выберите картинку товара"
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.cart_lg}>
                  <Label htmlFor="basicInput" className="form-label">
                    <h6>Наименование товара</h6>
                  </Label>
                  <Input
                    name="title"
                    id="basicInput"
                    type="text"
                    className="form-control"
                    placeholder="Введите название..."
                    validate={{
                      required: { value: true },
                    }}
                    onChange={validationAddProduct.handleChange}
                    onBlur={validationAddProduct.handleBlur}
                    value={validationAddProduct.values.title || ""}
                    invalid={
                      validationAddProduct.touched.title &&
                      validationAddProduct.errors.title
                        ? true
                        : !!errors?.title
                    }
                  />
                  {validationAddProduct.touched.title &&
                  validationAddProduct.errors.title ? (
                    <FormFeedback type="invalid">
                      {validationAddProduct.errors.title}
                    </FormFeedback>
                  ) : errors && errors?.title ? (
                    <FormFeedback type="invalid">
                      <div>{errors.title}</div>
                    </FormFeedback>
                  ) : null}
                </div>
                <div className={styles.cart}>
                  <Label htmlFor="articleInput" className="form-label">
                    <h6>Артикул товара</h6>
                  </Label>

                  <Input
                    name="code"
                    id="articleInput"
                    type="text"
                    className="form-control"
                    placeholder="Введите артикул..."
                    validate={{
                      required: { value: true },
                    }}
                    onChange={validationAddProduct.handleChange}
                    onBlur={validationAddProduct.handleBlur}
                    value={validationAddProduct.values.code || ""}
                    invalid={
                      validationAddProduct.touched.code &&
                      validationAddProduct.errors.code
                        ? true
                        : !!errors?.code
                    }
                  />
                  {validationAddProduct.touched.code &&
                  validationAddProduct.errors.code ? (
                    <FormFeedback type="invalid">
                      {validationAddProduct.errors.code}
                    </FormFeedback>
                  ) : errors && errors?.code ? (
                    <FormFeedback type="invalid">
                      <div>{errors.code}</div>
                    </FormFeedback>
                  ) : null}
                </div>
                <div className={styles.form_el}>
                  <div className={styles.cart}>
                    <Label htmlFor="costInput" className="form-label">
                      <h6>Цена продажи</h6>
                    </Label>

                    <Input
                      name="price"
                      id="costInput"
                      type="number"
                      className="form-control"
                      placeholder="Введите стоимость..."
                      validate={{
                        required: { value: true },
                      }}
                      onChange={validationAddProduct.handleChange}
                      onBlur={validationAddProduct.handleBlur}
                      value={validationAddProduct.values.price || ""}
                      invalid={
                        validationAddProduct.touched.price &&
                        validationAddProduct.errors.price
                          ? true
                          : !!errors?.price
                      }
                    />
                    {validationAddProduct.touched.price &&
                    validationAddProduct.errors.price ? (
                      <FormFeedback type="invalid">
                        {validationAddProduct.errors.price}
                      </FormFeedback>
                    ) : errors && errors?.price ? (
                      <FormFeedback type="invalid">
                        <div>{errors.price}</div>
                      </FormFeedback>
                    ) : null}
                  </div>
                </div>
                <div className={styles.cart_lg}>
                  <Label htmlFor="descriptionInput" className="form-label">
                    <h6>Описание</h6>
                  </Label>

                  <Input
                    name="description"
                    id="description-field"
                    type="textarea"
                    className="form-control"
                    placeholder="Введите описание для товара..."
                    validate={{
                      required: { value: true },
                    }}
                    onChange={validationAddProduct.handleChange}
                    onBlur={validationAddProduct.handleBlur}
                    value={validationAddProduct.values.description || ""}
                    invalid={
                      validationAddProduct.touched.description &&
                      validationAddProduct.errors.description
                        ? true
                        : !!errors?.description
                    }
                  />
                  {validationAddProduct.touched.description &&
                  validationAddProduct.errors.description ? (
                    <FormFeedback type="invalid">
                      {validationAddProduct.errors.description}
                    </FormFeedback>
                  ) : errors && errors?.description ? (
                    <FormFeedback type="invalid">
                      <div>{errors.description}</div>
                    </FormFeedback>
                  ) : null}
                </div>
              </ModalBody>
              <ModalFooter>
                <Button type="submit" color="success">
                  Сохранить товар
                </Button>{" "}
                <Button color="danger" onClick={handleCloseAddProduct}>
                  Закрыть
                </Button>
              </ModalFooter>
            </form>
          </Modal>
        )}
        {isAddSupplier && (
          <Modal isOpen={handleAddSupplier} toggle={handleAddSupplierClose}>
            <ModalHeader toggle={handleAddSupplierClose}>
              Создать поставщика
            </ModalHeader>

            <ModalBody>
              <div className={styles.cart}>
                <Label htmlFor="articleInput" className="form-label">
                  <h6>Введите имя</h6>
                </Label>
                <Input
                  type="text"
                  value={supplierInput}
                  onChange={handleSupplierInputChange}
                  id="nameInput"
                  invalid={!!errors?.name}
                />
                {errors && errors?.name ? (
                  <FormFeedback className={styles.feedback}>
                    <span className="lh-1">{errors.name}</span>
                  </FormFeedback>
                ) : null}
              </div>
            </ModalBody>
            <ModalFooter>
              <Button onClick={addSupplier} type="submit" color="primary">
                Сохранить
              </Button>{" "}
            </ModalFooter>
          </Modal>
        )}
        <ToastContainer closeButton={false} />
      </Container>
    </div>
  );
};

export default DetailsReception;
