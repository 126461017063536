import axios from "axios";
import {api} from "../../config";


export const isAuthenticated = async () => {
    // Check if there's an access token and authUser in sessionStorage
    const access_token = localStorage.getItem('access_token');
    const authUser = sessionStorage.getItem('authUser');
    const refresh_token = localStorage.getItem('refresh_token');

    if (!access_token || !authUser) {
        // Access token or authUser is missing
        if (refresh_token) {
            // Attempt to refresh the tokens using the refresh token
            await axios.post(
                // `${process.env.REACT_APP_API_URL}/auth/token/`,
                `${api.API_URL}/auth/token/`,
                {
                    // client_id: process.env.REACT_APP_CLIENT_PASSWORD_ID,
                    // client_secret: process.env.REACT_APP_CLIENT_PASSWORD_SECRET,
                    client_id: api.CLIENT_PASSWORD_ID,
                    client_secret: api.CLIENT_PASSWORD_SECRET,
                    grant_type: 'refresh_token',
                    refresh_token: localStorage.getItem('refresh_token'),
                },{
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: '',
                    }
                }
            ).then((response) => {
                if (response?.access_token) {
                    // Update the tokens in local storage
                    localStorage.setItem('access_token', response.access_token);
                    localStorage.setItem('refresh_token', response.refresh_token);
                } else {
                    localStorage.removeItem('refresh_token');
                    localStorage.removeItem('access_token');
                    console.error('status not 200 refreshing token');
                }
            }).catch((error)=>{
                localStorage.removeItem('refresh_token');
                localStorage.removeItem('access_token');
                console.error('Error refreshing token');
                return false
            });
        }else{
            return false
        }
    }

    if (authUser) {
        return true;
    }

    // // Fetch user data with the new token
    await axios.get(
        // `${process.env.REACT_APP_API_URL}/api/users/me/`,
        `${api.API_URL}/api/users/me/`,
        {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        }
    ).then((response) => {
        if (response?.email) {
            // const data = await response.data;
            sessionStorage.setItem("authUser", JSON.stringify(response));
            return true;
        } else {
            sessionStorage.removeItem("authUser");
            return false;
        }
    }).catch((error) => {
        sessionStorage.removeItem("authUser");
        return false;
    });
    return false;
};
