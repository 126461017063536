const isProductionEnv = process.env.REACT_APP_IS_PRODUCTION_ENV === 'true';

module.exports = {
  google: {
    API_KEY: process.env.REACT_APP_API_KEY,
    CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
    SECRET: process.env.REACT_APP_SECRET,
  },
  facebook: {
    APP_ID: "",
  },
  api: {
    IS_PRODUCTION_ENV: isProductionEnv,

    API_URL: isProductionEnv
        ? process.env.REACT_APP_API_URL
        : process.env.REACT_APP_LOCAL_API_URL,

    CLIENT_PASSWORD_ID: isProductionEnv
        ? process.env.REACT_APP_CLIENT_PASSWORD_ID
        : process.env.REACT_APP_LOCAL_PASSWORD_ID,

    CLIENT_PASSWORD_SECRET: isProductionEnv
        ? process.env.REACT_APP_CLIENT_PASSWORD_SECRET
        : process.env.REACT_APP_LOCAL_PASSWORD_SECRET,

    // API_URL: "http://207.154.232.92",
  }
};
// import { api } 