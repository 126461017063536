import React, {useState} from "react";
import {v4 as uuidv4} from "uuid";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
// import BreadCrumb from "../../../../Components/Common/BreadCrumb";
// import PreviewCardHeader from "../../../../Components/Common/PreviewCardHeader";
import styles from "./AddProduct.module.css";
import {useFormik} from "formik";
import * as Yup from "yup";
import {createProductApi, createProductApiJson,} from "../../../helpers/backend_helper";
import {toast, ToastContainer} from "react-toastify";
import {useNavigate} from "react-router-dom";
import TagInput from "../../../Components/Common/TagInput";

function AddProduct() {
  const history = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [params, setParams] = useState([
    // {uuid: uuidv4(), name: '', values: []}
    // {uuid: uuidv4(), name: 'размер', values: ['50', '52']}, /*, values_error: 'Добавьте хотябы одно значение'*/
    // {uuid: uuidv4(), name: 'цвет', values: ['красный', 'черный']} /*, name_error: 'Введите название параметра'*/
  ]);
  const [generated, setGenerated] = useState([
    // {uuid: uuidv4(), title: 'Мяч 50 красный Мяч 50 красный Мяч 50 красный Мяч 50 красный Мяч 50 красный Мяч 50 красный Мяч 50 красный Мяч 50 красный Мяч 50 красный Мяч 50 красный', code: '100500'},
    // {uuid: uuidv4(), title: 'Мяч 50 белый', code: '100500100500100500100500100500'},
    // {uuid: uuidv4(), title: 'Мяч 50 красный', code: '100500'},
    // {uuid: uuidv4(), title: 'Мяч 50 белый', code: '100500'},
  ]);

  const maxSize = 1048576; // 1 MB in bytes
  const [imageValidationError, setImageValidationError] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  /*function handleAcceptedFiles(files) {
    if (files.length === 0) {
      setImageValidationError("Размер фото превышает 1 MB");
    } else {
      setImageValidationError(null);
    }
    try {
      const file = files[0];
      // files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      });
      // );
      setSelectedFile(file);
    } catch (e) {
      setSelectedFile(null);
    }
  }*/

  function handleImageChange(event) {
    const file = event.target.files[0];
    if (!file) {
      // setImageValidationError("Файл не выбран");
      setSelectedFile(null);
      return;
    }

    if (file.size > maxSize) {
      setImageValidationError("Размер фото превышает 1 MB");
      return;
    }

    setImageValidationError(null);

    try {
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      });
      setSelectedFile(file);
    } catch (error) {
      setSelectedFile(null);
    }
  }

  const handleProductChange = () => {
    setIsModalOpen(true);
  };

  const handleProductClose = () => {
    setIsModalOpen(false);
  };

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 B";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  // ### Params ###
  const handleParamNameChange = (index, newName) => {
    setParams((prevParams) => {
      const updatedParams = [...prevParams];
      updatedParams[index] = { ...updatedParams[index], name: newName };
      delete updatedParams[index].name_error;
      return updatedParams;
    });
  };
  const handleTagChange = (index, tags) => {
    setParams((prevParams) => {
      const updatedParams = [...prevParams];
      updatedParams[index] = { ...updatedParams[index], values: tags };
      delete updatedParams[index].values_error;
      return updatedParams;
    });
  };
  const handleAddParam = () => {
    setParams((prevParams) => {
      return [...prevParams, { uuid: uuidv4(), name: "", values: [] }];
    });
    // toast.success("Параметр успешно добавлен.");
  };
  const handleDeleteParam = (paramUuid) => {
    setParams((prevParams) => {
      return prevParams.filter((param) => param.uuid !== paramUuid);
    });
    // setParams((prevParams) => {
    //   const updatedParams = [...prevParams];
    //   updatedParams.splice(paramIndex, 1);
    //   return updatedParams;
    // });
    // toast.success("Параметр успешно удалён.");
  };

  // ### Generated ###
  const handleTitleChange = (index, value) => {
    const updatedItems = [...generated];
    updatedItems[index].title = value;
    setGenerated(updatedItems);
  };
  const handleCodeChange = (index, value) => {
    const updatedItems = [...generated];
    updatedItems[index].code = value;
    setGenerated(updatedItems);
  };
  const handleAddGenerated = () => {
    setGenerated((prevGenerated) => {
      return [...prevGenerated, { uuid: uuidv4(), title: "", code: "" }];
    });
    // toast.success("Параметр успешно добавлен.");
  };
  const handleDeleteGenerated = (generatedUuid) => {
    setGenerated((prevGenerated) => {
      return prevGenerated.filter((item) => item.uuid !== generatedUuid);
    });
    // toast.success("Параметр успешно удалён.");
  };

  function cartesianProduct(arrays) {
    return arrays.reduce((acc, currentArray) => {
      // Если аккумулятор пуст, возвращаем текущий массив
      if (acc.length === 0) {
        return currentArray.map((item) => [item]);
      }
      // Используем flatMap для создания комбинаций
      return acc.flatMap((accItem) =>
        currentArray.map((currentItem) => accItem.concat(currentItem))
      );
    }, []);
  }

  /*const handleCreateProducts = async () => {
    // const generatedProductsFormDataArray = generated.map((product) => {
    //   const formData = new FormData();
    //   formData.append("code", product.code);
    //   formData.append("title", product.title);
    //   // old
    //   if (selectedFile) { formData.append("image", selectedFile); }
    //   formData.append("description", validation.values.description);
    //   formData.append("price", validation.values.price);
    //
    //   return formData;
    // });
    // await createProduct(generatedProductsFormDataArray);

    const generatedProducts = generated.map((product) => {
      const data = {};
      data.code = product.code;
      data.title = product.title;
      data.description = validation.values.description;
      data.price = validation.values.price;
      // formData.append("code", product.code);
      // formData.append("title", product.title);
      // old
      // if (selectedFile) { formData.append("image", selectedFile); }
      // formData.append("description", validation.values.description);
      // formData.append("price", validation.values.price);
      return data;
    });
    await createProduct(generatedProducts);
  };*/

  const handleCreateProducts = async () => {
    const formData = new FormData();
    // formData.append("shared_image", sharedImageFile);
    if (selectedFile) { formData.append("shared_image", selectedFile); }
    formData.append("description", validation.values.description);
    formData.append("price", validation.values.price);

    const products = generated.map((product) => {
      return {
        code: product.code,
        title: product.title
        // Add other fields here if needed
      };
    });
    formData.append("products", JSON.stringify(products)); // Append product data as JSON string

    await createProduct(formData);
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      image: "",
      title: "",
      code: "",
      description: "",
      price: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Пожалуйста введите название Товара"),
      code: Yup.string().required("Пожалуйста введите Артикул"),
      price: Yup.string().required("Пожалуйста введите Цену продажи"),
    }),
    validateOnChange: true,
    validate: (values) => {
      const errors = {};
      if (values.price === 0) {
        values.price = "";
      } else if (values.price < 0) {
        errors.price = "Цена продажи не может быть отрицательной";
      } else if (values.price > 9999999) {
        errors.price = "Цена продажи не может быть больше 9999999";
      }
      // Validate Code
      if (values.code && !/^[a-zA-Z0-9_]+$/.test(values.code)) {
        errors.code =
          "Артикул должен содержать только английские буквы, цифры и символ \"_\"";
      }
      return errors;
    },
    onSubmit: async (values) => {
      setErrors({});
      setImageValidationError(null);

      let hasErrorsParams = false;
      const updatedParams = params.map((param) => {
        // Создаем копию объекта без ключа name_error
        let { name_error, values_error, ...rest } = param;
        if (!rest.name) {
          hasErrorsParams = true;
          rest.name_error = "Введите название параметра";
        }
        if (rest.values.length < 1) {
          hasErrorsParams = true;
          rest.values_error = "Добавьте хотябы одно значение";
        }
        return rest;
      });

      // Обновляем состояние с новым массивом объектов
      await setParams(updatedParams);

      if (params.length > 0) {
        if (!hasErrorsParams) {
          const valuesArray = params.map(
            (param) => param.values
          ); /* [["красный", "черный"], ["50", "52"]] */
          const generatedProducts = cartesianProduct(valuesArray).map(
            (paramValues) => {
              return createGeneratedObject(values, paramValues);
            }
          );
          setGenerated(generatedProducts);
          handleProductChange();
        }
      } else {
        const newProductData = prepareFormData(values);
        await createProduct(newProductData);
      }
      if (!errors) {
        validation.resetForm();
      }
    },
  });

  const createGeneratedObject = (values, paramValues) => {
    const uuid = uuidv4();
    const title = [values.title, ...paramValues].join(" "); // объединяем значения параметров с названием
    const code = values.code;

    return { uuid, title, code };
  };

  const prepareFormData = (values) => {
    const newProductData = new FormData();

    if (selectedFile) {
      newProductData.append("image", selectedFile);
    }
    newProductData.append("code", values.code);
    newProductData.append("description", values.description);
    newProductData.append("price", values.price);
    newProductData.append("title", values.title);

    return newProductData;
  };

  const createProduct = async (newProductData) => {
    try {
      const apiMethod = Array.isArray(newProductData)
        ? createProductApiJson
        : createProductApi;

      await apiMethod(newProductData);

      history("/products", {
        state: {
          message: Array.isArray(newProductData)
            ? "Все товары были созданы успешно"
            : "Товар был создан успешно",
        },
      });
    } catch (error) {
      if (error?.response?.data?.errors && error?.response?.status === 400) {
        toast.error("Исправьте ошибки", {
          position: "top-right",
        });
        // setErrors({ ...errors, ...error.response.data.errors });
        setErrors(error.response.data.errors);
      } else {
        toast.error(
          Array.isArray(newProductData)
            ? "Не удалось создать предложенные варианты товаров"
            : "Не удалось создать товар",
          {
            position: "top-right",
          }
        );
      }
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className={styles.card} style={{ maxWidth: "1300px" }}>
          <h4>Добавить товар</h4>

          <Row className="pb-5">
            <Card className="pt-2">
              <Form
                onKeyDown={handleKeyDown}
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <CardBody className="card-body">
                  <div className={styles.cart_lg}>
                    <Label htmlFor="title-field" className="form-label">
                      <h5>Наименование товара</h5>
                    </Label>
                    {/*<input type="text" className="form-control" id="basiInput1" />*/}
                    <Input
                      name="title"
                      id="title-field"
                      type="text"
                      className="form-control"
                      placeholder="Введите название..."
                      validate={{
                        required: { value: true },
                      }}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.title || ""}
                      invalid={
                        validation.touched.title && validation.errors.title
                          ? true
                          : !!errors?.title
                      }
                    />
                    {validation.touched.title && validation.errors.title ? (
                      <FormFeedback type="invalid">
                        {validation.errors.title}
                      </FormFeedback>
                    ) : errors && errors?.title ? (
                      <FormFeedback type="invalid">
                        <div>{errors.title}</div>
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className={styles.form_el}>
                    <div className={styles.cart}>
                      <Label htmlFor="price-field" className="form-label">
                        <h5>Цена продажи</h5>
                      </Label>
                      <Input
                        name="price"
                        id="price-field"
                        type="number"
                        className="form-control"
                        placeholder="Введите стоимость..."
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.price || ""}
                        invalid={
                          validation.touched.price && validation.errors.price
                            ? true
                            : !!errors?.price
                        }
                      />
                      {validation.touched.price && validation.errors.price ? (
                        <FormFeedback type="invalid">
                          {validation.errors.price}
                        </FormFeedback>
                      ) : errors && errors?.price ? (
                        <FormFeedback type="invalid">
                          <div>{errors.price}</div>
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className={styles.cart}>
                      <Label htmlFor="code-field" className="form-label">
                        <h5>Артикул</h5>
                      </Label>
                      <Input
                        name="code"
                        id="code-field"
                        type="text"
                        className="form-control"
                        placeholder="Введите артикул..."
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.code || ""}
                        invalid={
                          validation.touched.code && validation.errors.code
                            ? true
                            : !!errors?.code
                        }
                      />
                      {validation.touched.code && validation.errors.code ? (
                        <FormFeedback type="invalid">
                          {validation.errors.code}
                        </FormFeedback>
                      ) : errors && errors?.code ? (
                        <FormFeedback type="invalid">
                          <div>{errors.code}</div>
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className={styles.cart}>
                      <Label htmlFor="basiInput4" className="form-label">
                        <h5>Изображение</h5>
                      </Label>
                      {/*// -------------*/}
                      {/*<Dropzone
                        maxFiles={1}
                        maxSize={maxSize}
                        accept={{
                          // "image/*": [".jpeg", ".png", ".webp"],
                          "image/jpeg": [],
                          "image/png": [],
                          "image/webp": [],
                        }}
                        style={{ width: "100%" }}
                        onDrop={(acceptedFiles, rejectedFiles) => {
                          handleAcceptedFiles(acceptedFiles);
                        }}
                        // onFileDialogOpen={()=>{alert(123)}}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className=" dz-clickable ">
                            <div
                              className="dz-message needsclick"
                              {...getRootProps()}
                            >
                              <div
                                className={styles.form}
                                style={
                                  imageValidationError && {
                                    border: "1px solid #ed5e5e",
                                  }
                                }
                              >
                                <img
                                  src="/plus.png"
                                  alt="plus"
                                  style={{ width: "20px" }}
                                />
                                <span
                                  style={{
                                    marginLeft: "5px",
                                    fontSize: "16px",
                                  }}
                                >
                                  Загрузить
                                </span>
                              </div>
                            </div>
                          </div>
                        )}
                      </Dropzone>*/}
                      {/*<Input
                        type="file"
                        name="image"
                        id="image-field"
                        accept="image/*"
                        onChange={handleImageChange}
                        invalid={!!imageValidationError}
                        maxSize={maxSize}
                        // accept={{
                        //   // "image/*": [".jpeg", ".png", ".webp"],
                        //   "image/jpeg": [],
                        //   "image/png": [],
                        //   "image/webp": [],
                        // }}
                     />*/}
                      <Input
                         type="file"
                         name="image"
                         id="image-field"
                         accept="image/jpeg, image/png, image/webp"
                         onChange={handleImageChange}
                         invalid={!!imageValidationError}
                         style={{ display: 'none' }}
                      />
                     <label
                        htmlFor="image-field"
                        className={styles.form}
                        style={
                           !selectedFile && imageValidationError
                               ? { border: "1px solid #ed5e5e", padding: '0.5rem 0.9rem' }
                               : { padding: '0.5rem 0.9rem'}
                        }
                     >
                        <img src="/plus.png" alt="plus" style={{ width: "20px" }} />
                        <span style={{ marginLeft: "5px", fontSize: "16px" }}>
                           Загрузить
                        </span>
                     </label>

                      {imageValidationError && (
                        <p
                          className="mt-2"
                          style={{ color: "#ed5e5e", fontSize: "0.875em" }}
                        >
                          {imageValidationError}
                        </p>
                      )}
                      {
                        selectedFile && (
                          // selectedFile.map((file) => (
                          <span className="mt-2">
                            <img
                              className={styles.file_img}
                              src={selectedFile.preview}
                              alt="Загруженный файл"
                            />
                            <span className="mx-1">
                              {selectedFile.formattedSize}
                            </span>
                          </span>
                        )
                        // ))
                      }
                    </div>
                  </div>
                  <div>
                    <div className={styles.cart_lg}>
                      <Label htmlFor="description-field" className="form-label">
                        <h5>Описание</h5>
                      </Label>
                      <Col xxl={3} md={6} style={{ width: "100%" }}>
                        <Input
                          name="description"
                          id="description-field"
                          type="textarea"
                          rows="3"
                          style={{ fontSize: "16px", maxHeight: "200px" }}
                          className="form-control"
                          placeholder="Введите описание для товара..."
                          validate={{
                            required: { value: true },
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.description || ""}
                          invalid={
                            validation.touched.description &&
                            validation.errors.description
                              ? true
                              : !!errors?.description
                          }
                        />
                        {validation.touched.description &&
                        validation.errors.description ? (
                          <FormFeedback type="invalid">
                            {validation.errors.description}
                          </FormFeedback>
                        ) : errors && errors?.description ? (
                          <FormFeedback type="invalid">
                            <div>{errors.description}</div>
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </div>
                  </div>
                  <div style={{ margin: "25px 0" }}>
                    <h5 style={{ marginBottom: "30px" }}>Варианты товара</h5>
                    <div className={styles.container_params}>
                      {params.map((param, index) => (
                        <div
                          // key={uuidv4()}
                          key={param.uuid}
                          className="d-flex align-items-start flex-wrap mb-4 position-relative"
                        >
                          <div className="position-relative mb-2 mx-4">
                            <h5
                              className="position-absolute"
                              style={{ top: "65%", left: "-23px" }}
                            >
                              {index + 1}.
                            </h5>
                            <label>Название : размер, цвет и др.</label>
                            <input
                              placeholder="Добавить параметр"
                              style={{ width: "200px" }}
                              className={`form-control`}
                              type="text"
                              value={param.name}
                              onChange={(e) =>
                                handleParamNameChange(index, e.target.value)
                              }
                            />
                            {param?.name_error && (
                              <div
                                style={{
                                  color: "#ed5e5e",
                                  fontSize: "0.875em",
                                }}
                              >
                                {param.name_error}
                              </div>
                            )}
                          </div>
                          <div className="mx-4">
                            <label>
                              Значение: черный, красный / размер 38,40, S, M и
                              др.
                            </label>
                            <TagInput
                              tags={param.values}
                              onTagsChange={(tags) =>
                                handleTagChange(index, tags)
                              }
                            />
                            {param?.values_error && (
                              <div
                                style={{
                                  color: "#ed5e5e",
                                  fontSize: "0.875em",
                                }}
                              >
                                {param.values_error}
                              </div>
                            )}
                          </div>
                          <div
                            className="position-absolute"
                            style={{ top: "37px", right: 0 }}
                          >
                            <img
                              style={{ cursor: "pointer" }}
                              onClick={() => handleDeleteParam(param.uuid)}
                              src="/delete-inline.svg"
                              alt="delete"
                            />
                          </div>
                        </div>
                      ))}
                      <button
                        className={styles.add_new_params}
                        type="button"
                        onClick={handleAddParam}
                      >
                        <img
                          style={{ marginRight: "5px", width: "17px" }}
                          src="/plus.png"
                          alt="plus"
                        />
                        Добавить новый параметр
                      </button>
                    </div>
                  </div>
                  {/*<div style={{ margin: "25px 0" }}>*/}
                  {/*  <h5 style={{ marginBottom: "30px" }}>Варианты товара</h5>*/}

                  {/*  <div className={styles.container_params}>*/}
                  {/*    <div className="d-flex align-items-start flex-wrap mb-2 position-relative">*/}
                  {/*      <div className="position-relative mb-2 mx-4">*/}
                  {/*        <h5 className="position-absolute" style={{top: '65%', left: '-23px'}}>1.</h5>*/}
                  {/*        <label>Название : размер, цвет и др.</label>*/}
                  {/*        <input*/}
                  {/*            placeholder="Добавить параметр"*/}
                  {/*            style={{ width: "200px" }}*/}
                  {/*            className={`form-control`}*/}
                  {/*            type="text"*/}
                  {/*        />*/}
                  {/*      </div>*/}
                  {/*      <div className="mx-4">*/}
                  {/*        <label style={{lineHeight: 0.9}}>*/}
                  {/*          Значение: черный, красный / размер 38,40, S, M и др.*/}
                  {/*        </label>*/}
                  {/*        /!*<TagInput externalTags={tags} onTagsChange={handleTagsChange}/>*!/*/}
                  {/*        <TagInput/>*/}
                  {/*      </div>*/}
                  {/*      <div className="position-absolute" style={{top: '37px', right: 0}}>*/}
                  {/*        <img style={{cursor: 'pointer'}} onClick={() => handleDeleteParam(status.id)} src="/delete-inline.svg" alt="delete"/>*/}
                  {/*      </div>*/}
                  {/*    </div>*/}

                  {/*    <button className={styles.add_new_params} type="button">*/}
                  {/*      <img style={{ marginRight: "5px"}} src="/plus.png" alt="plus" />*/}
                  {/*      Добавить новый параметр*/}
                  {/*    </button>*/}
                  {/*  </div>*/}
                  {/*</div>*/}

                  <div className="text-end">
                    <button
                      className="btn btn-primary"
                      type="submit"
                      style={{ fontSize: "15px", padding: "15px" }}
                    >
                      Добавить
                    </button>
                  </div>
                </CardBody>
              </Form>
            </Card>
          </Row>

          {isModalOpen && (
            <Modal
              className={styles.modal_product}
              isOpen={true}
              toggle={handleProductClose}
            >
              <ModalHeader toggle={handleProductClose}>
                Сгенерированные варианты
              </ModalHeader>
              <ModalBody>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                  }}
                >
                  <div
                    style={{
                      flexGrow: 1,
                      overflowY: "auto",
                      height: "47vh",
                      margin: "0 auto",
                      width: "100%",
                    }}
                  >
                    {errors?.error ? (
                      <div style={{ color: "#ed5e5e" }}>
                        <ul>
                          <li>{errors.error}</li>
                        </ul>
                      </div>
                    ) : null}
                    <table className={styles.table_modal}>
                      <thead>
                        <tr>
                          <th>Наименование товара</th>
                          <th>Артикул</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody className={styles.table_modal_product}>
                        {generated &&
                          generated.map((generatedItem, index) => (
                            <tr key={generatedItem.uuid}>
                              <td
                                style={{
                                  display: "flex",
                                  /* width: 70%; */
                                  flexDirection: "column",
                                }}
                              >
                                <Input
                                  type="textarea"
                                  className={`form-control ${styles.smaller_input}`}
                                  value={generatedItem.title}
                                  onChange={(event) =>
                                    handleTitleChange(index, event.target.value)
                                  }
                                  invalid={!!errors[index]?.title}
                                />
                                <div
                                  className="invalid-feedback"
                                  style={{ lineHeight: 0.9, fontSize: "10px" }}
                                >
                                  {errors[index]?.title}
                                </div>
                              </td>
                              <td style={{ width: "30%" }}>
                                <Input
                                  type="textarea"
                                  className={`form-control ${styles.smaller_input}`}
                                  value={generatedItem.code}
                                  onChange={(event) =>
                                    handleCodeChange(index, event.target.value)
                                  }
                                  invalid={!!errors[index]?.code}
                                />
                                <div
                                  className="invalid-feedback"
                                  style={{ lineHeight: 0.9, fontSize: "10px" }}
                                >
                                  {errors[index]?.code}
                                </div>
                              </td>
                              <td>
                                <img
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    handleDeleteGenerated(generatedItem.uuid)
                                  }
                                  src="/delete-inline.svg"
                                  alt="delete"
                                />
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    <button
                      style={{
                        paddingRight: "8px",
                        marginTop: "10px",
                        marginBottom: "80px",
                      }}
                      className={styles.add_new_params}
                      type="button"
                      onClick={handleAddGenerated}
                    >
                      <img
                        style={{ marginRight: "5px", width: "17px" }}
                        src="/plus.png"
                        alt="plus"
                      />
                      Добавить вариант
                    </button>
                  </div>
                  {generated?.length > 0 && (
                    <div className="text-end m-1">
                      <button
                        className="btn btn-success"
                        type="button"
                        onClick={handleCreateProducts}
                      >
                        Загрузить товары
                      </button>
                    </div>
                  )}
                </div>
                {/*<div style={{ overflowY: "auto", height: "47vh", margin: "0 auto" }}>*/}
                {/*  <table className={styles.table_modal}>*/}
                {/*    <thead>*/}
                {/*      <tr>*/}
                {/*        <th>Наименование товара</th>*/}
                {/*        <th>Артикул</th>*/}
                {/*        /!*<th className="p-1">Себестоимость</th>*!/*/}
                {/*        /!*<th className="p-1">Фото</th>*!/*/}
                {/*      </tr>*/}
                {/*    </thead>*/}
                {/*    <tbody className={styles.table_modal_product}>*/}
                {/*      {generated && generated.map((generatedItem, index) => (*/}
                {/*        <tr key={generatedItem.uuid}>*/}
                {/*          <td style={{ width: '70%' }}>*/}
                {/*            <Input*/}
                {/*                type="textarea"*/}
                {/*                className={`form-control ${styles.smaller_input}`}*/}
                {/*                value={generatedItem.title}*/}
                {/*                onChange={(event) => handleTitleChange(index, event.target.value)}*/}
                {/*            />*/}
                {/*          </td>*/}
                {/*          <td style={{ width: '30%' }}>*/}
                {/*            <Input*/}
                {/*                type="textarea"*/}
                {/*                className={`form-control ${styles.smaller_input}`}*/}
                {/*                value={generatedItem.code}*/}
                {/*                onChange={(event) => handleCodeChange(index, event.target.value)}*/}
                {/*            />*/}
                {/*          </td>*/}
                {/*          /!*<td className="p-1"></td>*!/*/}
                {/*          /!*<td className="p-1"></td>*!/*/}
                {/*          <td>*/}
                {/*            <img*/}
                {/*                style={{ cursor: "pointer" }}*/}
                {/*                onClick={() => handleDeleteGenerated(generatedItem.uuid)}*/}
                {/*                src="/delete-inline.svg"*/}
                {/*                alt="delete"*/}
                {/*            />*/}
                {/*            /!*<i className="ri-close-circle-line" style={{color: "red"}}></i>*!/*/}
                {/*          </td>*/}
                {/*        </tr>*/}
                {/*      ))}*/}
                {/*    </tbody>*/}
                {/*  </table>*/}
                {/*  <button*/}
                {/*      style={{paddingRight: '8px', marginTop: '10px', marginBottom: '50px'}}*/}
                {/*      className={styles.add_new_params}*/}
                {/*      type="button"*/}
                {/*      onClick={handleAddGenerated}*/}
                {/*  >*/}
                {/*    <img*/}
                {/*        style={{ marginRight: "5px"}}*/}
                {/*        src="/plus.png"*/}
                {/*        alt="plus"*/}
                {/*    />*/}
                {/*    Добавить вариант*/}
                {/*  </button>*/}
                {/*  {generated?.length > 0 && (*/}
                {/*    <div className="text-end m-1">*/}
                {/*      <button*/}
                {/*          style={{}}*/}
                {/*          className="btn btn-success"*/}
                {/*          type="button"*/}
                {/*          // onClick={handleAddGenerated}*/}
                {/*      >*/}
                {/*        Загрузить товары*/}
                {/*      </button>*/}
                {/*    </div>*/}
                {/*  )}*/}
                {/*  )}*/}
                {/*</div>*/}
              </ModalBody>
            </Modal>
          )}
        </Container>
        <ToastContainer closeButton={false} />
      </div>
    </React.Fragment>
  );
}

export default AddProduct;
