import { APIClient } from "./api_helper";

import * as url from "./url_helper";
import {GET_GENERATE_PRICELIST, GET_INVITATIONS} from "./url_helper";

const api = new APIClient();

// Gets the logged-in user data from local session
export const getLoggedInUser = () => {
    const user = localStorage.getItem("user");
    if (user) return JSON.parse(user);
    return null;
};

// //is user is logged in
export const isUserAuthenticated = () => {
    return getLoggedInUser() !== null;
};


// Edit profile
// export const postJwtProfile = data => api.create(url.POST_EDIT_JWT_PROFILE, data);

// export const postFakeProfile = (data) => api.update(url.POST_EDIT_PROFILE + '/' + data.idx, data);
export const postEditProfile = (data) => api.update(url.POST_EDIT_ME, data);
export const postPasswordChange = (data) => api.create(url.POST_PASSWORD_CHANGE, data);


// FARMS
// export const getFarmsApi = data => api.get(url.GET_FARMS, data);
// export const createFarmApi = data => api.create(url.GET_FARMS, data);
// export const updateFarmApi = data => api.update(url.GET_FARMS + data.id + '/', data);
// export const deleteFarmApi = data => api.delete(url.GET_FARMS + data.id + '/', data);

// ACCOUNTS
// export const getAccountsApi = data => api.get(url.GET_ACCOUNTS, data);
// export const getFarmAccountsApi = data => api.get(url.GET_FARM_ACCOUNTS + data.farmId + '/accounts', data);
// export const getAccountDetailApi = data => api.get(url.GET_ACCOUNTS + data.id + '/', data);
// export const createAccountApi = data => api.create(url.GET_ACCOUNTS, data);
// export const createFarmAccountApi = data => api.create(url.GET_FARM_ACCOUNTS + data.farmId + '/accounts/', data);
// export const updateAccountApi = data => api.update(url.GET_ACCOUNTS + data.id + '/', data);
// export const deleteAccountApi = data => api.delete(url.GET_ACCOUNTS + data.id + '/', data);


// Login Method
export const postJwtLogin = data => api.create(url.POST_JWT_LOGIN, data);
export const getMe = data => api.get(url.GET_ME, data);

// Register
export const postJwtRegisterCustom = data => api.create(url.POST_JWT_REGISTER, data);
export const postJwtConfirmEmail = data => api.create(url.POST_JWT_CONFIRM_EMAIL, data);

// Supplier
export const getSuppliersApi = data => api.get(url.GET_SUPPLIER, data);
export const addSupplierApi = data => api.create(url.GET_SUPPLIER, data);

// Customer
export const getCustomersApi = data => api.get(url.GET_CUSTOMER, data);
export const addCustomerApi = data => api.create(url.GET_CUSTOMER, data);

// Storages
export const getStorage = data => api.get(url.GET_STORAGE + data.id, data);
export const getStoragesApi = data => api.get(url.GET_STORAGE + '?o=id', data);
export const createStorageApi = data => api.create(url.GET_STORAGE, data);
export const updateStorageApi = data => api.update(url.GET_STORAGE + data.id + '/', data);
export const deleteStorageApi = data => api.delete(url.GET_STORAGE + data.id + '/', data);

// Statuses
export const getStatusesApi = data => api.get(url.GET_STATUS, data);
export const addStatusApi = data => api.create(url.GET_STATUS, data);
export const updateStatusApi = data => api.update(url.GET_STATUS + data.id + '/', data);
export const deleteStatusApi = data => api.delete(url.GET_STATUS + data.id + '/', data);

// SalesChannels
export const getSalesChannelsApi = data => api.get(url.GET_SALES_CHANNEL, data);
export const createSalesChannelApi = data => api.create(url.GET_SALES_CHANNEL, data);
export const deleteSalesChannelApi = data => api.delete(url.GET_SALES_CHANNEL + data.id + '/', data);

// Remains
export const getRemainsApi = data => api.get(url.GET_REMAINS, data);
export const createRemainsApi = data => api.create(url.GET_REMAINS, data);
export const updateRemainsApi = data => api.update(url.GET_REMAINS + data.id + '/', data);
export const deleteRemainsApi = data => api.delete(url.GET_REMAINS + data.id + '/', data);

// Products
export const getProductsApi = data => api.get(url.GET_PRODUCT, data);
export const createProductApi = data => api.post(url.GET_PRODUCT, data);
export const createProductApiJson = data => api.create(url.GET_PRODUCT, data);
export const updateProductApi = (productId, data) => api.update(url.GET_PRODUCT + productId + '/', data);
export const deleteProductApi = data => api.delete(url.GET_PRODUCT + data.id + '/', data);
export const importProducts = data => api.post(url.POST_IMPORT_PRODUCTS, data);

// Reception
export const getReceptionsDataApi = data => api.get(url.GET_RECEPTION, data);
export const getReceptionDetailApi = data => api.get(url.GET_RECEPTION + data.id + '/', data);
export const createReceptionDraft = data => api.create(url.GET_RECEPTION, data);
export const updateReceptionApi = (receptionId, data) => api.update(url.GET_RECEPTION + receptionId + '/', data);
export const deleteReceptionApi = data => api.delete(url.GET_RECEPTION + data.id + '/', data);

// Order
export const getOrdersDataApi = data => api.get(url.GET_ORDER, data);
export const getOrderDetailApi = data => api.get(url.GET_ORDER + data.id + '/', data);
export const createOrder = data => api.create(url.GET_ORDER, data);
export const updateOrderApi = (receptionId, data) => api.update(url.GET_ORDER + receptionId + '/', data);
export const deleteOrderApi = data => api.delete(url.GET_ORDER + data.id + '/', data);
export const getCalculateProfit = data => api.get(url.GET_CALCULATE_PROFIT, data);
export const getCalculateTurnover = data => api.get(url.GET_CALCULATE_TURNOVER, data);

// Expenses
export const getExpenseCategoriesApi = data => api.get(url.GET_EXPENSE_CATEGORIES, data);
export const createExpenseCategoriesApi = data => api.create(url.GET_EXPENSE_CATEGORIES, data);
export const updateExpenseCategoriesApi = data => api.update(url.GET_EXPENSE_CATEGORIES + data.id + '/', data);
export const deleteExpenseCategoriesApi = data => api.delete(url.GET_EXPENSE_CATEGORIES + data.id + '/', data);
export const getExpensesApi = data => api.get(url.GET_EXPENSES, data);
export const createExpensesApi = data => api.create(url.GET_EXPENSES, data);
export const deleteExpensesApi = data => api.delete(url.GET_EXPENSES + data.id + '/', data);

// Integration
export const getIntegrationApi = data => api.get(url.GET_INTEGRATION, data);
export const updateIntegrationApi = data => api.update(url.GET_INTEGRATION, data);
export const generatePricelistApi = () => api.get(url.GET_GENERATE_PRICELIST, {});
// ExternalBot
export const getBotIntegrationApi = data => api.get(url.GET_BOT_INTEGRATION, data);
export const updateBotIntegrationApi = data => api.update(url.GET_BOT_INTEGRATION, data);
export const getBotPricelistApi = data => api.get(url.GET_BOT_PRICELIST, data);
export const updateBotPricelistApi = data => api.put(url.GET_BOT_PRICELIST, data);
export const deleteFromBotPricelistApi = data => api.deleteWithData(url.GET_BOT_PRICELIST, data);
export const getBotSettingsApi = data => api.get(url.BOT_SETTINGS, data);
export const updateBotSettingsApi = data => api.create(url.BOT_SETTINGS, data);
export const massPriceChangeApi = data => api.create(url.MASS_PRICE_CHANGE, data);
// export const generatePricelistApi = () => api.get(url.GET_GENERATE_PRICELIST, {});

// Invites
export const getInvitationsApi = data => api.get(url.GET_INVITATIONS, data);
export const getAccountsApi = data => api.get(url.GET_ACCOUNTS, data);
export const getChangeAccount = data => api.get(url.GET_CHANGE_ACCOUNT, data);
export const getAcceptInvitation = (invitationId, data) => api.get(url.GET_INVITATIONS + invitationId + '/accept/', data);
export const postCreateInvite = data => api.create(url.GET_INVITATIONS, data);
export const updateInvite = data => api.update(url.GET_INVITATIONS + data.id + '/', data);

/// Processes ///
export const updateFromKaspiApi = data => api.get(url.UPDATE_FROM_KASPI, data);
export const progressUpdateFromKaspiAPI = data => api.get(url.UPDATE_FROM_KASPI + 'get_progress/', data);

// postForgetPwd
// export const postJwtForgetPwd = data => api.create(url.POST_FAKE_JWT_PASSWORD_FORGET, data);

// postSocialLogin
// export const postSocialLogin = data => api.create(url.SOCIAL_LOGIN, data);

//API Key
// export const getAPIKey = () => api.get(url.GET_API_KEY);