import React, { useState } from 'react';
import axios from 'axios';
import { toast } from "react-toastify";
import { Modal, ModalBody, ModalFooter, Button, FormGroup, Label, Input } from 'reactstrap';
import './Prints.css';
import styles from "../Invoices/InvoiceCreate.module.css";
import {getLoggedinUser} from "../../helpers/api_helper";

const PrintButton = ({ id, number, documentType, columns, button, listId, json, filters }) => {
    const user = getLoggedinUser();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [printOption, setPrintOption] = useState('download');
    const [warrantyDescription, setWarrantyDescription] = useState(user?.organization?.warranty || '');
    const [selectedColumns, setSelectedColumns] = useState([]);

    const handleColumnChange = (column) => {
        setSelectedColumns(prevSelectedColumns =>
            prevSelectedColumns.includes(column)
                ? prevSelectedColumns.filter(col => col !== column)
                : [...prevSelectedColumns, column]
        );
    };

    const handlePrint = async () => {
        setIsModalOpen(false);
        try {
            let url = id ? `/api/core/generate_pdf/${id}/` : '/api/core/generate_pdf/';
            if (['profit', 'turnover'].includes(documentType)) {
                url = '/api/core/generate_analytics_pdf/'
            }
            const data = {
                print_option: printOption,
                document_type: documentType,
                json: json,
                filters: filters
            }
            if (documentType === 'order') {
                data['warranty_description'] = warrantyDescription;
            }
            if (columns) {
                data['columns'] = selectedColumns;
            }
            if (listId) {
                data['list_id'] = listId;
            }
            const pdf = await axios.post(url, data, {
                responseType: 'blob',
            });

            const objectUrl = window.URL.createObjectURL(new Blob([pdf], { type: 'application/pdf' }));

            if (printOption === 'open') {
                window.open(objectUrl);
            } else if (printOption === 'download') {
                const link = document.createElement('a');
                link.href = objectUrl;
                let name = `warranty_${number}.pdf`;
                if (!number) {
                    const formattedDate = new Date().toLocaleDateString('en-GB', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                    });
                    name = `${formattedDate}.pdf`
                }
                link.setAttribute('download', name);
                document.body.appendChild(link);
                link.click();
                link.remove();
            }
            window.URL.revokeObjectURL(objectUrl);
        } catch (error) {
            toast.error("Не удалось загрузить PDF-файл", {
                position: "top-right",
            });
            console.error('Ошибка при генерации PDF:', error);
        }
    };

    return (
        <>
            {button ?
                React.cloneElement(button, {
                    onClick: () => setIsModalOpen(true)
                }) :
                <img
                    style={{ marginBottom: '20px' }}
                    onClick={() => setIsModalOpen(true)}
                    src="/print.png"
                    className={styles["print"]}
                    alt="print"
                />
            }

            <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(false)} centered>
                <ModalBody>
                    <h2>Настройка печати</h2>
                    <FormGroup>
                        <Label for="printOption">Выберите опцию:</Label>
                        <Input
                            type="select"
                            id="printOption"
                            value={printOption}
                            onChange={(e) => setPrintOption(e.target.value)}
                        >
                            <option value="download">Скачать файл</option>
                            <option value="open">Открыть в браузере</option>
                        </Input>
                    </FormGroup>
                    {columns && (
                        <FormGroup>
                            <Label>Выберите колонки для печати:</Label>
                            {columns.map((column) => (
                                <FormGroup check key={column}>
                                    <Label check>
                                        <Input
                                            type="checkbox"
                                            checked={selectedColumns.includes(column)}
                                            onChange={() => handleColumnChange(column)}
                                        />
                                        {` ${column}`}
                                    </Label>
                                </FormGroup>
                            ))}
                        </FormGroup>
                    )}
                    {documentType === 'order' &&
                        <FormGroup>
                            <Label for="additionalText">Описание гарантии:</Label>
                            <Input
                                type="textarea"
                                rows="7"
                                id="additionalText"
                                value={warrantyDescription}
                                onChange={(e) => setWarrantyDescription(e.target.value)}
                            />
                        </FormGroup>
                    }
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handlePrint}>Применить</Button>
                    <Button color="secondary" onClick={() => setIsModalOpen(false)}>Закрыть</Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

// PrintButton.propTypes = {
//     id: PropTypes.number.isRequired,
//     number: PropTypes.string.isRequired,
//     columns: PropTypes.arrayOf(PropTypes.string).isRequired,
// };

export default PrintButton;
