import React, {useEffect, useState} from 'react';
// import style from './Auth.module.css'
import {Link, useNavigate} from "react-router-dom";
import withRouter from "../../Components/Common/withRouter";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import {
    Alert,
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormFeedback,
    Input,
    Label,
    Row,
    Spinner
} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";

import {useFormik} from "formik";
import * as Yup from "yup";
import {loginUser} from "../../slices/auth/login/utils";
import {isAuthenticated} from "../../Routes/Auth/isAuthenticated";
import GoogleWrapper from "../../Components/Social/Google/GoogleWrapper";


const Login = (props) => {
    const dispatch = useDispatch();
    const selectLayoutState = (state) => state.Account;
    const selectLayoutProperties = createSelector(
        selectLayoutState,
        (layout) => ({
            // user: layout.user,
            errorMsg: layout.errorMsg,
            loading:layout.loading,
            // error: layout.error,
        })
    );
    // Inside your component
    const {
        // user,
        errorMsg,
        loading,
        // error
    } = useSelector(selectLayoutProperties);

    document.title = "CRMBEK: Вход";

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);

    const setErrorState = (errorMessage) => {
        setError(errorMessage);
    };

    // const [userLogin, setUserLogin] = useState([]);
    const [passwordShow, setPasswordShow] = useState(false);

    const navigate = useNavigate(); // Get the navigate function
    // const domain = process.env.REACT_APP_API_URL;
    // const apiKey = process.env.REACT_APP_API_KEY;
    // const client_id = process.env.REACT_APP_CLIENT_PASSWORD_ID;
    // const client_secret = process.env.REACT_APP_CLIENT_PASSWORD_SECRET;
    // const grant_type = 'password';

    useEffect(() => {
        // changeHTMLAttribute("data-bs-theme", 'dark');
        const checkAuth = async () => {
            const authenticated = await isAuthenticated();
            if (authenticated) {
                navigate('/'); // Redirect to the dashboard if the user is authenticated
            }
        };
        checkAuth();
    }, [navigate]);


    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            username: username || '', /*"admin@themesbrand.com" ||*/
            password: password || '', /*|| "123456" */
        },
        validationSchema: Yup.object({
            username: Yup.string().required("Обязательное поле"),
            password: Yup.string().required("Обязательное поле"),
        }),
        onSubmit: (values) => {
            dispatch(loginUser(values, props.router.navigate, setErrorState));
        }
    });

    // useEffect(() => {
    //     if (errorMsg) {
    //         setTimeout(() => {
    //             dispatch(resetLoginFlag());
    //         }, 3000);
    //     }
    // }, [dispatch, errorMsg]);

    return(
        <React.Fragment>
            <ParticlesAuth>
                <div className="auth-page-content">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center mt-sm-5 mb-4 text-white-50">
                                    <div>
                                        {/*<Link to="/login" className="d-inline-block auth-logo">*/}
                                        <h1>CRMBEK</h1>
                                            {/*<img src={logoLight} alt="" height="20" />*/}
                                        {/*</Link>*/}
                                    </div>
                                    <p className="mt-3 fs-15 fw-medium" style={{color: 'grey'}}>Система для вашего бизнеса</p>
                                </div>
                            </Col>
                        </Row>

                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="mt-4">
                                    <CardBody className="p-4">
                                        <div className="text-center mt-2">
                                            <h5 className="text-primary">Добро пожаловать!</h5>
                                            <p className="text-muted">Войдите, чтобы продолжить.</p>
                                        </div>
                                        {error && error ? (<Alert color="danger"> {error} </Alert>) : null}
                                        {/*{<Alert color="danger"> {'Error occurred in backend'} </Alert>}*/}
                                        <div className="p-2 mt-4">
                                            <Form
                                                // onSubmit={handleLogin}
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    validation.handleSubmit();
                                                    return false;
                                                }}
                                                action="#">

                                                <div className="mb-3">
                                                    <Label htmlFor="email" className="form-label">Електронная почта</Label>
                                                    <Input style={{fontFamily: "Montserrat"}}
                                                        name="username"
                                                        className="form-control"
                                                        placeholder="Введите email"
                                                        type="email"
                                                        onChange={validation.handleChange}
                                                        // onChange={(e) => setUsername(e.target.value)}
                                                        // value={username || ""}
                                                        // onBlur={validation.handleBlur}
                                                        value={validation.values.username || ""}
                                                        // value={username}
                                                        invalid={
                                                            validation.touched.username && validation.errors.username ? true : false
                                                        }
                                                    />
                                                    {validation.touched.username && validation.errors.username ? (
                                                        <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                                                    ) : null}
                                                </div>

                                                <div className="mb-3">
                                                    {/*<div className="float-end">*/}
                                                    {/*    <Link to="/forgot-password" className="text-muted">Forgot password?</Link>*/}
                                                    {/*</div>*/}
                                                    <Label className="form-label" htmlFor="password-input">Пароль</Label>
                                                    <div className="position-relative auth-pass-inputgroup mb-3">
                                                        <Input style={{fontFamily: "Montserrat"}}
                                                            name="password"
                                                            value={validation.values.password || ""}
                                                            // value={password}
                                                            type={passwordShow ? "text" : "password"}
                                                            className="form-control pe-5"
                                                            placeholder="Введите пароль"
                                                            onChange={validation.handleChange}
                                                            // onChange={(e) => setPassword(e.target.value)}
                                                            // onBlur={validation.handleBlur}
                                                            invalid={
                                                                validation.touched.password && validation.errors.password ? true : false
                                                            }
                                                        />
                                                        {validation.touched.password && validation.errors.password ? (
                                                            <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                                        ) : null}
                                                        <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" onClick={() => setPasswordShow(!passwordShow)} id="password-addon"><i className="ri-eye-fill align-middle"></i></button>
                                                    </div>
                                                </div>

                                                {/*<div className="form-check">*/}
                                                {/*    <Input className="form-check-input" type="checkbox" value="" id="auth-remember-check" />*/}
                                                {/*    <Label className="form-check-label" htmlFor="auth-remember-check">Remember me</Label>*/}
                                                {/*</div>*/}

                                                <div className="mt-4">
                                                    <Button color="primary" disabled={error ? null : loading ? true : false} className="btn btn-outline-primary w-100" type="submit">
                                                        {loading ? <Spinner size="sm" className='me-2'> Загрузка... </Spinner> : null}
                                                        Вход
                                                    </Button>
                                                    <GoogleWrapper />
                                                    {/*<Button color="success" className="btn btn-success w-100" type="submit">*/}
                                                    {/*    Sign In*/}
                                                    {/*</Button>*/}
                                                </div>

                                                {/*<div className="mt-4 text-center">*/}
                                                {/*    /!*<div className="signin-other-title">*!/*/}
                                                {/*    /!*    <h5 className="fs-13 mb-4 title">Sign In with</h5>*!/*/}
                                                {/*    /!*</div>*!/*/}
                                                {/*    <div>*/}
                                                {/*        /!*<Link*!/*/}
                                                {/*        /!*    to="#"*!/*/}
                                                {/*        /!*    className="btn btn-primary btn-icon me-1"*!/*/}
                                                {/*        /!*    onClick={e => {*!/*/}
                                                {/*        /!*        e.preventDefault();*!/*/}
                                                {/*        /!*        socialResponse("facebook");*!/*/}
                                                {/*        /!*    }}*!/*/}
                                                {/*        /!*>*!/*/}
                                                {/*        /!*    <i className="ri-facebook-fill fs-16" />*!/*/}
                                                {/*        /!*</Link>*!/*/}
                                                {/*        /!*<Link*!/*/}
                                                {/*        /!*    to="#"*!/*/}
                                                {/*        /!*    className="btn btn-danger btn-icon me-1"*!/*/}
                                                {/*        /!*    // onClick={e => {   // todo: GOOLGLE LOGIN*!/*/}
                                                {/*        /!*    //     e.preventDefault();*!/*/}
                                                {/*        /!*    //     socialResponse("google");*!/*/}
                                                {/*        /!*    // }}*!/*/}
                                                {/*        /!*>*!/*/}
                                                {/*        /!*    <i className="ri-google-fill fs-16" />*!/*/}
                                                {/*        /!*</Link>*!/*/}
                                                {/*        /!* <Button color="primary" className="btn-icon"><i className="ri-facebook-fill fs-16"></i></Button>{" "} *!/*/}
                                                {/*        /!* <Button color="danger" className="btn-icon"><i className="ri-google-fill fs-16"></i></Button>{" "} *!/*/}
                                                {/*        /!*<Button color="dark" className="btn-icon"><i className="ri-github-fill fs-16"></i></Button>{" "}*!/*/}
                                                {/*        /!*<Button color="info" className="btn-icon"><i className="ri-twitter-fill fs-16"></i></Button>*!/*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                            </Form>
                                        </div>
                                    </CardBody>
                                </Card>

                                <div className="mt-4 text-center">
                                    <p className="mb-0">Еще нет аккаунта ? <Link to="/register" className="fw-semibold text-primary text-decoration-underline"> Создать </Link> </p>
                                </div>

                            </Col>
                        </Row>
                    </Container>
                </div>
            </ParticlesAuth>
        </React.Fragment>
    )
};


export default withRouter(Login);
