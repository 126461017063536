import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";
//import logo
import logoSm from "../assets/images/logo-sm.png";
import logoDark from "../assets/images/logo-dark.png";
import logoLight from "../assets/images/logo-light.png";

import { changeSidebarVisibility } from "../slices/thunks";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
//Import Components
import VerticalLayout from "./VerticalLayouts/index";
// import TwoColumnLayout from "./TwoColumnLayout";
import { Container } from "reactstrap";
// import HorizontalLayout from "./HorizontalLayout";
import styles from "./Layouts.module.css";

const Sidebar = ({ layoutType }) => {
  useEffect(() => {
    var closeIcon = document.getElementsByClassName("close-icon");
    if (closeIcon.length > 0) {
      closeIcon[0].addEventListener("click", function () {
        document.body.classList.remove("vertical-sidebar-enable");
      });
    }
    var verticalOverlay = document.getElementsByClassName("vertical-overlay");
    if (verticalOverlay) {
      verticalOverlay[0].addEventListener("click", function () {
        document.body.classList.remove("vertical-sidebar-enable");
      });
    }
  });

  // const addEventListenerOnSmHoverMenu = () => {
  //   // add listener Sidebar Hover icon on change layout from setting
  //   if (
  //     document.documentElement.getAttribute("data-sidebar-size") === "sm-hover"
  //   ) {
  //     document.documentElement.setAttribute(
  //       "data-sidebar-size",
  //       "sm-hover-active"
  //     );
  //   } else if (
  //     document.documentElement.getAttribute("data-sidebar-size") ===
  //     "sm-hover-active"
  //   ) {
  //     document.documentElement.setAttribute("data-sidebar-size", "sm-hover");
  //   } else {
  //     document.documentElement.setAttribute("data-sidebar-size", "sm-hover");
  //   }
  // };

  //////////////////////
  const dispatch = useDispatch();

  const sidebarVisibilityData = createSelector(
    (state) => state.Layout.sidebarVisibilitytype,
    (sidebarVisibilitytype) => sidebarVisibilitytype
  );

  // Inside your component
  const sidebarVisibilitytype = useSelector(sidebarVisibilityData);

  const [search, setSearch] = useState(false);
  const toogleSearch = () => {
    setSearch(!search);
  };

  const toogleMenuBtn = () => {
    var windowSize = document.documentElement.clientWidth;
    dispatch(changeSidebarVisibility("show"));

    // if (windowSize > 767)
    //   document.querySelector(".hamburger-icon").classList.toggle("open");

    // if (document.documentElement.getAttribute("data-layout") === "horizontal") {
    //   document.body.classList.toggle("menu");
    // }
    if (
      sidebarVisibilitytype === "show" &&
      (document.documentElement.getAttribute("data-layout") === "vertical" ||
        document.documentElement.getAttribute("data-layout") === "semibox")
    ) {
      if (windowSize < 1025 && windowSize > 767) {
        document.body.classList.remove("vertical-sidebar-enable");
        document.documentElement.getAttribute("data-sidebar-size") === "sm"
          ? document.documentElement.setAttribute("data-sidebar-size", "")
          : document.documentElement.setAttribute("data-sidebar-size", "sm");
      } else if (windowSize > 1025) {
        document.body.classList.remove("vertical-sidebar-enable");
        document.documentElement.getAttribute("data-sidebar-size") === "lg"
          ? document.documentElement.setAttribute("data-sidebar-size", "sm")
          : document.documentElement.setAttribute("data-sidebar-size", "lg");
      } else if (windowSize <= 767) {
        document.body.classList.add("vertical-sidebar-enable");
        document.documentElement.setAttribute("data-sidebar-size", "lg");
      }
    }
    if (document.documentElement.getAttribute("data-layout") === "twocolumn") {
      document.body.classList.contains("twocolumn-panel")
        ? document.body.classList.remove("twocolumn-panel")
        : document.body.classList.add("twocolumn-panel");
    }
    const container_logo = document.querySelector(".container_logo");
    if (container_logo) {
      const currentFlexDirection =
        getComputedStyle(container_logo).flexDirection;
      container_logo.style.flexDirection =
        currentFlexDirection !== "column-reverse" ? "column-reverse" : "row";
    }
    const h4 = document.querySelector("h4");
    if (h4) {
      h4.style.padding = "0";
      h4.style.margin = "0";
    }
  };
  ////////////////////////
  return (
    <React.Fragment>
      <div className="app-menu navbar-menu"> {/*style={{ width: "200px" }}*/}
        <div className="navbar-brand-box">
          <div className={`container_logo ${styles["container_logo"]}`}>
            <Link to="/products">
              <h4 className={styles.h4_crm}>CRM</h4>
            </Link>

            <button
              onClick={toogleMenuBtn}
              type="button"
              className="btn btn-sm"
              id="topnav-hamburger-icon"
            >
              <span className="hamburger-icon">
                <span></span>
                <span></span>
                <span></span>
              </span>
            </button>
          </div>

          {/* <Link to="/" className="logo logo-light" style={{ lineHeight: "1" }}>
            <span className="logo-sm">
              <h1 style={{ margin: "0", paddingTop: "10px" }}>CRM</h1>
            </span>
            <span className="logo-lg">
              <h2 style={{ paddingTop: "20px" }}>CRM</h2>
            </span>
          </Link> */}
        </div>
        {
          <React.Fragment>
            <SimpleBar id="scrollbar" className="h-100">
              <Container fluid>
                <ul className="navbar-nav" id="navbar-nav">
                  <div className={`menu-title ${styles["menu-title"]}`}>
                    <span>
                    <Link to="/products">
                      <h4 className={styles.logo_mobile}>CRM</h4>
                      </Link>
                    </span>
                    <span>
                      {" "}
                      <img
                        className="close-icon"
                        src="/close.png"
                        alt="close"
                      />
                    </span>
                  </div>
                  <VerticalLayout layoutType={layoutType} />
                </ul>
              </Container>
            </SimpleBar>
            <div className="sidebar-background"></div>
          </React.Fragment>
        }
      </div>
      <div className="vertical-overlay"></div>
    </React.Fragment>
  );
};

export default Sidebar;
