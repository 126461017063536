import React, { useState } from 'react';
import {Form, Input} from "reactstrap";
import styles from './CRM.module.css'

function ImportForm({handleImportProducts, errors, setErrors, disabled}) {
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    // Perform file validation here
    const allowedTypes = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
    if (selectedFile && !allowedTypes.includes(selectedFile.type)) {
      setErrors({ import: 'Неверный формат файла. Пожалуйста, выберите файл в формате .xlsx' });
    } else {
      setErrors({});
      setFile(selectedFile);
    }
  };

  const handleImportFormSubmit = async (e) => {
    e.preventDefault();

    if (file) {
      const formData = new FormData();
      formData.append('pricelist', file);

      await handleImportProducts(formData); // Await the handleImportProducts function

      // Очистить значение инпута после успешной загрузки
      e.target.reset(); // Сбросить значения формы
      setFile(null); // Сбросить выбранный файл

    } else {
      setErrors({ import: 'Пожалуйста, выберите файл' });
    }
  };

  return (
    <Form method="POST" encType="multipart/form-data" onSubmit={handleImportFormSubmit}>
      <div className="row mb-3">
        <Input
          disabled={disabled}
          type="file"
          name="pricelist"
          id="pricelist"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          placeholder="Загрузите ваш прайс-лист в єто поле"
          className="form-control"
          onChange={handleFileChange}
        />
        {errors.import && <div className="text-danger">{errors.import}</div>}
      </div>

      <div className="row justify-content-start" style={{ width: '100px'}}>
        <button type="submit" className={`btn btn-primary ${disabled ? styles.sm_loader : ''}`} disabled={disabled}>Загрузить</button>
        {/*{!disabled && (<div className={styles.sm_loader}>123</div>)}*/}
      </div>
    </Form>
  );
}

export default ImportForm;