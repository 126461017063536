
export function padNumberWithZeros(number, length) {
    let strNumber = String(number);
    while (strNumber.length < length) {
        strNumber = "0" + strNumber;
    }
    return strNumber;
}

export function convertToReadableDateTime(backendDate) {
    let newDate = String(backendDate)
        .replace("T", ". ")
        .replace("-", ".")
        .replace("-", ".")
        .replace("Z", "")
        .slice(0, -3);

    let dateElements = newDate.split(' ')[0].split('.');
    let time = newDate.split(' ')[1];

    return `${dateElements[2]}.${dateElements[1]}.${dateElements[0].slice(2, 4)} ${time}`;
}
