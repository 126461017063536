import React, {useEffect, useState, useCallback, useMemo} from "react";
import * as moment from "moment";
import Flatpickr from "react-flatpickr";
import styles from "../../Crm/CRM.module.css"
import {Link, useLocation} from "react-router-dom";

import {
    Button,
    Col,
    Container,
    Row,
    Card,
    CardHeader,
    CardBody,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Label,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    ModalFooter,
    FormFeedback,
} from "reactstrap";

import DeleteModal from "../../../Components/Common/DeleteModal";

// import WidgetsTask from "./WidgetsTask";

import TableContainer from "../../../Components/Common/TableContainer";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

import Loader from "../../../Components/Common/Loader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


// Export Modal
import ExportCSVModal from "../../../Components/Common/ExportCSVModal";

import {
    importProducts,
    getAccountsApi,
    getBotPricelistApi,
    updateBotSettingsApi,
    getBotSettingsApi,
    updateBotPricelistApi,
    deleteFromBotPricelistApi, getBotIntegrationApi
} from "../../../helpers/backend_helper";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear'; // Иконка для очистки
import {Box, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select, Switch} from '@mui/material';
import InputMui from '@mui/material/Input'
import CircleIcon from '@mui/icons-material/Circle';
import ImportForm from "../../Crm/ImportForm";
import Filter from "./Filter";
// import MassPriceChangeModal from "./MassPriceChangeModal";

const Pricelist = () => {
    const location = useLocation();
    const maxSize = 1048576; // 1 MB in bytes

    const [loading, setLoading] = useState(true);
    const [isEdit, setIsEdit] = useState(false);
    const [isEdited, setIsEdited] = useState(false);
    const [contact, setContact] = useState([]);
    const [success, setSuccess] = useState(true);

    const [products, setProducts] = useState([
        // {
        //     "row": 21,
        //     "sku": "236687",
        //     "model": "Вечернее платье 236687 белый 42",
        //     "brand": "",
        //     "price": "10800",
        //     "preorder": "",
        //     "min_price": "",
        //     "kaspi_code": "114002868",
        //     "no_competitor_price": "",
        //     "max_price": "",
        //     "kaspi_price_now": "",
        //     "top_price": "",
        //     "bot_enabled": true,
        //     "position": "",
        //     "url_item": "https://kaspi.kz/shop/p/vechernee-plat-e-236687-belyi-42-114002868/",
        //     "url_image": "https://resources.cdn-kaspi.kz/img/m/p/h5c/h91/84322932359198.jpg?format=gallery-medium",
        //     "storages": [
        //         {
        //             "name": "PP1",
        //             "active": true
        //         }
        //     ],
        //     "archive": false
        // }
    ]);
    const [metaData, setMetaData] = useState({
        "bot_enabled": false,
        "meta": {
            "active_date": "",
            "api_integration_enabled": false,
            "with_remainders": false
        },
        "page": 1,
        "num_pages": 1,
        "search": "",
        "f": "all",
        "f_name": "Все опубликованные",
        "f_count": 0,
        "amount_rows": 0,
        "pricelist_errors": [],
        "pricelist_errors_count": 0,
        "f_select_data": {}
    });
    const [settings, setSettings] = useState({
        "username": "",
        "price_change_step": 2,
        "skip_merchant_id": "",
        "bot_enabled": false
    });
    const [integration, setIntegration] = useState({
        "allowed": false,
        "enabled": false,
        "external_token": '',
        "pricelist_xlsx": null,
        "last_uploaded_formatted": null,
        "event_logs": [
            // { formatted_created: '10.02.2024 08:30:15', log_type: 'Вход', description: 'Пользователь вошел в систему' },
            // { formatted_created: '10.02.2024 09:15:42', log_type: 'Выход', description: 'Пользователь вышел из системы' },
        ],  // readOnly
    });

    const [fetchParams, setFetchParams] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [delayTimer, setDelayTimer] = useState(null);

    const [paginationOptions, setPaginationOptions] = useState({});
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [filters, setFilters] = useState('all');

    const [selectedImage, setSelectedImage] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [imageValidationError, setImageValidationError] = useState(null);

    const [accounts, setAccounts] = useState({});

    /*const hasPermissionsCallback = useCallback((permissions) => {
        return accounts.results && accounts.current && permissions.includes(accounts.results[accounts.current].access)
    }, [accounts]);*/
    const hasPermissions = (permissions) => {
        return accounts.results && accounts.current && permissions.includes(accounts.results[accounts.current].access)
    }
    /*const getAccessObjects = () => {
        return String(accounts?.results && accounts.current && accounts?.results[accounts.current]?.access_objects);
    }*/
    const deleteAllowedCallback = useCallback(() => {
        return integration.allowed && !integration.enabled;
    }, [integration]);

    useEffect(() => {
        const fetchAccounts = () => {
            getAccountsApi()
                .then((data) => {
                    setAccounts(data);
                })
                .catch(() => {})
        }
        fetchAccounts();
        const fetchIntegration = () => {
            getBotIntegrationApi()
                .then((response) => {
                    setIntegration(response);
                })
                .catch((error) => {});
        };
        fetchIntegration();
    }, []);



    const handleFilterChange = (event) => {
        setFilters(event.target.value);
    };

    useEffect(() => {
        const fetchSettings = async () => {
            try {
                const response = await getBotSettingsApi();
                setSettings(response);
            } catch (error) {}
        };
        fetchSettings();
    }, []);

    const toggleBot = async () => {
        try {
            const newBotEnabled = !settings.bot_enabled;
            setSettings(prevSettings => ({
                ...prevSettings,
                bot_enabled: newBotEnabled
            }));
            await updateBotSettingsApi({ bot_enabled: newBotEnabled });
        } catch (error) {}
    };

    function setPaginationOptionsFromData(data) {
        const currentPage = data.page;
        const totalPages = data.num_pages;

        // Calculate previous and next pages
        const previousPage = currentPage > 1 ? currentPage - 1 : null;
        const nextPage = currentPage < totalPages ? currentPage + 1 : null;

        // Format the previous and next links
        const previousLink = previousPage ? `?page=${previousPage}` : null;
        const nextLink = nextPage ? `?page=${nextPage}` : null;

        // Set pagination options
        setPaginationOptions({
            // count: data.count,
            // previous: data.previous,
            // next: data.next
            count: data.amount_rows,
            previous: previousLink,
            next: nextLink
        });
    }


    const fetchProducts = ({page, search}) => {
        let data = {};
        if (page) {
            data.page = page;
            setCurrentPage(page);
        }else{
            setCurrentPage(1);
        }
        if (searchTerm) {
            data.search = search;
        }
        if (filters !== 'all') {
            data.f = filters;
        }
        getBotPricelistApi(data)
            .then((json) => {
                let {data, ...metadata} = json;

                if (data) {
                    data.reverse()
                    setMetaData(metadata);
                }

                // setProducts(data.results);
                // setFilteredProducts(data.results);
                setProducts(data);
                setFilteredProducts(data);

                setPaginationOptionsFromData(metadata);
                setSuccess(true);
            })
            .catch((error) => {
                setSuccess(false);
                // toast.error("Не удалось получить прайслист", {position: "top-right",});
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        const message = location.state && location.state.message;
        if (message) {
            toast.success(message);
        }
        // fetchProducts({});
    }, []);

    //////////ПОИСК//////////////////
    const [searchTerm, setSearchTerm] = useState("");
    const [searchProducts, setSearchProducts] = useState("");
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };
    const handleProductsChange = (tags) => {
        // setSearchProducts(tags);
        setSearchTerm(tags);
    };


    /////////////////////////
    useEffect(() => {
        // Check if products is populated and not empty
        if (searchTerm) {
            if (delayTimer) {
                clearTimeout(delayTimer);
            }
            const timer = setTimeout(() => {
                setFetchParams({ search: searchTerm });
                fetchProducts({ search: searchTerm });
            }, 700); // Adjust delay time as needed
            setDelayTimer(timer);
            // filteredProducts = products.filter((product) =>
            //   product.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
            //   product.code.toLowerCase().includes(searchTerm.toLowerCase())
            // );
        }else{
            const { search, page, ...updatedParams } = fetchParams;
            setFetchParams(updatedParams);
            fetchProducts(updatedParams);
        }
    }, [searchTerm, filters]);

    const onPageChange = (data) => {
        const updatedFetchParams = { ...fetchParams };
        if (data.page){
            updatedFetchParams.page = data.page;
        }else{
            delete updatedFetchParams.page;
        }
        // Call fetchProducts with an updated params
        fetchProducts(updatedFetchParams);
        setFetchParams(updatedFetchParams);
    };


    //delete Contact
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteModalMulti, setDeleteModalMulti] = useState(false);
    const [createMulti, setCreateMulti] = useState(false);
    const [isCreateButton, setIsCreateButton] = useState(false);

    const [filtering, setFiltering] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [importing, setImporting] = useState(false);
    const [importLoading, setImportLoading] = useState(false);

    /*useEffect(() => {
        // Define an async function
        const fetchProgress = async () => {
            try {
                const data = await progressUpdateFromKaspiAPI();
                const { progress, is_updating } = data;
                setIsUpdating(is_updating);
                setLoading(is_updating);
            } catch (error) {
                console.log(error);
            }
        };
        // Call the async function
        fetchProgress();
    }, [isUpdating]); // Empty dependency array ensures this runs once on mount*/

    /*const startUpdateFromKaspi = () => {
        if (!isUpdating) {
            setIsUpdating(true);
            setLoading(true);
            setImporting(false);
            setFiltering(false);
            // Запускаем процесс на сервере
            updateFromKaspiApi()
                .then((data) => {
                    fetchProducts(fetchParams);
                    toast.success(data?.message, {position: 'top-right'});
                    setIsUpdating(false);
                    setLoading(false);
                })
                .catch((error) => {
                    if (String(error?.response?.status).startsWith('4')) {
                        const { message } = error.response.data;
                        toast.error(message, { position: "top-right" })
                    }else{
                        toast.error('Не удалось обновить прайслист', { position: "top-right" })
                    }
                    setIsUpdating(false);
                    setLoading(false);
                });
        }
    };*/

    // useEffect(() => {
    //   const checkUpdating = () => {
    //     // getAccountsApi()
    //     //     .then((data) => {
    //     //       setAccounts(data);
    //     //     })
    //     //     .catch(() => {
    //     //       toast.error("Не удалось получить список аккаунтов", {
    //     //         position: "top-right",
    //     //       });
    //     //     })
    //   }
    //   fetchAccounts();
    // }, []);

    const toggleFiltering = () => {
        if (filtering) {
            setFiltering(false);
        } else {
            setImporting(false);
            setFiltering(true);
        }
    };

    const toggleImporting = () => {
        if (importing) {
            setImporting(false);
        } else {
            setFiltering(false);
            setImporting(true);
        }
    };

    const create = () => {
        const checkall = document.getElementById("checkBoxAll");
        selectedCheckBoxDelete.forEach((element) => {
            // dispatch(deleteTask(element.value));  // delete with checkboxes
            setTimeout(() => {
                toast.clearWaitingQueue();
            }, 3000);
        });
        setIsCreateButton(false);
        checkall.checked = false;
    };

    const createbox = () => {
        const ele = document.querySelectorAll(".taskCheckBox:checked");
        ele.length > 0 ? setIsCreateButton(true) : setIsCreateButton(false);
        setSelectedCheckBoxDelete(ele);
    };
    const [modal, setModal] = useState(false);
    const [modalTask, setModalTask] = useState(false);
    const [errors, setErrors] = useState({});

    const toggle = useCallback(() => {
        if (modal) {
            setModal(false);
            setContact(null);
            setSelectedFile(null);
            setSelectedImage("");
        } else {
            setErrors({});
            setImageValidationError(null);
            validation.resetForm();
            setModal(true);
            setTag([]);
            setAssignTag([]);
        }
    }, [modal]);

    const toggleTask = useCallback(() => {
        if (modalTask) {
            setModalTask(false);
            setContact(null);
            setSelectedFile(null);
            setSelectedImage("");
        } else {
            setModalTask(true);
            setTag([]);
            setAssignTag([]);
        }
    }, [modalTask]);

    // Delete Data
    const handleDeleteContact = () => {
        if (contact) {
            deleteFromBotPricelistApi(contact)
                .then((data) => {
                    fetchProducts(fetchParams);
                    // const updatedProducts = (prevProduct) => prevProduct.filter((item) => item.id !== contact.id);
                    // setProducts(updatedProducts);
                    // setFilteredProducts(updatedProducts);
                    toast.success("Товар был успешно удалён.", { position: "top-right" });
                })
                .catch((error) => {
                    // console.error(error);
                    toast.error("Не удалось удалить товар.", { position: "top-right" });
                });
            setDeleteModal(false);
        }
    };

    const onClickDelete = (contact) => {
        setContact(contact);
        setDeleteModal(true);
    };

    // Add Data
    const handleContactClicks = () => {
        // Create Contact
        validation.resetForm();
        setErrors({});
        setContact("");
        setSelectedFile(null);
        setSelectedImage("");
        setIsEdit(false);
        setIsEdited(false);
        toggle();
    };

    // Date & Time Format

    const dateFormat = () => {
        var d = new Date(),
            months = [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
            ];
        return d.getDate() + " " + months[d.getMonth()] + ", " + d.getFullYear();
    };
    const dateformate = (e) => {
        const date = e.toString().split(" ");
        const joinDate = (date[2] + " " + date[1] + ", " + date[3]).toString();
        setDate(joinDate);
    };

    //createTask
    const [task, setTask] = useState([]);
    const valid = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            taskId: (task && task.taskId) || "",
            project: (task && task.project) || "",
            task: (task && task.task) || "",
            creater: (task && task.creater) || "",
            dueDate: (task && task.dueDate) || "",
            status: (task && task.status) || "New",
            priority: (task && task.priority) || "High",
            subItem: (task && task.subItem) || [],
        },
        taskSchema: Yup.object({
            taskId: Yup.string().required("Please Enter Task Id"),
            project: Yup.string().required("Please Enter Project Name"),
            task: Yup.string().required("Please Enter Your Task"),
            creater: Yup.string().required("Please Enter Creater Name"),
            // dueDate: Yup.string().required("Please Enter Due Date"),
            status: Yup.string().required("Please Enter Status"),
            priority: Yup.string().required("Please Enter Priority"),
            subItem: Yup.array().required("Please Enter"),
        }),
        onSubmit: (values) => {
            if (isEdit) {
                const updatedTask = {
                    _id: task ? task._id : 0,
                    taskId: values.taskId,
                    project: values.project,
                    task: values.task,
                    creater: values.creater,
                    dueDate: date,
                    status: values.status,
                    priority: values.priority,
                    subItem: values.subItem,
                };
                // update customer
                // dispatch(updateTask(updatedTask));  // UPDATE TASK
                valid.resetForm();
            } else {
                const newTask = {
                    _id: (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
                    taskId: values["taskId"],
                    project: values["project"],
                    task: values["task"],
                    creater: values["creater"],
                    dueDate: date,
                    status: values["status"],
                    priority: values["priority"],
                    subItem: values["subItem"],
                };
                // save new customer
                // dispatch(addNewTask(newTask));  // ADD NEW TASK
                task.resetForm();
            }
            toggleTask();
        },
    });
    const defaultdate = () => {
        let d = new Date(),
            months = [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
            ];
        return (
            d.getDate() +
            " " +
            months[d.getMonth()] +
            ", " +
            d.getFullYear()
        ).toString();
    };
    const [date, setDate] = useState(defaultdate());

    // Create a function to update an element in products
    const updateItemInProducts = (updatedItem) => {
        const updatedProducts = (prevProducts) => {
            return prevProducts.map((item) => {
                if (item.row === updatedItem.row) {
                    // Update the item with the updatedItem data
                    return updatedItem;
                }
                return item;
            });
        }
        setProducts(updatedProducts);
        setFilteredProducts(updatedProducts);
    };

    // validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            row: (contact && contact.row) || "",
            image: (contact && contact.image) || "",
            model: (contact && contact.model) || "",
            sku: (contact && contact.sku) || "",
            bot_enabled: (contact && contact.bot_enabled) || false,
            // description: (contact && contact.description) || "",
            price: (contact && contact.price) || "",
            min_price: (contact && contact.min_price) || "",
            max_price: (contact && contact.max_price) || "",
            preorder: (contact && contact.preorder) || "",
            kaspi_code: (contact && contact.kaspi_code) || "",
            no_competitor_price: (contact && contact.no_competitor_price) || "",

            kaspi_price_now: (contact && contact.kaspi_price_now) || "",
            top_price: (contact && contact.top_price) || "",
        },
        validationSchema: Yup.object({
            // image: Yup.string().required("Пожалуйста выберите картинку"),
            model: Yup.string().required("Пожалуйста введите название Товара"),
            sku: Yup.string().required("Пожалуйста введите Артикул"),
            price: Yup.number().required("Пожалуйста выставите Цену продажи"),
            bot_enabled: Yup.bool(),
            // min_price: Yup.number(),
            // max_price: Yup.number(),
            // no_competitor_price: Yup.number(),
            // preorder: Yup.number(),
        }),
        validateOnChange: true,

        validate: (values) => {
            const errors = {};
            if (values.price === 0) {
                values.price = "";
            } else if (values.price < 0) {
                errors.price = "Цена продажи не может быть отрицательной";
            } else if (values.price > 9999999) {
                errors.price = "Цена продажи не может быть больше 9999999";
            } else if (String(values.price).includes('.')) {
                errors.price = "Введите целое число";
            }

            if (values.min_price === 0) {
                values.min_price = "";
            } else if (values.min_price < 0) {
                errors.min_price = "Минимальная цена не может быть отрицательной";
            } else if (values.price > 9999999) {
                errors.min_price = "Минимальная цена не может быть больше 9999999";
            } else if (String(values.min_price).includes('.')) {
                errors.min_price = "Введите целое число";
            }

            if (values.max_price === 0) {
                values.max_price = "";
            } else if (values.max_price < 0) {
                errors.max_price = "Максимальная цена не может быть отрицательной";
            } else if (values.price > 9999999) {
                errors.max_price = "Максимальная цена не может быть больше 9999999";
            } else if (String(values.max_price).includes('.')) {
                errors.max_price = "Введите целое число";
            }

            if (values.no_competitor_price === 0) {
                values.no_competitor_price = "";
            } else if (values.no_competitor_price < 0) {
                errors.no_competitor_price = "Неконкурентная цена не может быть отрицательной";
            } else if (values.no_competitor_price > 9999999) {
                errors.no_competitor_price = "Неконкурентная цена не может быть больше 9999999";
            } else if (String(values.no_competitor_price).includes('.')) {
                errors.no_competitor_price = "Введите целое число";
            }

            if (values.preorder === 0) {
                values.preorder = "";
            } else if (values.preorder < 0) {
                errors.preorder = "Количество дней предзаказа не может быть отрицательным";
            } else if (values.preorder > 100) {
                errors.preorder = "Максимальное значение 100";
            } else if (String(values.preorder).includes('.')) {
                errors.preorder = "Введите целое число";
            }

            return errors;
        },

        onSubmit: (values) => {
            setErrors({});

            if (isEdit) {
                const productRow = contact ? contact.row : 0;

                const updateProductData = {
                    row: productRow,
                    model: values.model,
                    sku: values.sku,
                    price: values.price || null,
                    bot_enabled: values.bot_enabled,
                    min_price: values.min_price || null,
                    max_price: values.max_price || null,
                    preorder: values.preorder || null,
                    kaspi_code: values.kaspi_code,
                    no_competitor_price: values.no_competitor_price || null,
                    // joined: dateFormat(),
                    // time: timeFormat(),
                    // tags: assignTag,
                };
                // const updateProductData = new FormData();
                // updateProductData.append("row", productRow);
                // if (selectedFile) {
                //     updateProductData.append("image", selectedFile);
                // }
                // updateProductData.append("model", values.title);
                // updateProductData.append("sku", values.code);
                // // updateProductData.append("description", values.description);
                // updateProductData.append("price", values.price);
                // updateProductData.append("bot_enabled", values.bot_enabled);
                // updateProductData.append("min_price", values.min_price);
                // updateProductData.append("max_price", values.max_price);
                // updateProductData.append("preorder", values.preorder);
                // updateProductData.append("kaspi_code", values.kaspi_code);
                // updateProductData.append("no_competitor_price", values.no_competitor_price);

                updateBotPricelistApi([updateProductData])
                    .then((response) => {
                        const {data, message} = response;
                        // updateItemInProducts(data[0]);
                        fetchProducts(fetchParams);
                        setModal(false);
                        toast.success(message || "Товар успешно обновлён", { position: "top-right" });
                    })
                    .catch((error) => {
                        if (
                            Array.isArray(error?.response?.data)
                            &&
                            error?.response?.status === 400
                        ) {
                            setErrors(error.response.data[0]);
                        } else {
                            toast.error("Не удалось обновить товар", {
                                position: "top-right",
                            });
                        }
                    });

                /*updateProductApi(productId, updateProductData)
                    .then((updatedProductData) => {
                        // Call the function to update the element in products state
                        updateItemInProducts(updatedProductData);
                        // fetchProducts(fetchParams);
                        setModal(false);
                        toast.success("Товар успешно обновлён", { position: "top-right" });
                    })
                    .catch((error) => {
                        if (
                            error?.response?.data?.errors &&
                            error?.response?.status === 400
                        ) {
                            setErrors(error.response.data.errors);
                        } else {
                            toast.error("Не удалось обновить товар", {
                                position: "top-right",
                            });
                        }
                    });*/
            }
            /*else {
                const newProductData = new FormData();
                if (selectedFile) {
                    newProductData.append("image", selectedFile);
                }
                newProductData.append("model", values.title);
                newProductData.append("sku", values.code);
                // newProductData.append("description", values.description);
                newProductData.append("price", values.price);

                createProductApi(newProductData)
                    .then((createdProductData) => {
                        // Call the function to update the element in teamList
                        let updatedProductsData = [createdProductData, ...products];
                        setProducts(updatedProductsData);
                        setModal(false);
                        toast.success("Товар был создан успешно", {
                            position: "top-right",
                        });
                    })
                    .catch((error) => {
                        // console.error(error);
                        if (
                            error?.response?.data?.errors &&
                            error?.response?.status === 400
                        ) {
                            setErrors(error.response.data.errors);
                        } else {
                            // setErrors('Unexpected error occurred.')
                            toast.error("Не удалось создать товар", {
                                position: "top-right",
                            });
                        }
                    });
            }*/
            if (!errors) {
                if (isEdit) {
                    setIsEdit(false);
                }
                validation.resetForm();
                toggle();
            }
        },
    });

    // Update Data
    const handleContactClick = useCallback(
        (arg) => {
            const contact = arg;
            setContact({
                row: contact.row,
                image: contact.image,
                model: contact.model,
                sku: contact.sku,
                bot_enabled: contact.bot_enabled,
                // price: contact.price ? contact.price.split('.')[0]: '',
                price: contact.price,
                min_price: contact.min_price,
                max_price: contact.max_price,
                preorder: contact.preorder,
                kaspi_code: contact.kaspi_code,
                no_competitor_price: contact.no_competitor_price,
                // joined: contact.joined,f
                // time: contact.time,
                // tags: contact.tags,
            });
            // TODO: if file exists -> set image url
            setSelectedFile(null);
            contact.image ?
                setSelectedImage(contact.image) :
                contact?.url_image ?
                    setSelectedImage(contact?.url_image) :
                    setSelectedImage("");

            setIsEdit(true);
            toggle();
        },
        [toggle]
    );

    // Node API
    // useEffect(() => {
    //   if (isContactCreated) {
    //     setContact(null);
    //     dispatch(onGetContacts());
    //   }
    // }, [
    //   dispatch,
    //   isContactCreated,
    // ]);

    const handleValidDate = (date) => {
        const date1 = moment(new Date(date)).format("DD MMM Y");
        return date1;
    };

    const handleValidTime = (time) => {
        const time1 = new Date(time);
        const getHour = time1.getUTCHours();
        const getMin = time1.getUTCMinutes();
        const getTime = `${getHour}:${getMin}`;
        var meridiem = "";
        if (getHour >= 12) {
            meridiem = "PM";
        } else {
            meridiem = "AM";
        }
        const updateTime =
            moment(getTime, "hh:mm").format("hh:mm") + " " + meridiem;
        return updateTime;
    };

    // Checked All
    const checkedAll = useCallback(() => {
        const checkall = document.getElementById("checkBoxAll");
        const ele = document.querySelectorAll(".contactCheckBox");

        if (checkall.checked) {
            ele.forEach((ele) => {
                ele.checked = true;
            });
        } else {
            ele.forEach((ele) => {
                ele.checked = false;
            });
        }
        deleteCheckbox();
    }, []);

    // Delete Multiple
    const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
    const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);

    const deleteMultiple = async () => {
        const checkall = document.getElementById("checkBoxAll");
        let withoutErr = true;

        // Копируем текущие продукты
        let updatedProducts = [...filteredProducts];

        for (const element of selectedCheckBoxDelete) {
            const sku = element.value;
            const productToDelete = updatedProducts.find(product => product.sku === sku);

            if (productToDelete) {
                const { row } = productToDelete;

                try {
                    await deleteFromBotPricelistApi({ sku, row });

                    // Удаляем товар и обновляем `row` у следующих товаров в локальной переменной
                    updatedProducts = updatedProducts
                        .filter((item) => item.sku !== sku) // Удаляем товар
                        .map((item) => {
                            const itemRow = Number(item.row);
                            const targetRow = Number(row);
                            return itemRow > targetRow
                                ? { ...item, row: itemRow - 1 }
                                : item;
                        });

                    // Очищаем очередь тостов
                    setTimeout(() => {
                        toast.clearWaitingQueue();
                    }, 3000);

                } catch (error) {
                    withoutErr = false;
                    toast.error("Не удалось удалить товар", { position: "top-right" });
                }
            } else {
                toast.error(`Не найдено товар с артикулом ${sku}`, { position: "top-right" });
            }
        }

        // Обновляем состояние после всех удалений
        setProducts(updatedProducts);
        fetchProducts(fetchParams);
        setDeleteModal(false);

        if (withoutErr) {
            toast.success("Выбранные товары были успешно удалены", {
                position: "top-right",
            });
        }

        setIsMultiDeleteButton(false);
        checkall.checked = false;
        checkedAll();
    };

    const deleteCheckbox = () => {
        const ele = document.querySelectorAll(".contactCheckBox:checked");
        ele.length > 0
            ? setIsMultiDeleteButton(true)
            : setIsMultiDeleteButton(false);
        setSelectedCheckBoxDelete(ele);
    };

    const handleImportProducts = async (formData) => {
        if ('import' in errors) {
            delete errors['import'];
        }
        try {
            setImportLoading(true);
            const response = await importProducts(formData);
            setImportLoading(false);
            if (response.success) {
                toast.success(response.message);
                fetchProducts({});
                setImporting(false);
            }else{
                // toast.error(response.message);
                setErrors({'import': response.message, ...errors});
            }
        } catch (error) {
            if (error?.response?.data && error?.response?.status === 400) {
                toast.error(error.response.data.message); // , { autoClose: 10000 }
            } else {
                toast.error("Возникла ошибка. Повторите попытку позже");
            }
        }
    }

    // Define a custom filter function for the "Status" column
    // const statusColumnFilter = ({
    //                               column: { filterValue, setFilter },
    //                             }) => {
    //   const onChange = useAsyncDebounce((value) => {
    //     setFilter(value || undefined);
    //   }, 200);
    //
    //   return (
    //       <input
    //           type="text"
    //           placeholder="Filter Status"
    //           value={filterValue || ''}
    //           onChange={(e) => {
    //             onChange(e.target.value);
    //           }}
    //       />
    //   );
    // };

    // Customber Column
    const columns = useMemo(() =>
        {
            const baseColumns = [
                /*{
                    Header: (
                        <input
                            type="checkbox"
                            id="checkBoxAll"
                            className="form-check-input"
                            onClick={() => checkedAll()}
                        />
                    ),
                    Cell: (cellProps) => {
                        return (
                            <input
                                type="checkbox"
                                className="contactCheckBox form-check-input"
                                value={cellProps.row.original.sku}
                                // value={`${cellProps.row.original.row},${cellProps.row.original.sku}`}
                                onChange={() => deleteCheckbox()}
                            />
                        );
                    },
                    id: "#",
                },*/
                {
                    Header: "",
                    accessor: "row",
                    filterable: true,
                    Cell: (product) => (
                        <div style={{color: 'grey'}}>
                            {product.row.original.row}
                        </div>
                    ),
                },
                {
                    Header: "Название",
                    accessor: "title",
                    filterable: true,
                    Cell: (product) => (
                        <>
                            <div
                                onClick={() => {
                                    const contactData = product.row.original;
                                    handleContactClick(contactData);
                                    setErrors({});
                                    validation.resetForm();
                                }}
                                style={{
                                    display: "flex",
                                    justifyContent: "left",
                                    alignItems: "center",
                                    cursor: 'pointer'
                                }}
                            >
                                {product.row.original.image || product.row.original.url_image ? (
                                    <Link to={product.row.original?.url_item || '#'} target={'_blank'}>
                                        <div
                                            onClick={() => {}}
                                            style={{
                                                position: 'relative',
                                                height: "30px",
                                                width: "35px",
                                                minHeight: "30px",
                                                minWidth: "35px",
                                                marginRight: "10px",
                                                verticalAlign: "middle",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                cursor: 'pointer'
                                            }}
                                        >

                                                <img
                                                    src={product.row.original.image || product.row.original.url_image}
                                                    style={{
                                                        width: "auto",
                                                        maxWidth: "100%",
                                                        maxHeight: "100%",
                                                    }}
                                                    alt="картинка товара"
                                                />

                                        </div>
                                    </Link>
                                ) : null}
                                {/*<CircleIcon
                                    style={{
                                        height: '14px',
                                        // transform: 'translateY(15px)',
                                        color: product.row.original.bot_enabled ? '#9def86' : '#ffc3c3'
                                    }}
                                />*/}
                                <span
                                    style={{
                                        lineHeight: '1.3', wordWrap: 'break-word',
                                        whiteSpace: 'normal', textDecoration: 'underline',
                                        color: '#33839f'
                                    }}
                                >
                                  {product.row.original.model}
                                </span>
                            </div>
                        </>
                    ),
                },
                {
                    Header: "Артикул",
                    accessor: "sku",
                    filterable: true,
                    Cell: (product) => (
                        <>
                            {/*<Link to={ "/products/" + product.row.original.id + '/' }>*/}
                            {/*    <span style={{color: '#5691b6'}}>*/}
                            {product.row.original.sku}
                            {/*</span>*/}
                            {/*</Link>*/}
                        </>
                    ),
                },
                /*{
                    Header: "Бренд",
                    accessor: "brand",
                    filterable: true,
                    Cell: (product) => (
                        <>
                            {product.row.original.brand}
                        </>
                    ),
                },*/
                {
                    Header: "Предзаказ дней",
                    accessor: "preorder",
                    filterable: true,
                    Cell: (product) => (
                        <>
                            {product.row.original.preorder}
                        </>
                    ),
                },
                {
                    Header: "Цена продажи",
                    accessor: "price",
                    filterable: false,
                    Cell: (product) => (
                        <>
                            {/*<span>{product.row.original.price && product.row.original.price.split('.')[0] || "-"}</span>*/}
                            <span>{product.row.original.price || "-"}</span>
                        </>
                    ),
                },
                /*{
                    Header: "Предзаказ",
                    accessor: "preorder",
                    filterable: true,
                    Cell: (product) => (
                        <>
                            {product.row.original.preorder}
                        </>
                    ),
                },*/
                {
                    Header: "Мин. цена",
                    accessor: "min_price",
                    filterable: true,
                    Cell: (product) => (
                        <>
                            {product.row.original.min_price}
                        </>
                    ),
                },
                {
                    Header: "Макс. цена",
                    accessor: "max_price",
                    filterable: true,
                    Cell: (product) => (
                        <>
                            {product.row.original.max_price}
                        </>
                    ),
                },
                {
                    Header: "Неконкурентная цена",
                    accessor: "no_competitor_price",
                    filterable: true,
                    Cell: (product) => (
                        <>
                            {product.row.original.no_competitor_price}
                        </>
                    ),
                },
                {
                    Header: "Kaspi код",
                    accessor: "kaspi_code",
                    filterable: true,
                    Cell: (product) => (
                        <>
                            {product.row.original.kaspi_code}
                        </>
                    ),
                },
                {
                    Header: "1-е место",
                    accessor: "top_price",
                    filterable: true,
                    Cell: (product) => (
                        <>
                            {product.row.original.top_price}
                        </>
                    ),
                },
                /*{
                    Header: "Включен",
                    accessor: "bot_enabled",
                    filterable: true,
                    Cell: (product) => (
                        // <>
                        //     {product.row.original.bot_enabled ? (
                        //         <CheckCircleIcon style={{ color: green[500] }} />
                        //     ) : (
                        //         <CancelIcon style={{ color: red[500] }} />
                        //     )}
                        // </>
                        <Box
                            display="flex"
                            // justifyContent="center"
                            // alignItems="center"
                            height="100%"
                        >
                            <CircleIcon
                                style={{
                                    color: product.row.original.bot_enabled ? 'limegreen' : 'red'
                                }}
                            />
                        </Box>
                    ),
                },*/
                // {
                //   Header: "Продано",
                //   accessor: "sold",
                //   filterable: false,
                //   Cell: (product) => (
                //     <>
                //       <span>{product.row.original.sold}</span>
                //     </>
                //   ),
                // },
                // {
                //   Header: "Дата создания",
                //
                //   Cell: (contact) => (
                //     <>
                //       <small className="text-muted">
                //         {contact.row.original.created}
                //       </small>
                //     </>
                //   ),
                // },
                {
                    Header: "Действие",
                    Cell: (cellProps) => {
                        return (
                            <ul className="list-inline hstack gap-2 mb-0">

                                <li className="list-inline-item">
                                    <UncontrolledDropdown>
                                        <DropdownToggle
                                            href="#"
                                            className="btn btn-soft-secondary btn-sm dropdown"
                                            tag="button"
                                        >
                                            <i className="ri-more-fill align-middle"></i>
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu-end">
                                            {deleteAllowedCallback() &&
                                                <DropdownItem
                                                    className="dropdown-item edit-item-btn"
                                                    // href="#"
                                                    onClick={() => {
                                                        const contactData = cellProps.row.original;
                                                        handleContactClick(contactData);
                                                        setErrors({});
                                                        validation.resetForm();
                                                    }}
                                                >
                                                    <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{" "}
                                                    Редактировать
                                                </DropdownItem>
                                            }
                                            <DropdownItem
                                                className="dropdown-item edit-item-btn"
                                                href={cellProps.row.original?.url_item || '#'}
                                                target={'_blank'}
                                            >
                                                <i className="ri-search-line align-bottom me-2 text-muted"></i>{" "}
                                                Открыть на Kaspi
                                            </DropdownItem>
                                            {hasPermissions(['owner']) && deleteAllowedCallback() && (
                                                <DropdownItem
                                                    className="dropdown-item remove-item-btn"
                                                    href="#"
                                                    onClick={() => {
                                                        const contactData = cellProps.row.original;
                                                        onClickDelete(contactData);
                                                    }}
                                                >
                                                    <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}
                                                    Удалить
                                                </DropdownItem>
                                            )}
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </li>
                            </ul>
                        );
                    },
                }
            ]
            /*if (hasPermissionsCallback(['owner', 'admin'])) {
                baseColumns.splice(4, 0, {
                    Header: "Себестоимость",
                    accessor: "self_cost",
                    filterable: false,
                    Cell: (product) => (
                        <>
                            {/!*<span>{product.row.original.self_cost && product.row.original.self_cost.split('.')[0] || "-"}</span>*!/}
                            <span>{product.row.original.self_cost || "-"}</span>
                        </>
                    ),
                })
            }*/
            if (deleteAllowedCallback()) {
                baseColumns.splice(0, 0, {
                    Header: (
                        <input
                            type="checkbox"
                            id="checkBoxAll"
                            className="form-check-input"
                            onClick={() => checkedAll()}
                        />
                    ),
                    Cell: (cellProps) => {
                        return (
                            <input
                                type="checkbox"
                                className="contactCheckBox form-check-input"
                                value={cellProps.row.original.sku}
                                // value={`${cellProps.row.original.row},${cellProps.row.original.sku}`}
                                onChange={() => deleteCheckbox()}
                            />
                        );
                    },
                    id: "#",
                })
            }
            return baseColumns;
        },
        [handleContactClick, checkedAll, deleteAllowedCallback] /*, hasPermissionsCallback*/
    );

    // Define your static options
    const options = [
        { label: "Option 1", value: "option1" },
        { label: "Option 2", value: "option2" },
        { label: "Option 3", value: "option3" },
    ];
    const formatDate = (dateString) => {
        // return format(new Date(dateString), 'dd MMMM yyyy h:m:s');
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        };

        // Format date in Russian
        return new Intl.DateTimeFormat('ru-RU', options).format(new Date(dateString));
    };

    const [tag, setTag] = useState([]);
    const [assignTag, setAssignTag] = useState([]);

    // SideBar Contact Deatail
    const [info, setInfo] = useState([]);

    // Export Modal
    const [isExportCSV, setIsExportCSV] = useState(false);

    // Mass Price Change
    /*const [isMassPriceChangeModalOpen, setMassPriceChangeModalOpen] = useState(false);
    const toggleMassPriceChangeModal = () => {
        setMassPriceChangeModalOpen(!isMassPriceChangeModalOpen);
    };*/

    // Screen Wideness
    const minWideScreenWidth = 820;
    const [isWideScreen, setIsWideScreen] = useState(window.innerWidth >= minWideScreenWidth);
    useEffect(() => {
        const handleResize = () => {
            setIsWideScreen(window.innerWidth >= minWideScreenWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    document.title = "CRMBEK : Прайслист";

    return (
        <React.Fragment>
            <ToastContainer closeButton={false} />
            <div className="page-content">
                <ExportCSVModal
                    show={isExportCSV}
                    onCloseClick={() => setIsExportCSV(false)}
                    data={[]}
                />
                <DeleteModal
                    show={deleteModal}
                    onDeleteClick={handleDeleteContact}
                    onCloseClick={() => setDeleteModal(false)}
                />

                <DeleteModal
                    show={deleteModalMulti}
                    onDeleteClick={() => {
                        deleteMultiple();
                        setDeleteModalMulti(false);
                    }}
                    onCloseClick={() => setDeleteModalMulti(false)}
                />

                <Container fluid>
                    <BreadCrumb title="Прайслист" />

                    {hasPermissions(['owner'])
                    // || getAccessObjects().includes('products')
                        ? success ? (
                        <Row className="pb-5"> {/*px-5  */}
                            <Col lg={12}>
                                <Card style={{ marginBottom: "0" }}>
                                    <CardHeader>
                                        {metaData.pricelist_errors.length > 0 &&
                                            <div style={{color: '#f46a6a'}}>
                                                <span className="fs-18">Ошибки ({metaData.pricelist_errors_count}):</span>
                                                <ul className="list-messages mt-0">
                                                    {metaData.pricelist_errors.map(error => (
                                                        <li>{error}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        }
                                        {!settings.bot_enabled &&
                                            <ul className="list-messages mt-0 mb-1">
                                                <li style={{color: '#f46a6a'}}>Бот отключен. Вы можете включить его обратно, нажав на кнопку "Включить бот"</li>
                                            </ul>
                                        }

                                        <div className="d-flex align-items-center flex-wrap gap-2">
                                            <div className="flex-grow-1 d-flex align-items-center gap-3">
                                                {/*<Link to="/add-product" disabled={isUpdating} style={isUpdating ? {pointerEvents: 'none'} : {}}>
                                                    <button
                                                        disabled={isUpdating}
                                                        className="btn btn-info add-btn"
                                                        onClick={() => {
                                                            setModal(true);
                                                            setIsEdit(false);
                                                            setContact(null);
                                                            setSelectedFile(null);
                                                            setSelectedImage("");
                                                            setErrors({});
                                                            validation.resetForm();
                                                        }}
                                                    >
                                                      <span style={{fontSize: "14px", fontWeight: "400"}}>
                                                        <i className="ri-add-fill me-1 align-bottom fs-14"></i>Товар
                                                      </span>
                                                    </button>
                                                </Link>*/}
                                                {/*<button
                                                    disabled={isUpdating}
                                                    className={`btn ${filtering ? 'btn-primary' : 'btn-outline-primary'} px-2 fs-14`}
                                                    onClick={toggleFiltering}
                                                >
                                                    Фильтры <i className="ri-filter-2-line me-1 align-bottom"></i>
                                                </button>*/}

                                                <div style={{ flexGrow: 1, position: 'relative' }}>
                                                    <InputMui
                                                        fullWidth={true}
                                                        placeholder="Поиск..."
                                                        value={searchTerm}
                                                        onChange={(e) => handleProductsChange(e.target.value)}
                                                        sx={{
                                                            padding: '4px 8px',
                                                            borderRadius: '18px',
                                                            backgroundColor: '#f3f3f9',
                                                            outline: 'none',
                                                            border: 'none',
                                                            textDecoration: 'none',
                                                            '&::before': {
                                                                content: 'none', // Ensure that no content is being added
                                                            },
                                                            '&::after': {
                                                                content: 'none', // Ensure that no content is being added
                                                            },
                                                            // fontFamily: 'Rubik, sans-serif'
                                                        }}
                                                        startAdornment={
                                                            <InputAdornment position="start">
                                                                <SearchIcon />
                                                            </InputAdornment>
                                                        }
                                                        endAdornment={
                                                            searchTerm && (
                                                                <InputAdornment position="end">
                                                                    <IconButton onClick={() => handleProductsChange('')} edge="end">
                                                                        <ClearIcon />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            )
                                                        }
                                                    />

                                                </div>
                                                {/*<button*/}
                                                {/*    className={`btn ${isUpdating ? 'btn-primary' : 'btn-outline-primary'} fs-13 ${styles.filter_button}`}*/}
                                                {/*    // onClick={startUpdateFromKaspi}*/}
                                                {/*>*/}
                                                {/*    Обновить из Kaspi*/}
                                                {/*    /!*{" "}*!/*/}
                                                {/*    /!*<i className="ri-add-box-fill me-1 align-bottom"></i>*!/*/}
                                                {/*</button>*/}
                                                {metaData.meta.active_date && isWideScreen &&
                                                    <div className="text-success bg-light p-2 rounded-2 d-flex align-items-center" style={{lineHeight: 0.9, minHeight: '40px'}}>
                                                        <span>Подписка активна до: {formatDate(metaData.meta.active_date)}</span>
                                                    </div>
                                                }
                                                <button
                                                    style={!isWideScreen ? {lineHeight: 1} : {}}
                                                    className={`btn ${settings.bot_enabled ? 'btn-danger' : 'btn-success'} fs-13`}
                                                    onClick={toggleBot}
                                                >
                                                    {settings.bot_enabled ? 'Выключить бот' : 'Включить бот'}
                                                </button>

                                            </div>

                                            {/*{products && products.length > 0 && (
                                                <div className="flex-shrink-0">
                                                    <div className="hstack text-nowrap gap-2">
                                                        {isMultiDeleteButton && hasPermissions(['owner']) && (
                                                            <button
                                                                style={{padding: "6px 15px"}}
                                                                className="btn btn-danger"
                                                                onClick={() => setDeleteModalMulti(true)}
                                                            >
                                                                <i className="ri-delete-bin-2-line"></i>
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            )}*/}
                                        </div>
                                        <Box mt={2} className="d-flex gap-3">
                                            <Filter metaData={metaData} handleChange={handleFilterChange}/>
                                            {/*<Button className="btn-sm" style={{lineHeight: 1}} color="primary" onClick={toggleMassPriceChangeModal}>
                                                Массовое изменение цены
                                            </Button>
                                            <MassPriceChangeModal
                                                isOpen={isMassPriceChangeModalOpen}
                                                toggle={toggleMassPriceChangeModal}
                                                fetchProducts={fetchProducts}
                                                fetchParams={fetchParams}
                                            />*/}
                                            {products && products.length > 0 && (
                                                <div className="flex-shrink-0">
                                                    <div className="hstack text-nowrap gap-2">
                                                        {isMultiDeleteButton && hasPermissions(['owner']) && (
                                                            <button
                                                                style={{padding: "6px 15px"}}
                                                                className="btn btn-danger"
                                                                onClick={() => setDeleteModalMulti(true)}
                                                            >
                                                                <i className="ri-delete-bin-2-line"></i>
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </Box>
                                    </CardHeader>
                                </Card>


                                {importing &&
                                    // importLoading ? (
                                    //   <div className={styles.loader}>
                                    //     <p>Loading...</p>
                                    //   </div>
                                    // ) :
                                    (
                                        <section className={styles.filter_section}>
                                            <h2>Загрузить прайс-лист</h2>
                                            <div className={`${styles.filter_element} ${styles.only}`}>
                                                <Label
                                                    htmlFor="product-field"
                                                    className={`form-label ${styles.filter_label}`}
                                                >
                                                    Прайс-лист
                                                </Label>
                                                <ImportForm
                                                    handleImportProducts={handleImportProducts}
                                                    errors={errors}
                                                    setErrors={setErrors}
                                                    disabled={importLoading}
                                                />
                                            </div>

                                        </section>
                                    )
                                }
                            </Col>

                            <Col xxl={9} style={{width: "100%"}}>
                                <Card id="contactList">
                                    <CardBody className="pt-0">
                                        <div>
                                            {
                                                loading ? ( // Show loader only when loading
                                                        // <Loader isUpdating={isUpdating} setIsUpdating={setIsUpdating} />
                                                        <Loader />
                                                    ) :
                                                    // products ? (
                                                    (
                                                        (filteredProducts && filteredProducts.length > 0) ? (
                                                            <TableContainer
                                                                columns={columns}
                                                                data={filteredProducts}
                                                                // isGlobalFilter={true}
                                                                // isAddUserList={true}
                                                                customPageSize={20}

                                                                serverCount={paginationOptions?.count}
                                                                serverNext={paginationOptions?.next}
                                                                serverPrevious={paginationOptions?.previous}
                                                                onPageChange={onPageChange}
                                                                currentPage={currentPage}
                                                                setCurrentPage={setCurrentPage}

                                                                className="custom-header-css"
                                                                divClass="table-responsive table-card mb-3"
                                                                tableClass={`align-middle table-nowrap ${styles.table_with_checkbox}`}
                                                                theadClass="table-light"
                                                                handleContactClick={handleContactClicks}
                                                                SearchPlaceholder="Поиск..."
                                                            />
                                                        ) : (
                                                            <div className="text-center mt-4">
                                                            <h5
                                                                style={{ lineHeight: "1.6", color: "grey" }}
                                                            >
                                                                <div>Товаров не найдено...</div>
                                                            </h5>
                                                                {(filters === 'all' && !searchTerm) &&
                                                                    <span style={{fontSize: '15px', color: "grey"}}>
                                                                        Включите интеграцию чтобы товары начали появляться либо добавляйте их самостоятельно.
                                                                    </span>
                                                                }
                                                            </div>
                                                        )
                                                    )}
                                                {/*: (
                                                        // <Loader isUpdating={isUpdating} setIsUpdating={setIsUpdating} />
                                                        <Loader />
                                                    )}*/}
                                        </div>

                                        <Modal id="showModal" isOpen={modal} toggle={toggle} centered>
                                            <ModalHeader className="bg-info-subtle p-3" toggle={toggle}>
                                                {!!isEdit ? contact?.model : "Добавить товар"}
                                            </ModalHeader>

                                            <Form
                                                className="tablelist-form"
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    validation.handleSubmit();
                                                    return false;
                                                }}
                                            >
                                                <ModalBody>
                                                    <Input type="hidden" id="id-field" />
                                                    <Row className="g-3">
                                                        {/*<Col lg={12}>*/}
                                                        {/*    <div>*/}
                                                        {/*        <Label htmlFor="product-field" className="form-label">*/}
                                                        {/*            Товар*/}
                                                        {/*        </Label>*/}
                                                        {/*        <Select*/}
                                                        {/*            placeholder="Поиск..."*/}
                                                        {/*            name="product"*/}
                                                        {/*            id="product-field"*/}
                                                        {/*            options={options.slice(0, 5)}*/}
                                                        {/*            onChange={(selectedOption) => {*/}
                                                        {/*                validation.setFieldValue('product', selectedOption?.value);*/}
                                                        {/*            }}*/}
                                                        {/*            onBlur={validation.handleBlur('product')}*/}
                                                        {/*            value={options.find((option) => option.value === validation.values.product) || null}*/}
                                                        {/*            isClearable*/}
                                                        {/*            isSearchable*/}
                                                        {/*        />*/}
                                                        {/*        {validation.touched.product && validation.errors.product ? (*/}
                                                        {/*            <FormFeedback type="invalid">*/}
                                                        {/*                {validation.errors.product}*/}
                                                        {/*            </FormFeedback>*/}
                                                        {/*        ) : null}*/}
                                                        {/*    </div>*/}
                                                        {/*</Col>*/}
                                                        <Col lg={12} className="mt-1">
                                                            <div className="text-center">
                                                                <div className="position-relative d-inline-block">
                                                                    {/*<div className="position-absolute  bottom-0 end-0">
                                                                        <Label
                                                                            htmlFor="customer-image-input"
                                                                            className="mb-0"
                                                                        >
                                                                            <div className="avatar-xs cursor-pointer">
                                                                                <div className="avatar-title bg-light border rounded-circle text-muted">
                                                                                    <i className="ri-image-fill"></i>
                                                                                </div>
                                                                            </div>
                                                                        </Label>
                                                                        <Input
                                                                            className="form-control d-none"
                                                                            id="customer-image-input"
                                                                            type="file"
                                                                            name="image"
                                                                            accept="image/png, image/jpeg, image/webp"
                                                                            onChange={(event) => {
                                                                                const file = event.target.files[0];
                                                                                if (file) {
                                                                                    const fileSize = file.size; // Size in bytes

                                                                                    if (fileSize > maxSize) {
                                                                                        setImageValidationError('Размер фото превышает 1 MB')
                                                                                    } else {
                                                                                        setImageValidationError(null)
                                                                                        setSelectedFile(file);
                                                                                        const imageUrl = URL.createObjectURL(file);
                                                                                        setSelectedImage(imageUrl);
                                                                                    }

                                                                                } else {
                                                                                    setSelectedFile(null);
                                                                                    setSelectedImage("");
                                                                                }
                                                                                validation.handleChange(event);
                                                                            }}
                                                                            onBlur={validation.handleBlur}
                                                                            // value={validation.values.image || ""}
                                                                            value={""}
                                                                            invalid={
                                                                                validation.touched.image &&
                                                                                validation.errors.image
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                        />
                                                                    </div>*/}
                                                                    <div className="avatar-lg p-1">
                                                                        <div className="avatar-title bg-light text-primary fs-12 lh-1">
                                                                            {" "}
                                                                            {/*rounded-circle */}
                                                                            {selectedImage ? (
                                                                                <img
                                                                                    src={selectedImage}
                                                                                    alt="картинка товара"
                                                                                    id="customer-img"
                                                                                    className="avatar-md object-fit-cover"
                                                                                /> /*rounded-circle*/
                                                                            ) : (
                                                                                "выберите картинку товара"
                                                                            )}
                                                                        </div>
                                                                        {imageValidationError && <p className="mt-2" style={{ color: '#ed5e5e', fontSize: '0.875em' }}>{imageValidationError}</p>}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div>
                                                                <Label
                                                                    htmlFor="title-field"
                                                                    className="form-label"
                                                                >
                                                                    Название Товара
                                                                </Label>

                                                                <Input
                                                                    disabled={!integration.allowed || (integration.allowed && integration.enabled)}
                                                                    name="model"
                                                                    id="title-field"
                                                                    type="text"
                                                                    className="form-control"
                                                                    // placeholder="Введите название..."
                                                                    validate={{
                                                                        required: { value: true },
                                                                    }}
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.model || ""}
                                                                    invalid={
                                                                        validation.touched.model &&
                                                                        validation.errors.model
                                                                            ? true
                                                                            : !!errors?.model
                                                                    }
                                                                />
                                                                {validation.touched.model &&
                                                                validation.errors.model ? (
                                                                    <FormFeedback type="invalid">
                                                                        {validation.errors.model}
                                                                    </FormFeedback>
                                                                ) : errors && errors?.model ? (
                                                                    <FormFeedback type="invalid">
                                                                        <div>{errors.model}</div>
                                                                    </FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                        <Col lg={12} className="mt-1">
                                                            <div>
                                                                <Label
                                                                    htmlFor="sku-field"
                                                                    className="form-label"
                                                                >
                                                                    Артикул
                                                                </Label>

                                                                <Input
                                                                    disabled={!integration.allowed || (integration.allowed && integration.enabled)}
                                                                    name="sku"
                                                                    id="sku-field"
                                                                    type="text"
                                                                    className="form-control"
                                                                    // placeholder="Введите артикул..."
                                                                    validate={{
                                                                        required: { value: true },
                                                                    }}
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.sku || ""}
                                                                    invalid={
                                                                        validation.touched.sku &&
                                                                        validation.errors.sku
                                                                            ? true
                                                                            : !!errors?.sku
                                                                    }
                                                                />
                                                                {validation.touched.sku &&
                                                                validation.errors.sku ? (
                                                                    <FormFeedback type="invalid">
                                                                        {validation.errors.sku}
                                                                    </FormFeedback>
                                                                ) : errors && errors?.sku ? (
                                                                    <FormFeedback type="invalid">
                                                                        <div>{errors.sku}</div>
                                                                    </FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                        {/*<Col lg={12}>
                                                            <div>
                                                                <Label
                                                                    htmlFor="description-field"
                                                                    className="form-label"
                                                                >
                                                                    Описание
                                                                </Label>

                                                                <Input
                                                                    name="description"
                                                                    id="description-field"
                                                                    type="textarea"
                                                                    className="form-control"
                                                                    placeholder="Введите описание для товара..."
                                                                    validate={{
                                                                        required: { value: true },
                                                                    }}
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.description || ""}
                                                                    invalid={
                                                                        validation.touched.description &&
                                                                        validation.errors.description
                                                                            ? true
                                                                            : !!errors?.description
                                                                    }
                                                                />
                                                                {validation.touched.description &&
                                                                validation.errors.description ? (
                                                                    <FormFeedback type="invalid">
                                                                        {validation.errors.description}
                                                                    </FormFeedback>
                                                                ) : errors && errors?.description ? (
                                                                    <FormFeedback type="invalid">
                                                                        <div>{errors.description}</div>
                                                                    </FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Col>*/}
                                                        <Col lg={12} className="mt-1">
                                                            <div>
                                                                <Label
                                                                    htmlFor="price-field"
                                                                    className="form-label"
                                                                >
                                                                    Цена продажи
                                                                </Label>

                                                                <Input
                                                                    disabled={!integration.allowed || (integration.allowed && integration.enabled)}
                                                                    name="price"
                                                                    id="price-field"
                                                                    type="number"
                                                                    className="form-control"
                                                                    // placeholder="Введите стоимость..."
                                                                    validate={{
                                                                        required: { value: true },
                                                                    }}
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.price || ""}
                                                                    invalid={
                                                                        validation.touched.price &&
                                                                        validation.errors.price
                                                                            ? true
                                                                            : !!errors?.price
                                                                    }
                                                                />
                                                                {validation.touched.price &&
                                                                validation.errors.price ? (
                                                                    <FormFeedback type="invalid">
                                                                        {validation.errors.price}
                                                                    </FormFeedback>
                                                                ) : errors && errors?.price ? (
                                                                    <FormFeedback type="invalid">
                                                                        <div>{errors.price}</div>
                                                                    </FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                        <Col lg={12} className="mt-1">
                                                            <div>
                                                                <Label
                                                                    htmlFor="min_price-field"
                                                                    className="form-label"
                                                                >
                                                                    Минимальная цена
                                                                </Label>

                                                                <Input
                                                                    disabled={!integration.allowed || (integration.allowed && integration.enabled)}
                                                                    name="min_price"
                                                                    id="min_price-field"
                                                                    type="number"
                                                                    className="form-control"
                                                                    // placeholder="Введите минимальную цену..."
                                                                    validate={{
                                                                        required: { value: true },
                                                                    }}
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.min_price || ""}
                                                                    invalid={
                                                                        validation.touched.min_price &&
                                                                        validation.errors.min_price
                                                                            ? true
                                                                            : !!errors?.min_price
                                                                    }
                                                                />
                                                                {validation.touched.min_price &&
                                                                validation.errors.min_price ? (
                                                                    <FormFeedback type="invalid">
                                                                        {validation.errors.min_price}
                                                                    </FormFeedback>
                                                                ) : errors && errors?.min_price ? (
                                                                    <FormFeedback type="invalid">
                                                                        <div>{errors.min_price}</div>
                                                                    </FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                        <Col lg={12} className="mt-1">
                                                            <div>
                                                                <Label
                                                                    htmlFor="max_price-field"
                                                                    className="form-label"
                                                                >
                                                                    Максимальная цена
                                                                </Label>

                                                                <Input
                                                                    disabled={!integration.allowed || (integration.allowed && integration.enabled)}
                                                                    name="max_price"
                                                                    id="max_price-field"
                                                                    type="number"
                                                                    className="form-control"
                                                                    // placeholder="Введите максимальную цену..."
                                                                    validate={{
                                                                        required: { value: true },
                                                                    }}
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.max_price || ""}
                                                                    invalid={
                                                                        validation.touched.max_price &&
                                                                        validation.errors.max_price
                                                                            ? true
                                                                            : !!errors?.max_price
                                                                    }
                                                                />
                                                                {validation.touched.max_price &&
                                                                validation.errors.max_price ? (
                                                                    <FormFeedback type="invalid">
                                                                        {validation.errors.max_price}
                                                                    </FormFeedback>
                                                                ) : errors && errors?.max_price ? (
                                                                    <FormFeedback type="invalid">
                                                                        <div>{errors.max_price}</div>
                                                                    </FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                        <Col lg={12} className="mt-1">
                                                            <div>
                                                                <Label
                                                                    htmlFor="kaspi_code-field"
                                                                    className="form-label"
                                                                >
                                                                    Kaspi код
                                                                </Label>

                                                                <Input
                                                                    disabled={!integration.allowed || (integration.allowed && integration.enabled)}
                                                                    name="kaspi_code"
                                                                    id="kaspi_code-field"
                                                                    type="number"
                                                                    className="form-control"
                                                                    // placeholder="Введите Kaspi код..."
                                                                    validate={{
                                                                        required: { value: true },
                                                                    }}
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.kaspi_code || ""}
                                                                    invalid={
                                                                        validation.touched.kaspi_code &&
                                                                        validation.errors.kaspi_code
                                                                            ? true
                                                                            : !!errors?.kaspi_code
                                                                    }
                                                                />
                                                                {validation.touched.kaspi_code &&
                                                                validation.errors.kaspi_code ? (
                                                                    <FormFeedback type="invalid">
                                                                        {validation.errors.kaspi_code}
                                                                    </FormFeedback>
                                                                ) : errors && errors?.kaspi_code ? (
                                                                    <FormFeedback type="invalid">
                                                                        <div>{errors.kaspi_code}</div>
                                                                    </FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                        <Col lg={12} className="mt-1">
                                                            <div>
                                                                <Label
                                                                    htmlFor="no_competitor_price-field"
                                                                    className="form-label"
                                                                >
                                                                    Неконкурентная цена
                                                                </Label>

                                                                <Input
                                                                    disabled={!integration.allowed || (integration.allowed && integration.enabled)}
                                                                    name="no_competitor_price"
                                                                    id="no_competitor_price-field"
                                                                    type="number"
                                                                    className="form-control"
                                                                    // placeholder="Введите вашу цену когда нет конкурентов..."
                                                                    validate={{
                                                                        required: { value: true },
                                                                    }}
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.no_competitor_price || ""}
                                                                    invalid={
                                                                        validation.touched.no_competitor_price &&
                                                                        validation.errors.no_competitor_price
                                                                            ? true
                                                                            : !!errors?.no_competitor_price
                                                                    }
                                                                />
                                                                {validation.touched.no_competitor_price &&
                                                                validation.errors.no_competitor_price ? (
                                                                    <FormFeedback type="invalid">
                                                                        {validation.errors.no_competitor_price}
                                                                    </FormFeedback>
                                                                ) : errors && errors?.no_competitor_price ? (
                                                                    <FormFeedback type="invalid">
                                                                        <div>{errors.no_competitor_price}</div>
                                                                    </FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                        <Col lg={12} className="mt-1">
                                                            <div>
                                                                <Label
                                                                    htmlFor="preorder-field"
                                                                    className="form-label"
                                                                >
                                                                    Предзаказ дней
                                                                </Label>

                                                                <Input
                                                                    disabled={!integration.allowed || (integration.allowed && integration.enabled)}
                                                                    // style={{border: 0}}
                                                                    name="preorder"
                                                                    id="preorder-field"
                                                                    type="number"
                                                                    className="form-control"
                                                                    // placeholder="Введите количество дней если это предзаказ..."
                                                                    validate={{
                                                                        required: { value: true },
                                                                    }}
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.preorder || ""}
                                                                    invalid={
                                                                        validation.touched.preorder &&
                                                                        validation.errors.preorder
                                                                            ? true
                                                                            : !!errors?.preorder
                                                                    }
                                                                />
                                                                {validation.touched.preorder &&
                                                                validation.errors.preorder ? (
                                                                    <FormFeedback type="invalid">
                                                                        {validation.errors.preorder}
                                                                    </FormFeedback>
                                                                ) : errors && errors?.preorder ? (
                                                                    <FormFeedback type="invalid">
                                                                        <div>{errors.preorder}</div>
                                                                    </FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                        {/*<Col lg={12}>*/}
                                                        {/*  <div>*/}
                                                        {/*    <Label*/}
                                                        {/*      htmlFor="taginput-choices"*/}
                                                        {/*      className="form-label font-size-13 text-muted"*/}
                                                        {/*    >*/}
                                                        {/*      Tags*/}
                                                        {/*    </Label>*/}
                                                        {/*    <Select*/}
                                                        {/*      isMulti*/}
                                                        {/*      value={tag}*/}
                                                        {/*      onChange={(e) => {*/}
                                                        {/*        handlestag(e);*/}
                                                        {/*      }}*/}
                                                        {/*      className="mb-0"*/}
                                                        {/*      options={tags}*/}
                                                        {/*      id="taginput-choices"*/}
                                                        {/*    ></Select>*/}

                                                        {/*    {validation.touched.tags &&*/}
                                                        {/*    validation.errors.tags ? (*/}
                                                        {/*      <FormFeedback type="invalid">*/}
                                                        {/*        {validation.errors.tags}*/}
                                                        {/*      </FormFeedback>*/}
                                                        {/*    ) : null}*/}
                                                        {/*  </div>*/}
                                                        {/*</Col>*/}
                                                    </Row>
                                                </ModalBody>
                                                <ModalFooter className="hstack gap-2 justify-content-between">
                                                    <div className="d-flex gap-2 align-items-baseline">
                                                        <Label
                                                            htmlFor="bot_enabled-field"
                                                            className="form-label"
                                                        >
                                                            Бот включен
                                                        </Label>

                                                        {/*<div className="badge badge-success">ДА</div>*/}

                                                        {/*<CircleIcon*/}
                                                        {/*    style={{*/}
                                                        {/*        // height: '14px',*/}
                                                        {/*        // transform: 'translateY(15px)',*/}
                                                        {/*        color: validation.values.bot_enabled ? 'limegreen' : 'red'*/}
                                                        {/*    }}*/}
                                                        {/*/>*/}

                                                        <Switch
                                                            name="bot_enabled"
                                                            id="bot_enabled-field"
                                                            className="form-control"
                                                            disabled={!integration.allowed || (integration.allowed && integration.enabled)}
                                                            checked={validation.values.bot_enabled || false}
                                                            onChange={(e) => {
                                                                validation.handleChange({
                                                                    target: {
                                                                        name: 'bot_enabled',
                                                                        value: e.target.checked,
                                                                    },
                                                                });
                                                            }}
                                                            onBlur={validation.handleBlur}
                                                            invalid={
                                                                validation.touched.bot_enabled && validation.errors.bot_enabled
                                                                    ? true
                                                                    : !!errors?.bot_enabled
                                                            }
                                                        />
                                                        {validation.touched.bot_enabled && validation.errors.bot_enabled ? (
                                                            <FormFeedback type="invalid">
                                                                {validation.errors.bot_enabled}
                                                            </FormFeedback>
                                                        ) : errors && errors?.bot_enabled ? (
                                                            <FormFeedback type="invalid">
                                                                <div>{errors.bot_enabled}</div>
                                                            </FormFeedback>
                                                        ) : null}
                                                    </div>

                                                    <div className="hstack gap-2 justify-content-end">
                                                        <button
                                                            type="button"
                                                            className="btn btn-light"
                                                            onClick={() => {
                                                                setModal(false);
                                                            }}
                                                        >
                                                            {" "}
                                                            Закрыть{" "}
                                                        </button>
                                                        {(integration.allowed && !integration.enabled) &&
                                                            <button
                                                                type="submit"
                                                                className="btn btn-success"
                                                                id="add-btn"
                                                            >
                                                                {" "}
                                                                {!!isEdit ? "Сохранить" : "Создать"}{" "}
                                                            </button>
                                                        }
                                                    </div>
                                                </ModalFooter>
                                            </Form>
                                        </Modal>
                                        {/* task modal */}
                                        <Modal
                                            isOpen={modalTask}
                                            toggle={toggleTask}
                                            centered
                                            size="lg"
                                            className="border-0"
                                            modalClassName="modal fade zoomIn"
                                        >
                                            <ModalHeader
                                                className="p-3 bg-info-subtle"
                                                toggle={toggleTask}
                                            >
                                                {!!isEdited ? "Edit Task" : "Create Task"}
                                            </ModalHeader>
                                            <Form
                                                className="tablelist-form"
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    valid.handleSubmit();
                                                    return false;
                                                }}
                                            >
                                                <ModalBody className="modal-body">
                                                    <Row className="g-3">
                                                        <Col lg={12}>
                                                            <Label for="taskId-field" className="form-label">
                                                                Order Id
                                                            </Label>
                                                            <Input
                                                                name="taskId"
                                                                id="taskId-field"
                                                                className="form-control"
                                                                placeholder="Enter Task Id "
                                                                type="text"
                                                                validate={{
                                                                    required: { value: true },
                                                                }}
                                                                onChange={valid.handleChange}
                                                                onBlur={valid.handleBlur}
                                                                value={valid.values.taskId || ""}
                                                                invalid={
                                                                    valid.touched.taskId && valid.errors.taskId
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                            {valid.touched.taskId && valid.errors.taskId ? (
                                                                <FormFeedback type="invalid">
                                                                    {valid.errors.taskId}
                                                                </FormFeedback>
                                                            ) : null}
                                                        </Col>

                                                        <Col lg={12}>
                                                            <Label
                                                                for="projectName-field"
                                                                className="form-label"
                                                            >
                                                                Project Name
                                                            </Label>
                                                            <Input
                                                                name="project"
                                                                id="projectName-field"
                                                                className="form-control"
                                                                placeholder="Project name"
                                                                type="text"
                                                                validate={{
                                                                    required: { value: true },
                                                                }}
                                                                onChange={valid.handleChange}
                                                                onBlur={valid.handleBlur}
                                                                value={valid.values.project || ""}
                                                                invalid={
                                                                    valid.touched.project && valid.errors.project
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                            {valid.touched.project && valid.errors.project ? (
                                                                <FormFeedback type="invalid">
                                                                    {valid.errors.project}
                                                                </FormFeedback>
                                                            ) : null}
                                                        </Col>
                                                        <Col lg={12}>
                                                            <div>
                                                                <Label
                                                                    for="tasksTitle-field"
                                                                    className="form-label"
                                                                >
                                                                    Title
                                                                </Label>
                                                                <Input
                                                                    name="task"
                                                                    id="tasksTitle-field"
                                                                    className="form-control"
                                                                    placeholder="Title"
                                                                    type="text"
                                                                    validate={{
                                                                        required: { value: true },
                                                                    }}
                                                                    onChange={valid.handleChange}
                                                                    onBlur={valid.handleBlur}
                                                                    value={valid.values.task || ""}
                                                                    invalid={
                                                                        valid.touched.task && valid.errors.task
                                                                            ? true
                                                                            : false
                                                                    }
                                                                />
                                                                {valid.touched.task && valid.errors.task ? (
                                                                    <FormFeedback type="invalid">
                                                                        {valid.errors.task}
                                                                    </FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                        <Col lg={12}>
                                                            <Label
                                                                for="clientName-field"
                                                                className="form-label"
                                                            >
                                                                Client Name
                                                            </Label>
                                                            <Input
                                                                name="creater"
                                                                id="clientName-field"
                                                                className="form-control"
                                                                placeholder="Client name"
                                                                type="text"
                                                                validate={{
                                                                    required: { value: true },
                                                                }}
                                                                onChange={valid.handleChange}
                                                                onBlur={valid.handleBlur}
                                                                value={valid.values.creater || ""}
                                                                invalid={
                                                                    valid.touched.creater && valid.errors.creater
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                            {valid.touched.creater && valid.errors.creater ? (
                                                                <FormFeedback type="invalid">
                                                                    {valid.errors.creater}
                                                                </FormFeedback>
                                                            ) : null}
                                                        </Col>

                                                        <Col lg={6}>
                                                            <Label for="duedate-field" className="form-label">
                                                                Due Date
                                                            </Label>

                                                            <Flatpickr
                                                                name="dueDate"
                                                                id="duedate-field"
                                                                className="form-control"
                                                                placeholder="Select a date"
                                                                options={{
                                                                    altInput: true,
                                                                    altFormat: "d M, Y",
                                                                    dateFormat: "d M, Y",
                                                                }}
                                                                onChange={(e) => dateformate(e)}
                                                                value={valid.values.dueDate || ""}
                                                            />
                                                            {valid.touched.dueDate && valid.errors.dueDate ? (
                                                                <FormFeedback type="invalid">
                                                                    {valid.errors.dueDate}
                                                                </FormFeedback>
                                                            ) : null}
                                                        </Col>
                                                        <Col lg={6}>
                                                            <Label for="ticket-status" className="form-label">
                                                                Status
                                                            </Label>
                                                            <Input
                                                                name="status"
                                                                type="select"
                                                                className="form-select"
                                                                id="ticket-field"
                                                                onChange={valid.handleChange}
                                                                onBlur={valid.handleBlur}
                                                                value={valid.values.status || ""}
                                                            >
                                                                <option value="">Status</option>
                                                                <option value="New">New</option>
                                                                <option value="Inprogress">Inprogress</option>
                                                                <option value="Pending">Pending</option>
                                                                <option value="Completed">Completed</option>
                                                            </Input>
                                                            {valid.touched.status && valid.errors.status ? (
                                                                <FormFeedback type="invalid">
                                                                    {valid.errors.status}
                                                                </FormFeedback>
                                                            ) : null}
                                                        </Col>
                                                        <Col lg={12}>
                                                            <Label for="priority-field" className="form-label">
                                                                Priority
                                                            </Label>
                                                            <Input
                                                                name="priority"
                                                                type="select"
                                                                className="form-select"
                                                                id="priority-field"
                                                                onChange={valid.handleChange}
                                                                onBlur={valid.handleBlur}
                                                                value={valid.values.priority || ""}
                                                            >
                                                                <option value="">Priority</option>
                                                                <option value="High">High</option>
                                                                <option value="Medium">Medium</option>
                                                                <option value="Low">Low</option>
                                                            </Input>
                                                            {valid.touched.priority && valid.errors.priority ? (
                                                                <FormFeedback type="invalid">
                                                                    {valid.errors.priority}
                                                                </FormFeedback>
                                                            ) : null}
                                                        </Col>
                                                    </Row>
                                                </ModalBody>
                                                <div className="modal-footer">
                                                    <div className="hstack gap-2 justify-content-end">
                                                        <Button
                                                            type="button"
                                                            onClick={() => {
                                                                setModalTask(false);
                                                            }}
                                                            className="btn-light"
                                                        >
                                                            Закрыть
                                                        </Button>
                                                        <button
                                                            type="submit"
                                                            className="btn btn-success"
                                                            id="add-btn"
                                                        >
                                                            {!!isEdit ? "Update Task" : "Add Task"}
                                                        </button>
                                                    </div>
                                                </div>
                                            </Form>
                                        </Modal>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        ) : (
                            <h3 className="text-center my-5" style={{opacity: 0.3}}>Не удалось авторизовать пользователя</h3>
                    ) :
                        <h3 className="text-center my-5" style={{opacity: 0.3}}>У вас нет прав для просмотра данной страницы</h3>
                    }
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Pricelist;
