import React from 'react';

import yesImage from './../../../assets/images/yes.png';
import noImage from './../../../assets/images/no2.png';

function ValidityIndicator({ isValid }) {
  return (
    <div style={{position: 'absolute', top: '44%'}}>
      {isValid ? 'Да': 'Нет'}&nbsp;&nbsp;
      <div style={{width: '20px', height: '20px', display: 'inline-block'}}>
        {isValid ? (
          <img src={yesImage} alt="True" style={{width: '100%'}} />
        ) : (
          <img src={noImage} alt="False" style={{width: '100%'}} />
        )}
      </div>
    </div>
  );
}

export default ValidityIndicator;
