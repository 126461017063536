import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input, Spinner } from 'reactstrap';
import { toast } from 'react-toastify';
import {massPriceChangeApi, updateBotPricelistApi} from '../../../helpers/backend_helper'; // Adjust the path as needed

const MassPriceChangeModal = ({ isOpen, toggle, fetchProducts, fetchParams }) => {
    const [percent, setPercent] = useState(1);
    const [existing, setExisting] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setError('');

        const percentValue = parseInt(percent, 10);

        if (isNaN(percentValue) || percentValue < 1 || percentValue > 20) {
            setError('Процент должен быть от 1 до 20');
            setLoading(false);
            return;
        }

        massPriceChangeApi({
            percent: percentValue,
            existing: existing
        })
            .then((response) => {
                toggle();
                toast.success('Цены были успешно изменены');
                fetchProducts(fetchParams);
            })
            .catch(() => {
                toast.error('Не удалось выполнить операцию');
            })
            .finally(() => setLoading(false))

    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} centered>
            <ModalHeader toggle={toggle}>Массовые изменения цен</ModalHeader>
            <Form onSubmit={handleSubmit}>
                <ModalBody>
                    <FormGroup>
                        <Label for="percentInput">Выберите % снижения</Label>
                        <div className="d-flex align-items-center">
                            <Input
                                type="number"
                                id="percentInput"
                                min={1}
                                max={20}
                                value={percent}
                                onChange={(e) => setPercent(e.target.value)}
                                required
                                style={{ width: '80px' }}
                            />
                            <span className="ms-2">%</span>
                        </div>
                        {error && <small className="text-danger">{error}</small>}
                        <small className="form-text text-muted">
                            *Например: Если цена товара сейчас 10,000тг то -10% = 9000тг это будет ограничение цены. Ниже этой цены Бот не сможет поставить
                        </small>
                    </FormGroup>
                    <FormGroup check>
                        <Label check>
                            <Input
                                type="checkbox"
                                checked={existing}
                                onChange={(e) => setExisting(e.target.checked)}
                            />
                            Применить для товаров, где уже есть мин. цена
                        </Label>
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={toggle} disabled={loading}>
                        Закрыть
                    </Button>
                    <Button type="submit" color="success" disabled={loading}>
                        {loading ? <Spinner size="sm" /> : 'Применить'}
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>
    );
};

export default MassPriceChangeModal;
