import React, { useState, useEffect } from 'react';
import { Button, Form, FormGroup, Label, Input, Row, Col } from 'reactstrap';
import { updateBotSettingsApi, getBotSettingsApi } from "../../../helpers/backend_helper";
import { toast } from "react-toastify";

const ProfileSettings = ({ settings, setSettings, cities, setCities }) => {

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSettings((prevSettings) => ({
            ...prevSettings,
            [name]: value,
        }));
    };

    const handleSubmit = () => {
        updateBotSettingsApi(settings)
            .then(data => {
                // fetchSettings();
                setSettings(data);
                setCities(data.cities);
                toast.success('Настройки успешно обновлены!');
            })
            .catch((err) => {
                toast.error('Не удалось обновить настройки');
            });
    };

    return (
        <div className="row">
            <div className="col-lg-12">
                {/*<div className="card">*/}
                    <div className="card-body">
                        {/*<h4 className="card-title mb-3">Настройки профиля</h4>*/}
                        {settings ?
                            (
                                <Form>
                                    <Row>
                                        <Col lg={6}>
                                            <FormGroup>
                                                <Label for="username">Username</Label>
                                                <Input
                                                    type="text"
                                                    id="username"
                                                    name="username"
                                                    value={settings.username}
                                                    onChange={handleChange}
                                                    placeholder="Username..."
                                                    required
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg={6}>
                                            <FormGroup>
                                                <Label for="email">Email</Label>
                                                <Input
                                                    type="email"
                                                    id="email"
                                                    name="email"
                                                    value={settings.email}
                                                    onChange={handleChange}
                                                    placeholder="Email..."
                                                    readOnly
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    {/*<Row>
                                <Col lg={6}>
                                    <FormGroup>
                                        <Label for="password">Пароль</Label>
                                        <Input
                                            type="password"
                                            id="password"
                                            name="password"
                                            value={settings.password}
                                            onChange={handleChange}
                                            placeholder="Пароль..."
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>*/}
                                    <Row>
                                        {/*<Col lg={4}>
                                            <FormGroup>
                                                <Label for="kaspi_login">Kaspi Логин</Label>
                                                <Input
                                                    type="text"
                                                    id="kaspi_login"
                                                    name="kaspi_login"
                                                    value={settings.kaspi_login}
                                                    onChange={handleChange}
                                                    placeholder="Kaspi Логин..."
                                                />
                                            </FormGroup>
                                        </Col>*/}
                                        {/*<Col lg={4}>
                                            <FormGroup>
                                                <Label for="kaspi_password">Kaspi Пароль</Label>
                                                <Input
                                                    type="password"
                                                    id="kaspi_password"
                                                    name="kaspi_password"
                                                    value={settings.kaspi_password}
                                                    onChange={handleChange}
                                                    placeholder="Kaspi Пароль..."
                                                />
                                            </FormGroup>
                                        </Col>*/}
                                        {/*<Col lg={4}>
                                            <FormGroup>
                                                <Label for="kaspi_company">Название компании (В Kaspi)</Label>
                                                <Input
                                                    type="text"
                                                    id="kaspi_company"
                                                    name="kaspi_company"
                                                    value={settings.kaspi_company}
                                                    onChange={handleChange}
                                                    placeholder="Kaspi Компания..."
                                                />
                                            </FormGroup>
                                        </Col>*/}
                                    </Row>
                                    <Row>
                                        {/*<Col lg={4}>
                                            <FormGroup>
                                                <Label for="kaspi_merchant_id">ID магазина (В Kaspi)</Label>
                                                <Input
                                                    type="text"
                                                    id="kaspi_merchant_id"
                                                    name="kaspi_merchant_id"
                                                    value={settings.kaspi_merchant_id}
                                                    onChange={handleChange}
                                                    placeholder="Kaspi ID..."
                                                />
                                            </FormGroup>
                                        </Col>*/}
                                        <Col lg={6}>
                                            <FormGroup>
                                                <Label for="skip_merchant_id">Пропуск ID магазинов</Label>
                                                <Input
                                                    type="text"
                                                    id="skip_merchant_id"
                                                    name="skip_merchant_id"
                                                    value={settings.skip_merchant_id}
                                                    onChange={handleChange}
                                                    placeholder='Указывать через ",". Пример: ID1,ID2,ID3,...'
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg={2}>
                                            {/*<FormGroup className="mb-0">*/}
                                            <Label for="price_change_step">Шаг цены</Label>
                                            <Input
                                                type="number"
                                                id="price_change_step"
                                                name="price_change_step"
                                                min="0"
                                                max="500"
                                                value={settings.price_change_step}
                                                onChange={handleChange}
                                                placeholder="Шаг цены"
                                            />
                                            {/*</FormGroup>*/}
                                        </Col>
                                        <Col lg={4}>
                                            {/*<FormGroup className="mb-0">*/}
                                            <Label for="city">Город</Label>
                                            <Input
                                                type="select"
                                                id="city"
                                                name="city"
                                                value={settings.city}
                                                onChange={handleChange}
                                                // style={{ padding: '5px' }}
                                            >
                                                {cities && cities.map((city) => (
                                                    <option key={city.id} value={city.id}>
                                                        {city.name} ({city.code})
                                                    </option>
                                                ))}
                                            </Input>
                                            {/*</FormGroup>*/}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12} className="d-flex align-self-end justify-content-end mt-3">
                                            <Button type="button" color="primary" onClick={handleSubmit}> {/* className="w-100"*/}
                                                Изменить
                                            </Button>
                                        </Col>
                                    </Row>
                                    {/*<Row className="justify-content-end">
                                <Col lg={10}>
                                    <Button type="submit" color="primary">
                                        Изменить
                                    </Button>
                                </Col>
                            </Row>*/}
                                </Form>
                            ) : (
                                <h3>Не удалось авторизовать пользователя</h3>
                            )
                        }

                    </div>
                {/*</div>*/}
            </div>
        </div>
    );
};

export default ProfileSettings;
