import React from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { Button } from "reactstrap";
import {createOrder, getStoragesApi} from "../../helpers/backend_helper";
import getCurrentDateTime from "../../slices/invoice/utils";


function CreateOrder({isReturns}) {
  const navigate = useNavigate();
  const handleCreateOrder = async () => {
    // Define the reception data you want to create
    const orderData = {
      ordered_date: getCurrentDateTime()
      // storage: 23
    };
    await getStoragesApi()
      .then((response) => {
          try {
              if (response?.length > 0) {
                  orderData.storage = response[0].id;
              }
          }catch (e) {
             // pass
          }
      })
      .catch((error) => {});

    createOrder(orderData)
    .then((response) => {
      // Extract the created reception's ID from the response
      const createdOrderId = response.id;

      // Redirect to the detail page with the created reception's ID
        if (isReturns) {
            navigate(`/returns/${createdOrderId}`);
        }else{
            navigate(`/orders/${createdOrderId}`);
        }

    })
    .catch((error) => {
      // ...
    });
  };

  return (
    <div>
      <Button
        style={{paddingLeft: '8px'}}
        onClick={handleCreateOrder}
        color={isReturns ? 'danger': 'primary'}
        className="add-btn"
        id="create-btn"
      >
          <i className="ri-add-line align-bottom me-1 fs-15"></i><span style={{fontSize: '15px', fontWeight: 'bold'}}>
          {isReturns ? 'Возврат': 'Заказ'}
      </span>
      </Button>{" "}
    </div>
  );
}

export default CreateOrder;
