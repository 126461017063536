import React, { useEffect } from "react";
import { Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback } from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// action
import {apiError, resetRegisterFlag} from "../../slices/thunks";
import {registerUser} from "../../slices/auth/login/utils";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link, useNavigate } from "react-router-dom";

//import images
import logoLight from "../../assets/images/logo-light.png";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { createSelector } from "reselect";
import {useState} from "react";
import {changeHTMLAttribute} from "../../slices/layouts/utils";
import {isAuthenticated} from "../../Routes/Auth/isAuthenticated";

const Register = () => {
    const history = useNavigate();
    const dispatch = useDispatch();

    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    // validate errors
    const [errors, setErrors] = useState({});

    const setErrorsState = (obj) => {
        setErrors(obj);
    };

    const setSuccessState = (bool) => {
        setSuccess(bool);
    };

    useEffect(() => {
        if (success){
            validation.resetForm();
        }
    }, [success]);

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            email: '',
            // first_name: '',
            // username: '',
            password: '',
            password_retype: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Обязательное поле"),
            // first_name: Yup.string().required("Please Enter Your Username"),
            password: Yup.string().required("Обязательное поле"),
            password_retype: Yup.string().required("Обязательное поле"),
            // password_retype: Yup.string().when("password", {
            //     is: val => (val && val.length > 0 ? true : false),
            //     then: Yup.string().oneOf(
            //         [Yup.ref("password")],
            //         "Confirm Password Isn't Match"
            //     )
            // })
        }),
        onSubmit: (values) => {
            setErrors({})
            dispatch(registerUser(values, setErrorsState, setSuccessState));
        }
    });

    // const selectLayoutState = (state) => state.Account;
    // const registerData = createSelector(
    //   selectLayoutState,
    //   (accountState) => ({
    //     registrationError: accountState.registrationError,
    //     success: accountState.success,
    //     error: accountState.error
    //   })
    // );

    // Inside your component
// const {
//     registrationError,
//     success,
//     error
// } = useSelector(registerData);

    // useEffect(() => {
    //     dispatch(apiError(""));
    // }, [dispatch]);

    // useEffect(() => {
    //     if (success) {
    //         setTimeout(() => history("/login"), 3000);
    //     }
    //
    //     // setTimeout(() => {
    //     //     dispatch(resetRegisterFlag());
    //     // }, 3000);
    //
    // }, [success]);

    document.title="CRMBEK: Регистрация";

    return (
        <React.Fragment>
            <ParticlesAuth>
                <div className="auth-page-content">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center mt-sm-5 mb-4 text-white-50">
                                    {/*<div>*/}
                                    {/*    <Link to="/" className="d-inline-block auth-logo">*/}
                                    {/*        <img src={logoLight} alt="" height="20" />*/}
                                    {/*    </Link>*/}
                                    {/*</div>*/}
                                    <h1>CRMBEK</h1>
                                    <p className="mt-3 fs-15 fw-medium" style={{color: 'grey'}}>Система для вашего бизнеса</p>
                                </div>
                            </Col>
                        </Row>

                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="mt-4">

                                    <CardBody className="p-4">
                                        <div className="text-center mt-2">
                                            <h5 className="text-primary">Создать Новый Аккаунт</h5>
                                            <p className="text-muted">Зарегистрируйте новый аккаунт чтобы продолжить.</p>
                                        </div>
                                        <div className="p-2 mt-4">
                                            <Form
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    validation.handleSubmit();
                                                    return false;
                                                }}
                                                className="needs-validation" action="#">
                                                {success && success ? (
                                                    <>
                                                        {/*{toast("Успех! Проверьте Вашу почту", { position: "top-right", hideProgressBar: false, className: 'bg-success text-white', progress: undefined, toastId: "" })}*/}
                                                        {/*<ToastContainer autoClose={2000} limit={1} />*/}
                                                        <Alert color="success">
                                                            Успех! Проверьте Вашу почту для подтверждения
                                                        </Alert>
                                                    </>
                                                ) : null}

                                                {errors && errors?.error ?  (
                                                    <Alert color="danger">
                                                        <div>
                                                            {errors.error}
                                                        </div>
                                                    </Alert>
                                                ) : null}

                                                <div className="mb-3">
                                                    <Label htmlFor="useremail" className="form-label">Email</Label>
                                                    <Input style={{fontFamily: "Montserrat"}}
                                                        id="email"
                                                        name="email"
                                                        className="form-control"
                                                        placeholder="Введите Email адрес"
                                                        type="email"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.email || ""}
                                                        invalid={
                                                            validation.touched.email && validation.errors.email ? true :
                                                                !!errors?.email
                                                        }
                                                    />

                                                    {validation.touched.email && validation.errors.email ? (
                                                        <FormFeedback type="invalid"><div>{validation.errors.email}</div></FormFeedback>
                                                    ) : errors && errors?.email ? (
                                                        <FormFeedback type="invalid"><div>Пользователь с такой електронной почтой уже существует</div></FormFeedback>
                                                    ) : null}

                                                </div>
                                                {/*<div className="mb-3">*/}
                                                {/*    <Label htmlFor="username" className="form-label">Username <span className="text-danger">*</span></Label>*/}
                                                {/*    <Input*/}
                                                {/*        name="first_name"*/}
                                                {/*        type="text"*/}
                                                {/*        placeholder="Enter username"*/}
                                                {/*        onChange={validation.handleChange}*/}
                                                {/*        onBlur={validation.handleBlur}*/}
                                                {/*        value={validation.values.first_name || ""}*/}
                                                {/*        invalid={*/}
                                                {/*            validation.touched.first_name && validation.errors.first_name ? true : false*/}
                                                {/*        }*/}
                                                {/*    />*/}
                                                {/*    {validation.touched.first_name && validation.errors.first_name ? (*/}
                                                {/*        <FormFeedback type="invalid"><div>{validation.errors.first_name}</div></FormFeedback>*/}
                                                {/*    ) : null}*/}
                                                {/*    */}
                                                {/*</div>*/}

                                                <div className="mb-2">
                                                    <Label htmlFor="userpassword" className="form-label">Пароль</Label>
                                                    <Input style={{fontFamily: "Montserrat"}}
                                                        name="password"
                                                        type="password"
                                                        placeholder="Введите Пароль"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.password || ""}
                                                        invalid={
                                                            validation.touched.password && validation.errors.password ? true :
                                                                !!errors?.password
                                                        }
                                                    />
                                                    {validation.touched.password && validation.errors.password ? (
                                                        <FormFeedback type="invalid"><div>{validation.errors.password}</div></FormFeedback>
                                                    ) : errors && errors?.password ? (
                                                        <FormFeedback type="invalid"><div>{errors.password}</div></FormFeedback>
                                                    ) : null}


                                                </div>

                                                <div className="mb-2">
                                                    <Label htmlFor="confirmPassword" className="form-label">Подтвердите пароль</Label>
                                                    <Input style={{fontFamily: "Montserrat"}}
                                                        name="password_retype"
                                                        type="password"
                                                        placeholder="Введте Пароль повторно"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.password_retype || ""}
                                                        invalid={
                                                            validation.touched.password_retype && validation.errors.password_retype ? true : false
                                                        }
                                                    />
                                                    {validation.touched.password_retype && validation.errors.password_retype ? (
                                                        <FormFeedback type="invalid"><div>{validation.errors.password_retype}</div></FormFeedback>
                                                    ) : null}
                                                </div>

                                                {/*<div className="mb-4">*/}
                                                {/*    <p className="mb-0 fs-12 text-muted fst-italic">Регистрируясь, вы соглашаетесь с нашими*/}
                                                {/*    <Link to="#" className="text-primary text-decoration-underline fst-normal fw-medium"> Условиями</Link></p>*/}
                                                {/*</div>*/}

                                                <div className="mt-4">
                                                    <button className="btn btn-outline-primary w-100" type="submit">Регистрация</button>
                                                </div>

                                                {/*<div className="mt-4 text-center">*/}
                                                {/*    <div className="signin-other-title">*/}
                                                {/*        <h5 className="fs-13 mb-4 title text-muted">Create account with</h5>*/}
                                                {/*    </div>*/}

                                                {/*    <div>*/}
                                                {/*        <button type="button" className="btn btn-primary btn-icon waves-effect waves-light"><i className="ri-facebook-fill fs-16"></i></button>{" "}*/}
                                                {/*        <button type="button" className="btn btn-danger btn-icon waves-effect waves-light"><i className="ri-google-fill fs-16"></i></button>{" "}*/}
                                                {/*        <button type="button" className="btn btn-dark btn-icon waves-effect waves-light"><i className="ri-github-fill fs-16"></i></button>{" "}*/}
                                                {/*        <button type="button" className="btn btn-info btn-icon waves-effect waves-light"><i className="ri-twitter-fill fs-16"></i></button>*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                            </Form>
                                        </div>
                                    </CardBody>
                                </Card>

                                <div className="mt-4 text-center">
                                    <p className="mb-0">Уже есть аккаунт ? <Link to="/login" className="fw-semibold text-primary text-decoration-underline"> Войти </Link> </p>
                                </div>

                            </Col>
                        </Row>
                    </Container>
                </div>
            </ParticlesAuth>
        </React.Fragment>
    );
};

export default Register;
