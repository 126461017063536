import React, {useEffect, useState} from "react";

//Import Breadcrumb
import BreadCrumb from "../../../../src/Components/Common/BreadCrumb";
import FeatherIcon from 'feather-icons-react';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";

import classnames from "classnames";
import {Link, useNavigate} from "react-router-dom";
import {
  updateBotIntegrationApi,
  getBotIntegrationApi, getAccountsApi, getBotSettingsApi
} from "../../../helpers/backend_helper";
import {toast, ToastContainer} from "react-toastify";
import EventLogTable from "../../../Components/Common/EventLogTable";
import {Switch} from "@mui/material";
import ProfileSettings from "./ProfileSettings";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const BotIntegration = () => {
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});

  const [integration, setIntegration] = useState({
    "allowed": false,
    "enabled": false,
    "external_token": '',
    "pricelist_xlsx": null,
    "last_uploaded_formatted": null,
    "event_logs": [
        // { formatted_created: '10.02.2024 08:30:15', log_type: 'Вход', description: 'Пользователь вошел в систему' },
        // { formatted_created: '10.02.2024 09:15:42', log_type: 'Выход', description: 'Пользователь вышел из системы' },
    ],  // readOnly
  });
  const [startingExternalToken, setStartingExternalToken] = useState('')
  const [settings, setSettings] = useState({
    username: '',
    email: '',
    // password: '',
    // kaspi_login: '',
    // kaspi_password: '',
    // kaspi_company: '',
    // kaspi_merchant_id: '',
    skip_merchant_id: '',
    price_change_step: 2,
    city: '',
    cities: [],
  });

  const [cities, setCities] = useState([]);

  const [activeTab, setactiveTab] = useState(1);
  const [passedSteps, setPassedSteps] = useState([1]);
  const [modal, setModal] = useState(false);
  const [deletemodal, setDeleteModal] = useState(false);
  const [accountsApi, setAccountsApi] = useState({});


  const fetchSettings = async () => {
    try {
      const response = await getBotSettingsApi();
      setSettings(response);
      setCities(response.cities);
    } catch (error) {
      setSettings(null);
      // toast.error("Не удалось получить настройки аккаунта")
    }
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  const hasPermissions = (permissions) => {
    return accountsApi.results && accountsApi.current && permissions.includes(accountsApi.results[accountsApi.current].access)
  }

  const getAccessObjects = () => {
    return String(accountsApi?.results && accountsApi.current && accountsApi?.results[accountsApi.current]?.access_objects);
  }

  const fetchIntegration = () => {
    getBotIntegrationApi()
        .then((response) => {
          setIntegration(response);
          setStartingExternalToken(response.external_token);
        })
        .catch((error) => {});
  };

  const fetchAccounts = () => {
    getAccountsApi()
        .then((data) => {setAccountsApi(data)})
        .catch(() => {})
  }

  useEffect(() => {
    fetchIntegration();
    fetchAccounts();
  }, []);

  const updateIntegration = async (updatedData, successMsg) => {
    try {
      setErrors({});
      const response = await updateBotIntegrationApi(updatedData);
      setIntegration(response);
      toast.success(successMsg);
      if (integration.external_token && integration.external_token !== startingExternalToken) {
        localStorage.removeItem('access_token');
        setTimeout(()=>{
          navigate('/api-integration');
        }, 1000)
        setTimeout(()=>{
          navigate('/bot-integration');
        }, 2000)


        // window.location.reload();
      }
    } catch (error) {
      if (error?.response?.data?.errors &&
          error?.response?.status === 400) {
        setErrors(error.response.data.errors);
      } else {
        toast.error("Не удалось обновить настройки интеграции");
      }
    }
  };

  const handleSaveIntegrationForm = async (event) => {
    event.preventDefault();
    const {...updatedData} = integration;
    await updateIntegration(updatedData, 'Настройки интеграции обновлены');
  }

  const handleInputChange = (event) => {
    if (event.target.type === "checkbox") {
      const { name, checked } = event.target;
      setIntegration({
        ...integration,
        [name]: checked
      });
    }else {
      let { name, value } = event.target;
      // if (name==='external_token') {
      //   setExternalTokenChanged(true);
      // }

      if (value === 'false') {
        value = false;
      }else if (value === 'true') {
        value = true;
      }

      setIntegration({
        ...integration,
        [name]: value
      });

    }
  };

  // const handleUploadProducts = async (event) => {
  //   try {
  //     const response = await generatePricelistApi();
  //     if (response.success) {
  //       toast.success(response.message);
  //       fetchIntegration();
  //     }else{
  //       toast.error(response.message);
  //     }
  //   } catch (error) {
  //     if (error?.response?.data && error?.response?.status === 400) {
  //       toast.error(error.response.data.message);
  //     } else {
  //       toast.error("Возникла ошибка. Повторите попытку позже");
  //     }
  //   }
  // }


  // const handleIntegrationFieldChange = (event) => {
  //   const { name, value } = event.target;
  //   setIntegration((prevFormData) => ({
  //     ...prevFormData,
  //     [name]: value,
  //   }));
  // };


  // -------------------------------------------------------------------------------------------------------------------

  const toggledeletemodal = () => {
    setDeleteModal(!deletemodal);
  };

  const togglemodal = () => {
    setModal(!modal);
  };

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab];

      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab);
        setPassedSteps(modifiedSteps);
      }
    }
  }

  const [showProfileSettings, setShowProfileSettings] = useState(false);
  const toggleAdditionalSettings = () => {
    setShowProfileSettings(!showProfileSettings);
  };

  /*const productState = [
    {
      options: [
        {label: "Select State...", value: "Select State"},
        {label: "Alabama", value: "Alabama"},
        {label: "Alaska", value: "Alaska"},
        {label: "American Samoa", value: "American Samoa"},
        {label: "California", value: "California"},
        {label: "Colorado", value: "Colorado"},
        {label: "District Of Columbia", value: "District Of Columbia"},
        {label: "Florida", value: "Florida"},
        {label: "Georgia", value: "Georgia"},
        {label: "Guam", value: "Guam"},
        {label: "Hawaii", value: "Hawaii"},
        {label: "Idaho", value: "Idaho"},
        {label: "Kansas", value: "Kansas"},
        {label: "Louisiana", value: "Louisiana"},
        {label: "Montana", value: "Montana"},
        {label: "Nevada", value: "Nevada"},
        {label: "New Jersey", value: "New Jersey"},
        {label: "New Mexico", value: "New Mexico"},
        {label: "New York", value: "New York"},
      ],
    },
  ];*/
  /*const productCountry = [
    {
      options: [
        {label: "Select Country...", value: "Select Country"},
        {label: "United States", value: "United States"},
      ],
    },
  ];*/

  document.title = "CRMBEK : Настройки интеграции с IBot";

  return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid style={{marginBottom: '48px'}}>
            <BreadCrumb pageTitle="Интеграции" title="IBot Интеграция"/>

            {hasPermissions(['owner']) ?
                (integration?.allowed ? (
                <Row>
                  <Col xl="1" ></Col>
                  <Col xl="10" >
                    <Card>
                      <CardBody className="checkout-tab">
                        <Form autoComplete="off" onSubmit={handleSaveIntegrationForm}>
                          <div className="step-arrow-nav mt-n3 mx-n3 mb-3">
                            <Nav
                                className="nav-pills nav-justified custom-nav"
                                role="tablist"
                            >
                              <NavItem role="presentation" className="position-relative">
                                {/*{integration?.enabled === true &&
                                    activeTab !== 1 && (
                                    <div className="position-absolute d-inline-block"
                                         style={{right: '10px', top: '10px', color: '#ff5454'}}>
                                      Не заполнены статусы
                                      &nbsp;
                                      <span
                                        className="fw-bold"
                                        style={{
                                          display: 'inline-block',
                                          width: '1.3em',
                                          height: '1.3em',
                                          border: '2px solid #ff5454',
                                          borderRadius: '50%',
                                          lineHeight: '1.2em',
                                          textAlign: 'center',
                                          fontSize: '1.1em'
                                        }}
                                      >
                                        !
                                      </span>
                                    </div>
                                )}*/}
                                <NavLink href="#"
                                         style={{padding: '34px'}}
                                         className={classnames({
                                           active: activeTab === 1,
                                           done: (activeTab <= 4 && activeTab >= 0)
                                         }, "fs-15 lh-1")}
                                         onClick={() => {
                                           toggleTab(1);
                                         }}
                                >
                                  <i className="ri-user-2-line fs-16 p-2 bg-primary-subtle text-primary rounded-circle align-middle me-2"></i>
                                  Настройки интеграции с IBot
                                </NavLink>
                              </NavItem>
                              <NavItem role="presentation" className="position-relative">
                                {/*{Object.keys(integration?.api_not_synced_orders || {}).length > 0 && activeTab !== 2 && (
                                  <div className="position-absolute d-inline-block"
                                       style={{right: '10px', top: '10px', color: '#ff5454'}}>
                                    Не синхр. заказы
                                    &nbsp;
                                    <span className="fw-bold"
                                      style={{
                                        display: 'inline-block',
                                        width: '1.3em',
                                        height: '1.3em',
                                        border: '2px solid #ff5454',
                                        borderRadius: '50%',
                                        lineHeight: '1.2em',
                                        textAlign: 'center',
                                        fontSize: '1.1em'
                                      }}
                                    >
                                    !
                                  </span>
                                  </div>
                                )}*/}
                                <NavLink
                                    href="#"
                                    style={{padding: '34px'}}
                                    className={classnames({
                                      active: activeTab === 2,
                                      done: activeTab <= 4 && activeTab > 1
                                    }, "fs-15 lh-1")}
                                    onClick={() => {
                                      toggleTab(2);
                                    }}
                                >
                                  <i className="ri-truck-line fs-16 p-2 bg-primary-subtle text-primary rounded-circle align-middle me-2"></i>
                                  Журнал событий
                                </NavLink>
                              </NavItem>
                              {/*<NavItem role="presentation">
                                <NavLink href="#"
                                         className={classnames({
                                           active: activeTab === 3,
                                           done: activeTab <= 4 && activeTab > 2
                                         }, "fs-15 p-3")}
                                         onClick={() => {
                                           toggleTab(3);
                                         }}
                                >
                                  <i className="ri-bank-card-line fs-16 p-2 bg-primary-subtle text-primary rounded-circle align-middle me-2"></i>
                                  Payment Info
                                </NavLink>
                              </NavItem>*/}
                              {/*<NavItem role="presentation">
                                <NavLink href="#"
                                         className={classnames({
                                           active: activeTab === 4,
                                           done: activeTab <= 4 && activeTab > 3
                                         }, "fs-15 p-3")}
                                         onClick={() => {
                                           toggleTab(4);
                                         }}
                                >
                                  <i className="ri-checkbox-circle-line fs-16 p-2 bg-primary-subtle text-primary rounded-circle align-middle me-2"></i>
                                  Finish
                                </NavLink>
                              </NavItem>*/}
                            </Nav>
                          </div>

                          <TabContent activeTab={activeTab}>
                            <TabPane tabId={1} id="pills-bill-info">
                              <div>
                                <h5 className="mb-1">Основные настройки</h5>
                                <p className="text-muted mb-4">
                                  Пожалуйста заполните поля ниже для активации
                                </p>
                                {Object.entries(errors).length > 0 && (
                                  <div className="mb-4" style={{color: '#ff5454'}}>
                                    <span
                                      className="fw-bold"
                                      style={{
                                        display: 'inline-block',
                                        width: '1.3em',
                                        height: '1.3em',
                                        border: '2px solid #ff5454',
                                        borderRadius: '50%',
                                        lineHeight: '1.2em',
                                        textAlign: 'center',
                                        fontSize: '1.1em'
                                      }}
                                    >
                                      !
                                    </span>
                                    &nbsp;
                                    {/*{Object.entries(errors) && (*/}
                                    <ul style={{display: 'inline-block'}}>
                                      {Object.values(errors).map((error) =>(
                                          <li>{error}</li>
                                      ))}
                                    </ul>
                                    {/*)}*/}
                                  </div>
                                )}
                                {startingExternalToken && !settings && (
                                    <div className="mb-4" style={{color: '#ff5454'}}>
                                    <span
                                        className="fw-bold"
                                        style={{
                                          display: 'inline-block',
                                          width: '1.3em',
                                          height: '1.3em',
                                          border: '2px solid #ff5454',
                                          borderRadius: '50%',
                                          lineHeight: '1.2em',
                                          textAlign: 'center',
                                          fontSize: '1.1em'
                                        }}
                                    >
                                      !
                                    </span>
                                      &nbsp;
                                      <span className="fs-16 mx-1">Не удалось авторизовать пользователя.</span>
                                      <div className="fs-12 text-muted">(Интеграция не будет работать. Проверьте ваш внешний токен)</div>
                                      {/*<ul style={{display: 'inline-block'}}>
                                        {Object.values(errors).map((error) =>(
                                            <li>{error}</li>
                                        ))}
                                      </ul>*/}
                                    </div>
                                )}

                              </div>

                              <div>
                                <Row>
                                  <Col sm={6}>
                                    <div className="mb-3">
                                      <Label
                                          htmlFor="integration-token"
                                          className="form-label"
                                      >
                                        Внешний токен
                                      </Label>
                                      <Input
                                          name="external_token"
                                          type="text"
                                          className="form-control"
                                          id="integration-token"
                                          placeholder="Укажите ваш внешний токен из IBot кабинета"
                                          onChange={handleInputChange}
                                          value={integration?.external_token}
                                      />
                                    </div>
                                  </Col>

                                  <Col sm={6}>
                                    <div className="mb-3">
                                    <Label
                                          htmlFor="integration-enabled"
                                          className="form-label d-block"
                                      >
                                        Интеграция включена
                                      </Label>
                                      <Switch
                                          name="enabled"
                                          style={{padding: '8px'}}
                                          // readOnly={true}
                                          // disabled={true}
                                          type="checkbox"
                                          className="form-control"
                                          id="integration-enabled"
                                          checked={integration.enabled}
                                          onChange={handleInputChange}
                                      />
                                      {/*<Input*/}
                                      {/*    name="enabled"*/}
                                      {/*    style={{padding: '8px'}}*/}
                                      {/*    // readOnly={true}*/}
                                      {/*    // disabled={true}*/}
                                      {/*    type="checkbox"*/}
                                      {/*    className="form-control"*/}
                                      {/*    id="integration-enabled"*/}
                                      {/*    checked={integration.enabled}*/}
                                      {/*    onChange={handleInputChange}*/}
                                      {/*    // placeholder="Enter last name"*/}
                                      {/*/>*/}
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col sm={6}>
                                    <span className="text-muted mb-4">
                                      Это основной токен для подключении к удаленному аккаунту IBot.kz.
                                      Связав аккаунты, вы сможете в одном приложении и управлять товарами и ставить выгодные цены автоматически.
                                    </span>
                                  </Col>

                                  <Col sm={6}>
                                    <span className="text-muted mb-4">
                                      Включив интеграцию, ваши товары начнут синхронизироваться с удалённым аккаунтом бота
                                      , и вам не нужно управлять прайслистом бота самостоятельно, мы всё сделаем за Вас.
                                    </span>
                                  </Col>
                                </Row>

                                <Row className="my-4 align-items-center">
                                  <Col md={4}>
                                    <h5 className="mb-lg-0 mx-lg-2">Ваш excel прайслист</h5>
                                  </Col>
                                  {/*<Col md={1}>*/}
                                  {/*  <button*/}
                                  {/*      type="button"*/}
                                  {/*      className={`lh-1 btn ${integration?.pricelist_update_enabled ? 'btn-outline-warning' : 'btn-outline-primary'}`}*/}
                                  {/*      name="pricelist_update_enabled"*/}
                                  {/*      value={integration?.pricelist_update_enabled ? 'false' : 'true'}*/}
                                  {/*      onClick={handleInputChange}*/}
                                  {/*  >*/}
                                  {/*    {integration?.pricelist_update_enabled ? 'Выключить' : 'Включить'}*/}
                                  {/*  </button>*/}
                                  {/*</Col>*/}

                                  <Card style={{width: '97%', margin: '0 auto'}}>
                                    <CardHeader>
                                      <p style={{maxWidth: '500px'}}>
                                        Ссылка на прайс-лист в формате EXCEL, который содержит список ваших товаров,
                                        цены и наличие на складах. Выгрузка остатков и цен выполняется каждый день перед
                                        загрузкой вашего прайслиста на удалённый бот аккаунт.</p>
                                    </CardHeader>
                                    <CardBody>
                                      <div className="mb-3">
                                        <Label htmlFor="pricelist_xlsx" className="form-label">
                                          EXCEL прайс-лист
                                        </Label>
                                        <Row>
                                          <Col xl={6} md={9}>
                                            <Input
                                                readOnly={true}
                                                style={{padding: '8px'}}
                                                type="text"
                                                className="form-control"
                                                id="pricelist_xlsx"
                                                name="pricelist_xlsx"
                                                value={integration?.pricelist_xlsx || ""}
                                            />
                                          </Col>
                                          <Col xxl={3} xl={4}>
                                            {integration?.pricelist_xlsx
                                                ?
                                                <div>
                                                  <a href={integration.pricelist_xlsx}
                                                      type="button"
                                                      className="btn btn-outline-info btn-sm fs-13"
                                                      style={{padding: '2px 10px'}}
                                                      // onClick={handleUploadProducts}
                                                  >
                                                    Скачать
                                                  </a>
                                                  {integration?.last_uploaded_formatted &&
                                                      <div className="text-muted fs-12" style={{fontFamily: 'Montserrat, sans-serif'}}>
                                                        Последняя выгрузка: {integration.last_uploaded_formatted}
                                                      </div>
                                                  }
                                                </div>
                                                :
                                                <span className="text-muted text-sm">
                                                  После первой выгрузки товаров тут появися ссылка на ваш прайслист
                                                </span>
                                            }
                                          </Col>
                                        </Row>
                                      </div>
                                    </CardBody>
                                  </Card>
                                </Row>

                                <div className="d-flex align-items-start gap-3 mt-3">
                                  <button
                                      type="submit"
                                      className="btn btn-success lh-1"
                                      // onClick={() => {
                                      //   toggleTab(activeTab + 1);
                                      // }}
                                  >
                                    {/*<i className="ri-truck-line label-icon align-middle fs-16 ms-2"></i>*/}
                                    Сохранить настройки
                                  </button>
                                  <button
                                      type="button"
                                      className="btn btn-info btn-label right ms-auto nexttab lh-1"
                                      onClick={() => {
                                        toggleTab(activeTab + 1);
                                      }}
                                  >
                                    <i className="ri-truck-line label-icon align-middle fs-16 ms-2"></i>
                                    Перейти к журналу
                                  </button>
                                </div>

                                {/*Additional*/}
                                <Card className="mt-3">
                                  <div className="mt-1 p-3 d-flex justify-content-between align-items-center" onClick={toggleAdditionalSettings} style={{ cursor: 'pointer' }}>
                                    <div>
                                      <h5 className="mb-1">Дополнительные настройки</h5>
                                      <p className="text-muted mb-0">
                                        Это настройки удалённого аккаунта. Опционально
                                      </p>
                                      </div>
                                    {showProfileSettings ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                                  </div>
                                  {showProfileSettings &&
                                      <ProfileSettings
                                          settings={settings}
                                          setSettings={setSettings}
                                          cities={cities}
                                          setCities={setCities}
                                      />
                                  }
                                </Card>

                              </div>
                            </TabPane>

                            <TabPane tabId={2}>
                              <div>
                                <h5 className="mb-1">Журнал событий интеграции</h5>
                                <p className="text-muted mb-4">
                                  Здесь отображается всё что происходит в пределах интеграции
                                </p>
                              </div>
                              {Object.keys(integration?.api_not_synced_orders || {}).length > 0 && (
                                  <div className="mt-4" style={{color: 'rgb(255, 84, 84)'}}>
                                  <span className="fw-bold"
                                        style={{
                                          display: 'inline-block',
                                          width: '1.3em',
                                          height: '1.3em',
                                          border: '2px solid #ff5454',
                                          borderRadius: '50%',
                                          lineHeight: '1.2em',
                                          textAlign: 'center',
                                          fontSize: '1.1em'
                                        }}
                                  >
                                    !
                                  </span>
                                    &nbsp;
                                    Не синхронизированные заказы:
                                    <ul>
                                      {integration?.api_not_synced_orders &&
                                          Object.keys(integration.api_not_synced_orders).length > 0 &&
                                          Object.entries(integration.api_not_synced_orders).map(([name, val], index) => (
                                        <li key={index}>{name}: {val}</li>
                                      ))}
                                    </ul>
                                  </div>
                              )}

                              <EventLogTable events={integration?.event_logs} rows={1}/>

                              {/*<div className="mt-4">
                                <div className="d-flex align-items-center mb-2">
                                  <div className="flex-grow-1">
                                    <h5 className="fs-14 mb-0">Saved Address</h5>
                                  </div>
                                  <div className="flex-shrink-0">
                                    <button
                                        type="button"
                                        className="btn btn-sm btn-success mb-3"
                                        onClick={togglemodal}
                                    >
                                      Add Address
                                    </button>
                                  </div>
                                </div>
                                <Row className="gy-3">
                                  <Col lg={4} sm={6}>
                                    <div className="form-check card-radio">
                                      <Input
                                          id="shippingAddress01"
                                          name="shippingAddress"
                                          type="radio"
                                          className="form-check-input"
                                          defaultChecked
                                      />
                                      <Label
                                          className="form-check-label"
                                          htmlFor="shippingAddress01"
                                      >
                                      <span className="mb-4 fw-semibold d-block text-muted text-uppercase">
                                        Home Address
                                      </span>

                                        <span className="fs-14 mb-2 d-block">
                                        Marcus Alfaro
                                      </span>
                                        <span className="text-muted fw-normal text-wrap mb-1 d-block">
                                        4739 Bubby Drive Austin, TX 78729
                                      </span>
                                        <span className="text-muted fw-normal d-block">
                                        Mo. 012-345-6789
                                      </span>
                                      </Label>
                                    </div>
                                    <div className="d-flex flex-wrap p-2 py-1 bg-light rounded-bottom border mt-n1">
                                      <div>
                                        <Link
                                            to="#"
                                            className="d-block text-body p-1 px-2"
                                            onClick={togglemodal}
                                        >
                                          <i className="ri-pencil-fill text-muted align-bottom me-1"></i>
                                          Edit
                                        </Link>
                                      </div>
                                      <div>
                                        <Link
                                            to="#"
                                            className="d-block text-body p-1 px-2"
                                            onClick={toggledeletemodal}
                                        >
                                          <i className="ri-delete-bin-fill text-muted align-bottom me-1"></i>
                                          Remove
                                        </Link>
                                      </div>
                                    </div>
                                  </Col>
                                  <Col lg={4} sm={6}>
                                    <div className="form-check card-radio">
                                      <Input
                                          id="shippingAddress02"
                                          name="shippingAddress"
                                          type="radio"
                                          className="form-check-input"
                                      />
                                      <Label
                                          className="form-check-label"
                                          htmlFor="shippingAddress02"
                                      >
                                      <span className="mb-4 fw-semibold d-block text-muted text-uppercase">
                                        Office Address
                                      </span>

                                        <span className="fs-14 mb-2 d-block">
                                        James Honda
                                      </span>
                                        <span className="text-muted fw-normal text-wrap mb-1 d-block">
                                        1246 Virgil Street Pensacola, FL 32501
                                      </span>
                                        <span className="text-muted fw-normal d-block">
                                        Mo. 012-345-6789
                                      </span>
                                      </Label>
                                    </div>
                                    <div className="d-flex flex-wrap p-2 py-1 bg-light rounded-bottom border mt-n1">
                                      <div>
                                        <Link
                                            to="#"
                                            className="d-block text-body p-1 px-2"
                                            onClick={togglemodal}
                                        >
                                          <i className="ri-pencil-fill text-muted align-bottom me-1"></i>
                                          Edit
                                        </Link>
                                      </div>
                                      <div>
                                        <Link
                                            to="#"
                                            className="d-block text-body p-1 px-2"
                                            onClick={toggledeletemodal}
                                        >
                                          <i className="ri-delete-bin-fill text-muted align-bottom me-1"></i>
                                          Remove
                                        </Link>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>

                                <div className="mt-4">
                                  <h5 className="fs-14 mb-3">Shipping Method</h5>

                                  <Row className="g-4">
                                    <Col lg={6}>
                                      <div className="form-check card-radio">
                                        <Input
                                            id="shippingMethod01"
                                            name="shippingMethod"
                                            type="radio"
                                            className="form-check-input"
                                        />
                                        <Label
                                            className="form-check-label"
                                            htmlFor="shippingMethod01"
                                        >
                                        <span className="fs-20 float-end mt-2 text-wrap d-block">
                                          Free
                                        </span>
                                          <span className="fs-14 mb-1 text-wrap d-block">
                                          Free Delivery
                                        </span>
                                          <span className="text-muted fw-normal text-wrap d-block">
                                          Expected Delivery 3 to 5 Days
                                        </span>
                                        </Label>
                                      </div>
                                    </Col>
                                    <Col lg={6}>
                                      <div className="form-check card-radio">
                                        <Input
                                            id="shippingMethod02"
                                            name="shippingMethod"
                                            type="radio"
                                            className="form-check-input"
                                            defaultChecked
                                        />
                                        <Label
                                            className="form-check-label"
                                            htmlFor="shippingMethod02"
                                        >
                                        <span className="fs-20 float-end mt-2 text-wrap d-block">
                                          $24.99
                                        </span>
                                          <span className="fs-14 mb-1 text-wrap d-block">
                                          Express Delivery
                                        </span>
                                          <span className="text-muted fw-normal text-wrap d-block">
                                          Delivery within 24hrs.
                                        </span>
                                        </Label>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </div>*/}

                              <div className="d-flex align-items-start gap-3 mt-4">
                                <button
                                    type="button"
                                    className="btn btn-light btn-label previestab"
                                    onClick={() => {
                                      toggleTab(activeTab - 1);
                                    }}
                                >
                                  <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>
                                  Вернуться к настройкам
                                </button>
                                {/*<button
                                    type="button"
                                    className="btn btn-primary btn-label right ms-auto nexttab"
                                    onClick={() => {
                                      toggleTab(activeTab + 1);
                                    }}
                                >
                                  <i className="ri-bank-card-line label-icon align-middle fs-16 ms-2"></i>
                                  Continue to Payment
                                </button>*/}
                              </div>
                            </TabPane>

                            <TabPane tabId={3}>
                              <div>
                                <h5 className="mb-1">Payment Selection</h5>
                                <p className="text-muted mb-4">
                                  Please select and enter your billing information
                                </p>
                              </div>

                              <Row className="g-4">
                                <Col lg={4} sm={6}>
                                  <div>
                                    <div className="form-check card-radio">
                                      <Input
                                          id="paymentMethod01"
                                          name="paymentMethod"
                                          type="radio"
                                          className="form-check-input"
                                      />
                                      <Label
                                          className="form-check-label"
                                          htmlFor="paymentMethod01"
                                      >
                                      <span className="fs-16 text-muted me-2">
                                        <i className="ri-paypal-fill align-bottom"></i>
                                      </span>
                                        <span className="fs-14 text-wrap">
                                        Paypal
                                      </span>
                                      </Label>
                                    </div>
                                  </div>
                                </Col>
                                <Col lg={4} sm={6}>
                                  <div>
                                    <div className="form-check card-radio">
                                      <Input
                                          id="paymentMethod02"
                                          name="paymentMethod"
                                          type="radio"
                                          className="form-check-input"
                                          defaultChecked
                                      />
                                      <Label
                                          className="form-check-label"
                                          htmlFor="paymentMethod02"
                                      >
                                      <span className="fs-16 text-muted me-2">
                                        <i className="ri-bank-card-fill align-bottom"></i>
                                      </span>
                                        <span className="fs-14 text-wrap">
                                        Credit / Debit Card
                                      </span>
                                      </Label>
                                    </div>
                                  </div>
                                </Col>

                                <Col lg={4} sm={6}>
                                  <div>
                                    <div className="form-check card-radio">
                                      <Input
                                          id="paymentMethod03"
                                          name="paymentMethod"
                                          type="radio"
                                          className="form-check-input"
                                      />
                                      <Label
                                          className="form-check-label"
                                          htmlFor="paymentMethod03"
                                      >
                                      <span className="fs-16 text-muted me-2">
                                        <i className="ri-money-dollar-box-fill align-bottom"></i>
                                      </span>
                                        <span className="fs-14 text-wrap">
                                        Cash on Delivery
                                      </span>
                                      </Label>
                                    </div>
                                  </div>
                                </Col>
                              </Row>

                              <div
                                  className="collapse show"
                                  id="paymentmethodCollapse"
                              >
                                <Card className="p-4 border shadow-none mb-0 mt-4">
                                  <Row className="gy-3">
                                    <Col md={12}>
                                      <Label htmlFor="cc-name" className="form-label">
                                        Name on card
                                      </Label>
                                      <Input
                                          type="text"
                                          className="form-control"
                                          id="cc-name"
                                          placeholder="Enter name"
                                      />
                                      <small className="text-muted">
                                        Full name as displayed on card
                                      </small>
                                    </Col>

                                    <Col md={6}>
                                      <Label
                                          htmlFor="cc-number"
                                          className="form-label"
                                      >
                                        Credit card number
                                      </Label>
                                      <Input
                                          type="text"
                                          className="form-control"
                                          id="cc-number"
                                          placeholder="xxxx xxxx xxxx xxxx"
                                      />
                                    </Col>

                                    <Col md={3}>
                                      <Label
                                          htmlFor="cc-expiration"
                                          className="form-label"
                                      >
                                        Expiration
                                      </Label>
                                      <Input
                                          type="text"
                                          className="form-control"
                                          id="cc-expiration"
                                          placeholder="MM/YY"
                                      />
                                    </Col>

                                    <Col md={3}>
                                      <Label htmlFor="cc-cvv" className="form-label">
                                        CVV
                                      </Label>
                                      <Input
                                          type="text"
                                          className="form-control"
                                          id="cc-cvv"
                                          placeholder="xxx"
                                      />
                                    </Col>
                                  </Row>
                                </Card>
                                <div className="text-muted mt-2 fst-italic">

                                  <FeatherIcon
                                      icon="lock"
                                      className="text-muted icon-xs"
                                  />{" "}

                                  Your transaction is secured with SSL encryption
                                </div>
                              </div>

                              <div className="d-flex align-items-start gap-3 mt-4">
                                <button
                                    type="button"
                                    className="btn btn-light btn-label previestab"
                                    onClick={() => {
                                      toggleTab(activeTab - 1);
                                    }}
                                >
                                  <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>
                                  Back to Shipping
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary btn-label right ms-auto nexttab"
                                    onClick={() => {
                                      toggleTab(activeTab + 1);
                                    }}
                                >
                                  <i className="ri-shopping-basket-line label-icon align-middle fs-16 ms-2"></i>
                                  Complete Order
                                </button>
                              </div>
                            </TabPane>

                            <TabPane tabId={4} id="pills-finish">
                              <div className="text-center py-5">
                                <div className="mb-4">
                                  <lord-icon
                                      src="https://cdn.lordicon.com/lupuorrc.json"
                                      trigger="loop"
                                      colors="primary:#0ab39c,secondary:#405189"
                                      style={{width: "120px", height: "120px"}}
                                  ></lord-icon>
                                </div>
                                <h5>Thank you ! Your Order is Completed !</h5>
                                <p className="text-muted">
                                  You will receive an order confirmation email with
                                  details of your order.
                                </p>

                                <h3 className="fw-semibold">
                                  Order ID:{" "}
                                  <Link
                                      to="/apps-ecommerce-order-details"
                                      className="text-decoration-underline"
                                  >
                                    VZ2451
                                  </Link>
                                </h3>
                              </div>
                            </TabPane>
                          </TabContent>
                        </Form>
                      </CardBody>
                    </Card>
                  </Col>

                  {/*<Col xl={4}>*/}
                  {/*  <Card>*/}
                  {/*    <CardHeader>*/}
                  {/*      <div className="d-flex justify-content-between align-items-center">*/}
                  {/*        <div> /!*className="flex-grow-1"*!/*/}
                  {/*          <h5 className="card-title mb-0">Привязка статусов</h5>*/}
                  {/*        </div>*/}
                  {/*        <div> /!*className="flex-grow-1"*!/*/}
                  {/*          <button*/}
                  {/*              className="btn btn-success btn-sm fs-13"*/}
                  {/*              // onClick={handleSaveStatusesClick}*/}
                  {/*          >Сохранить</button>*/}
                  {/*        </div>*/}
                  {/*      </div>*/}
                  {/*    </CardHeader>*/}
                  {/*    <CardBody>*/}
                  {/*      <div className="table-responsive table-card">*/}
                  {/*      <table className="table table-borderless align-middle mb-0">*/}
                  {/*          <thead className="table-light text-muted">*/}
                  {/*          <tr>*/}
                  {/*            <th scope="col">*/}
                  {/*              Статус в Kaspi магазине*/}
                  {/*            </th>*/}
                  {/*            /!*<th scope="col">Product Info</th>*!/*/}
                  {/*            <th scope="col" className="text-end">*/}
                  {/*              Статус в CRM*/}
                  {/*            </th>*/}
                  {/*          </tr>*/}
                  {/*          {errors.mapped_statuses?.user_status || errors.mapped_statuses?.fixed_status ? (*/}
                  {/*            <tr>*/}
                  {/*              <th colSpan="2">*/}
                  {/*                <div style={{ color: "#ed5e5e", width: "100%" }}>*/}
                  {/*                  <ul style={{ marginBottom: 0 }}>*/}
                  {/*                    {errors.mapped_statuses?.user_status && (<li>{errors.mapped_statuses.user_status}</li>)}*/}
                  {/*                    {errors.mapped_statuses?.fixed_status && (<li>{errors.mapped_statuses.fixed_status}</li>)}*/}
                  {/*                  </ul>*/}
                  {/*                </div>*/}
                  {/*              </th>*/}
                  {/*            </tr>*/}
                  {/*          ) : null}*/}
                  {/*          </thead>*/}
                  {/*          <tbody>*/}
                  {/*          {integration?.kaspi_statuses &&*/}
                  {/*              Object.keys(integration.kaspi_statuses).length > 0 &&*/}
                  {/*              Object.entries(integration.kaspi_statuses).map(([value, label], key) => (*/}
                  {/*              <React.Fragment key={key}>*/}
                  {/*                <tr>*/}
                  {/*                  <td>{label}</td>*/}
                  {/*                  <td className="text-end">*/}
                  {/*                    <select*/}
                  {/*                        className="form-select"*/}
                  {/*                        data-plugin="choices"*/}
                  {/*                        value={getStatusForFixedStatus(value)}*/}
                  {/*                        onChange={(event) => handleStatusFieldChange(value, event)}*/}
                  {/*                    >*/}
                  {/*                      <option value="" style={{fontWeight: 'bold'}}>- не выбрано -</option>*/}
                  {/*                      {statuses.length > 0 && statuses.map(({id, name, mark}) => (*/}
                  {/*                          <option key={id} value={id}>{name}</option>*/}
                  {/*                      ))}*/}
                  {/*                    </select>*/}
                  {/*                    <div className="invalid" style={{color: 'red', lineHeight: 1.1, marginTop: '5px'}}>*/}
                  {/*                      /!*Поле не может быть пустым*!/*/}
                  {/*                      {errors?.mapped_statuses*/}
                  {/*                          ? errors?.mapped_statuses[key]?.user_status*/}
                  {/*                          : null}*/}
                  {/*                    </div>*/}
                  {/*                  </td>*/}
                  {/*                </tr>*/}
                  {/*              </React.Fragment>*/}
                  {/*          ))}*/}
                  {/*          </tbody>*/}
                  {/*      </table>*/}
                  {/*      </div>*/}
                  {/*    </CardBody>*/}
                  {/*  </Card>*/}
                  {/*</Col>*/}
                  <ToastContainer closeButton={false}/>
                </Row>) : (
                    <Col className='text-center'>
                      <h4 className="text-center my-5">
                        Подключите ваши товары к боту IBot.kz для управления ценами на маркетплейсе Kaspi!
                      </h4>
                      <a className='btn btn-success'
                         target='_blank'
                         href='https://api.whatsapp.com/send/?phone=77054811035&text=%D0%A1%D0%B0%D0%BB%D0%B5%D0%BC%D0%B5%D1%82%D1%81i%D0%B7%20%D0%B1%D0%B5,%20%D0%BA%D0%B0%D0%BA%20%D0%BF%D0%BE%D0%B4%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D1%8C%20%D0%94%D0%B5%D0%BC%D0%BE%20ibot%20%D0%BD%D0%B0%203%20%D0%B4%D0%BD%D1%8F'
                      >
                        <div className='d-flex justify-content-between align-items-center gap-1'>
                          <i className='ri-thumb-up-fill'></i>
                          <span>Подключить</span>
                        </div>
                      </a>
                    </Col>
                )
            ) : (
                <h3 className="text-center my-5" style={{opacity: 0.3}}>У вас нет прав для просмотра данной страницы</h3>
            )}
          </Container>
        </div>
        {/* modal Delete Address */}
        <Modal
            isOpen={deletemodal}
            role="dialog"
            autoFocus={true}
            centered
            id="removeItemModal"
            toggle={toggledeletemodal}
        >
          <ModalHeader toggle={() => {
            setDeleteModal(!deletemodal);
          }}>
          </ModalHeader>
          <ModalBody>
            <div className="mt-2 text-center">
              <lord-icon
                  src="https://cdn.lordicon.com/gsqxdxog.json"
                  trigger="loop"
                  colors="primary:#0ab39c,secondary:#405189"
                  style={{width: "100px", height: "100px"}}
              ></lord-icon>
              <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                <h4>Are you Sure ?</h4>
                <p className="text-muted mx-4 mb-0">
                  Are you Sure You want to Remove this Address ?
                </p>
              </div>
            </div>
            <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
              <button
                  type="button"
                  className="btn w-sm btn-light"
                  onClick={() => {
                    setDeleteModal(!deletemodal);
                  }}
              >
                Close
              </button>
              <button type="button" className="btn w-sm btn-danger" onClick={() => {
                setDeleteModal(!deletemodal);
              }}>
                Yes, Delete It!
              </button>
            </div>
          </ModalBody>
        </Modal>

        {/* modal Add Address */}
        <Modal
            isOpen={modal}
            role="dialog"
            autoFocus={true}
            centered
            id="addAddressModal"
            toggle={togglemodal}
        >
          <ModalHeader
              toggle={() => {
                setModal(!modal);
              }}
          >
            <h5 className="modal-title" id="addAddressModalLabel">
              Address
            </h5>
          </ModalHeader>
          <ModalBody>
            <div>
              <div className="mb-3">
                <Label for="addaddress-Name" className="form-label">
                  Name
                </Label>
                <Input
                    type="text"
                    className="form-control"
                    id="addaddress-Name"
                    placeholder="Enter Name"
                />
              </div>

              <div className="mb-3">
                <Label for="addaddress-textarea" className="form-label">
                  Address
                </Label>
                <textarea
                    className="form-control"
                    id="addaddress-textarea"
                    placeholder="Enter Address"
                    rows="2"
                ></textarea>
              </div>

              <div className="mb-3">
                <Label for="addaddress-Name" className="form-label">
                  Phone
                </Label>
                <Input
                    type="text"
                    className="form-control"
                    id="addaddress-Name"
                    placeholder="Enter Phone No."
                />
              </div>

              <div className="mb-3">
                <Label for="state" className="form-label">
                  Address Type
                </Label>
                <select className="form-select" id="state" data-plugin="choices">
                  <option value="homeAddress">Home (7am to 10pm)</option>
                  <option value="officeAddress">Office (11am to 7pm)</option>
                </select>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
                type="button"
                className="btn btn-light"
                onClick={() => {
                  setModal(!modal);
                }}
            >
              Close
            </button>
            <button
                type="button"
                className="btn btn-success"
                onClick={() => {
                  setModal(!modal);
                }}
            >
              Save
            </button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
  );
};

export default BotIntegration;
