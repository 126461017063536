import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {getBotIntegrationApi, getIntegrationApi} from "../helpers/backend_helper";

const Navdata = () => {
    const history = useNavigate();
    const [botIntegrationAllowed, setBotIntegrationAllowed] = useState(false);
    const [integration, setIntegration] = useState({"enabled": false});

    //state data
    const [isDashboard, setIsDashboard] = useState(false);
    const [isApps, setIsApps] = useState(false);
    const [isReceptions, setIsReceptions] = useState(false);
    const [isOrders, setIsOrders] = useState(false);
    const [isBotIntegration, setIsBotIntegration] = useState(false);
    const [isAuth, setIsAuth] = useState(false);
    const [isAnalytics, setIsAnalytics] = useState(false);
    const [isSettings, setIsSettings] = useState(false);
    const [isGoods, setIsGoods] = useState(false);
    const [isPages, setIsPages] = useState(false);
    const [isBaseUi, setIsBaseUi] = useState(false);
    const [isAdvanceUi, setIsAdvanceUi] = useState(false);
    const [isForms, setIsForms] = useState(false);
    const [isTables, setIsTables] = useState(false);
    const [isCharts, setIsCharts] = useState(false);
    const [isIcons, setIsIcons] = useState(false);
    const [isMaps, setIsMaps] = useState(false);
    const [isMultiLevel, setIsMultiLevel] = useState(false);

    // Apps
    const [isEmail, setEmail] = useState(false);
    const [isSubEmail, setSubEmail] = useState(false);
    const [isEcommerce, setIsEcommerce] = useState(false);
    const [isProjects, setIsProjects] = useState(false);
    const [isTasks, setIsTasks] = useState(false);
    const [isCRM, setIsCRM] = useState(false);
    const [isCrypto, setIsCrypto] = useState(false);
    const [isInvoices, setIsInvoices] = useState(false);
    const [isSupportTickets, setIsSupportTickets] = useState(false);
    const [isNFTMarketplace, setIsNFTMarketplace] = useState(false);
    const [isJobs, setIsJobs] = useState(false);
    const [isJobList, setIsJobList] = useState(false);
    const [isCandidateList, setIsCandidateList] = useState(false);

    // Authentication
    const [isSignIn, setIsSignIn] = useState(false);
    const [isSignUp, setIsSignUp] = useState(false);
    const [isPasswordReset, setIsPasswordReset] = useState(false);
    const [isPasswordCreate, setIsPasswordCreate] = useState(false);
    const [isLockScreen, setIsLockScreen] = useState(false);
    const [isLogout, setIsLogout] = useState(false);
    const [isSuccessMessage, setIsSuccessMessage] = useState(false);
    const [isVerification, setIsVerification] = useState(false);
    const [isError, setIsError] = useState(false);

    // Pages
    const [isProfile, setIsProfile] = useState(false);
    const [isLanding, setIsLanding] = useState(false);

    // Charts
    const [isApex, setIsApex] = useState(false);

    // Multi Level
    const [isLevel1, setIsLevel1] = useState(false);
    const [isLevel2, setIsLevel2] = useState(false);

    const [iscurrentState, setIscurrentState] = useState('Dashboard');

    function updateIconSidebar(e) {
        if (e && e.target && e.target.getAttribute("subitems")) {
            const ul = document.getElementById("two-column-menu");
            const iconItems = ul.querySelectorAll(".nav-icon.active");
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove("active");
                var id = item.getAttribute("subitems");
                if (document.getElementById(id))
                    document.getElementById(id).classList.remove("show");
            });
        }
    }
    const fetchIntegration = () => {
        getIntegrationApi()
            .then((response) => {
                setIntegration(response);
            })
            .catch((error) => {
            });
    };
    const fetchBotIntegration = () => {
        getBotIntegrationApi()
            .then((response) => {
                const { allowed } = response;
                setBotIntegrationAllowed(allowed);
            })
            .catch((error) => {
            });
    };

    // useEffect(() => {
    //     fetchIntegration();
    // }, [isOrders]);

    useEffect(() => {
        document.body.classList.remove('twocolumn-panel');
        // document.body.classList.remove("vertical-sidebar-enable");
        if (iscurrentState !== 'Dashboard') {
            setIsDashboard(false);
        }
        if (iscurrentState !== 'Apps') {
            setIsApps(false);
        }
        if (iscurrentState !== 'Orders') {
            setIsOrders(false);
        }
        if (iscurrentState !== 'BotIntegration') {
            setIsBotIntegration(false);
        }
        if (iscurrentState !== 'Receptions') {
            setIsReceptions(false);
        }
        if (iscurrentState !== 'Analytics') {
            setIsAnalytics(false);
        }
        if (iscurrentState !== 'Settings') {
            setIsSettings(false);
        }
        if (iscurrentState !== 'Goods') {
            setIsGoods(false);
        }
        if (iscurrentState !== 'Auth') {
            setIsAuth(false);
        }
        if (iscurrentState !== 'Pages') {
            setIsPages(false);
        }
        if (iscurrentState !== 'BaseUi') {
            setIsBaseUi(false);
        }
        if (iscurrentState !== 'AdvanceUi') {
            setIsAdvanceUi(false);
        }
        if (iscurrentState !== 'Forms') {
            setIsForms(false);
        }
        if (iscurrentState !== 'Tables') {
            setIsTables(false);
        }
        if (iscurrentState !== 'Charts') {
            setIsCharts(false);
        }
        if (iscurrentState !== 'Icons') {
            setIsIcons(false);
        }
        if (iscurrentState !== 'Maps') {
            setIsMaps(false);
        }
        if (iscurrentState !== 'MuliLevel') {
            setIsMultiLevel(false);
        }
        if (iscurrentState === 'Widgets') {
            history("/widgets");
            document.body.classList.add('twocolumn-panel');
        }
        if (iscurrentState === 'Acceptance') {
            history("/apps-invoices-create");
            document.body.classList.add('twocolumn-panel');
        }
        if (iscurrentState === 'Returns') {
            history("/returns");
            document.body.classList.add('twocolumn-panel');
        }
        // if (iscurrentState === 'Goods') {
        //     history("/products");
        //     document.body.classList.add('twocolumn-panel');
        // }
        if (iscurrentState === 'Leftovers') {
            history("/error");
            document.body.classList.add('twocolumn-panel');
        }
        if (iscurrentState === 'Expenses') {
            history("/expenses");
            document.body.classList.add('twocolumn-panel');
        }
        // if (iscurrentState === 'Settings') {
        //     // history("/profile");
        //     document.body.classList.add('twocolumn-panel');
        // }
        // if (iscurrentState === 'Orders') {
        //     history("/orders");
        //     document.body.classList.add('twocolumn-panel');
        // }
        if (iscurrentState !== 'Landing') {
            setIsLanding(false);
        }
    }, [
        history,
        iscurrentState,
        isDashboard,
        isApps,
        isOrders,
        isBotIntegration,
        isReceptions,
        isAuth,
        isAnalytics,
        isSettings,
        isGoods,
        isPages,
        isBaseUi,
        isAdvanceUi,
        isForms,
        isTables,
        isCharts,
        isIcons,
        isMaps,
        isMultiLevel
    ]);

    const menuItems = [
        {
            label: "Меню",
            isHeader: true,
        },
        {
            id: "orders",
            label: "Заказы",
            useImg: "/orders.svg",
            // icon: "las la-clipboard-list",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsOrders(!isOrders);
                setIscurrentState('Orders');
                updateIconSidebar(e);
            },
            stateVariables: isOrders,
            subItems: [
                {
                    id: 1,
                    label: "Все заказы",
                    link: "/orders",
                    parentId: "orders"
                },
                // {
                //     id: 2,
                //     label: "Каспи доставка",
                //     link: "/orders_kaspi_delivery",
                //     parentId: "orders"
                // },
                // {
                //     id: 3,
                //     label: "Доставка курьером",
                //     link: "/orders_delivery",
                //     parentId: "orders"
                // }
                // These subitems will be added only if integration is enabled
                ...(integration?.enabled ? [
                {
                  id: 2,
                  label: "Каспи доставка",
                  link: "/orders_kaspi_delivery",
                  parentId: "orders"
                },
                {
                  id: 3,
                  label: "Доставка курьером",
                  link: "/orders_delivery",
                  parentId: "orders"
                }
              ] : [])
            ]
        },
        {
            id: "returns",
            label: "Возвраты и отмены",
            useImg: "/productReturn.png",
            link: "/returns",
            click: function (e) {
                e.preventDefault();
                setIscurrentState('Returns');
            }
        },

// {
//         id: "icons",
//         label: "Icons",
//         icon: "ri-compasses-2-line",
//         link: "/#",
//         click: function (e) {
//             e.preventDefault();
//             setIsIcons(!isIcons);
//             setIscurrentState('Icons');
//             updateIconSidebar(e);
//         },
//         stateVariables: isIcons,
//         subItems: [
//             { id: "remix", label: "Remix", link: "/icons-remix", parentId: "icons" },
//             { id: "boxicons", label: "Boxicons", link: "/icons-boxicons", parentId: "icons" },
//             { id: "materialdesign", label: "Material Design", link: "/icons-materialdesign", parentId: "icons" },
//             { id: "lineawesome", label: "Line Awesome", link: "/icons-lineawesome", parentId: "icons" },
//             { id: "feather", label: "Feather", link: "/icons-feather", parentId: "icons" },
//             { id: "crypto", label: "Crypto SVG", link: "/icons-crypto", parentId: "icons" },
//         ],
//     },
 
    {
        id: "goods",
        label: "Товары",
        useImg: "/products.png",
        link: "/products",
        click: function (e) {
            e.preventDefault();
            setIsGoods(!isGoods);
            setIscurrentState('Goods');
            updateIconSidebar(e);
        },
        stateVariables: isGoods,
        // subItems: []
    },
    {
        id: "leftovers",
        label: "Остатки",
        useImg: "/box.png",
        link: "/remains",
        click: function (e) {
            e.preventDefault();
            setIscurrentState('Leftovers');
        }
    },
    // {
    //     id: "storages",
    //     label: "Склады",
    //     useImg: "/storages.png",
    //     link: "/storages",
    //     click: function (e) {
    //         e.preventDefault();
    //         setIscurrentState('Remains');
    //     }
    // },

    {
        id: "receptions",
        label: "Приемки",
        useImg: "/receptions.png",
        // link: "/#",
        link: "/receptions",
        click: function (e) {
            e.preventDefault();
            setIsReceptions(!isReceptions);
            setIscurrentState('Receptions');
            updateIconSidebar(e);
        },
        stateVariables: isReceptions,
        // subItems: [
        //
        //     // {
        //     //     id: 1,
        //     //     label: "Добавить приемку",
        //     //     link: "/receptions/:id",
        //     //     parentId: "receptions",
        //     // },
        //     {
        //         id: 1,
        //         label: "Все приемки",
        //         link: "/receptions",
        //         parentId: "receptions"
        //     },
        //
        //
        // ]
    },
    {
        id: "expenses",
        label: "Расходы",
        useImg: "/expenses.png",
        link: "/expenses",
        click: function (e) {
            e.preventDefault();
            setIscurrentState('Expenses');
        }
    },
    {
        id: "analytics",
        label: "Аналитика",
        useImg: "/analytics.png",
        link: "/#",
        click: function (e) {
            e.preventDefault();
            setIsAnalytics(!isAnalytics);
            setIscurrentState('Analytics');
            updateIconSidebar(e);
        },
        stateVariables: isAnalytics,
        subItems: [
            {
                id: 1, 
                label: "Прибыльность", 
                link: "/profit", 
                parentId: "analytics",
            },
            { 
                id: 2, 
                label: "Обороты", 
                link: "/revolutions", 
                parentId: "analytics" 
            },
            
        ]
    },

    {
        id: "settings",
        label: "Настройки",
        useImg: "/settings.png",
        link: "/profile",
        click: function (e) {
            e.preventDefault();
            setIsSettings(!isSettings);
            setIscurrentState('Settings');
            updateIconSidebar(e);
        },
        stateVariables: isSettings,
        /*subItems: [
            {
                id: 1,
                label: "Профиль",
                link: "/profile",
                parentId: "settings",
            },
            {
                id: 2,
                label: "Организация",
                link: "/organization",
                parentId: "settings"
            },

        ]*/
    },
    {
        id: "api_integration",
        label: "API интеграция",
        // useImg: "/settings.png",
        icon: "ri-git-merge-fill",
        link: "/api-integration",
        click: function (e) {
            e.preventDefault();
            fetchIntegration();
            setIscurrentState('Integration');
        }
    },
    {
        id: "bot_integration",
        label: "Интеграция с ботом",
        useImg: "/orders.svg",
        // useImg: "/settings.png",
        icon: "ri-git-merge-fill",
        // link: "/bot-integration",
        // icon: "las la-clipboard-list",
        // link: "/#",
        click: function (e) {
            e.preventDefault();
            setIsBotIntegration(!isBotIntegration);
            fetchBotIntegration();
            setIscurrentState('BotIntegration');
            updateIconSidebar(e);
        },
        stateVariables: isBotIntegration,
        subItems: [
            {
                id: 1,
                label: "Настройки",
                link: "/bot-integration",
                parentId: "bot_integration"
            },
            ...(botIntegrationAllowed ? [
                {
                    id: 2,
                    label: "Прайслист",
                    link: "/bot-integration-pricelist",
                    parentId: "bot_integration"
                }
            ] : [])
        ]
    }];


    return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;