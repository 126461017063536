import React, { useState, useEffect } from "react";

import Flatpickr from "react-flatpickr";
import { Card, CardBody, Col, Container, Row, Label, Input} from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import { Russian } from "flatpickr/dist/l10n/ru.js"
import styles from "../Expenses/Expenses.module.css";
import PieChartAnalytics from "./PieChartAnalytics";
import {
    getAccountsApi,
    getCalculateTurnover,
    getSalesChannelsApi,
    getStoragesApi
} from "../../../helpers/backend_helper";
import Loader from "../../../Components/Common/Loader";
import PrintButton from "../../Prints/PrintButton";
import TagInput from "../../../Components/Common/TagInput";


function Revolutions() {
    const [turnoverData, setTurnoverData] = useState({});
    const [loading, setLoading] = useState(true);
    // Call initialFunction to get the default date range
    const initialDateFunction = () => {
        const initialDate = new Date();
        const firstDayOfMonth = new Date(initialDate.getFullYear(), initialDate.getMonth(), 1);
        return [firstDayOfMonth, initialDate];
    };
    const toLocale = (date) => {
        return date.toLocaleDateString(
            'ru',
            { year: 'numeric', month: '2-digit', day: '2-digit' }
        )
        .replace('.', '-')
        .replace('.', '-')
        .split('-')
        .reverse()
        .join('-')
    };
    const formatDates = (dates) => dates.map(date => toLocale(date));
    const defaultDateRange = initialDateFunction();
    const defaultDateRangeFormatted = formatDates(defaultDateRange);

    const [filters, setFilters] = useState({
        dates: {
          range_start: defaultDateRangeFormatted[0],
          range_end: defaultDateRangeFormatted[1]
      }
    });

    const [salesChannels, setSalesChannels] = useState([]);
    const [selectedSalesChannel, setSelectedSalesChannel] = useState("");

    const [storages, setStorages] = useState([]);
    const [selectedStorage, setSelectedStorage] = useState("");

    const [selectedWithoutTurnover, setSelectedWithoutTurnover] = useState("false");
    const [accounts, setAccounts] = useState({});
    const hasPermissions = (permissions) => {
    return accounts.results && accounts.current && permissions.includes(accounts.results[accounts.current].access)
    }
    const getAccessObjects = () => {
        return String(accounts?.results && accounts.current && accounts?.results[accounts.current]?.access_objects);
    }

    useEffect(() => {
    const fetchAccounts = () => {
      getAccountsApi()
      .then((data) => {
        setAccounts(data);
      })
      .catch(() => {
        // toast.error("Не удалось получить список аккаунтов", {
        //   position: "top-right",
        // });
      })
    }
    fetchAccounts();
    }, []);

    const handleStorageChange = (storageName) => {
      setSelectedStorage(storageName);
    };
    const handleSalesChannelChange = (salesChannelName) => {
      setSelectedSalesChannel(salesChannelName);
    };
    const handleWithoutTurnoverChange = (selectedWithoutTurnover) => {
      setSelectedWithoutTurnover(selectedWithoutTurnover);
    };

    const fetchSalesChannels = () => {
      getSalesChannelsApi()
        .then((response) => {
          setSalesChannels(response);
        })
        .catch(() => {});
    };
    const fetchStorages = () => {
      getStoragesApi()
        .then((response) => {
          setStorages(response);
        })
        .catch((error) => {});
    };

    useEffect(() => {
        // Function to fetch profit data
        const fetchTurnoverData = async () => {
          try {
            setLoading(true);
            let data = {};
            if ('dates' in filters) {
              Object.keys(filters['dates']).forEach(key => {
                data[key] = filters['dates'][key];
              });
            }
            if (selectedStorage) {
                data.storage = selectedStorage;
            }
            if (selectedSalesChannel) {
                data.sales_channel = selectedSalesChannel;
            }
            if (selectedWithoutTurnover) {
                data.without_turnover = selectedWithoutTurnover;
            }
            // Make the API call to fetch profit data
            const response = await getCalculateTurnover(data);
            // Extract profit data and totals from the response
            const { results, totals, turnover, no_turnover } = response;
            // Set the profit data and totals in the state
            setTurnoverData({ results, totals, turnover, no_turnover });
          } catch (error) {
            toast.error('Не удалось получить данные об оборотах');
          } finally {
            setLoading(false);
          }
        };
        // Call the fetchTurnoverData function
        fetchTurnoverData();
    }, [selectedStorage, selectedSalesChannel, selectedWithoutTurnover, filters]);

    useEffect(() => {
      fetchSalesChannels();
      fetchStorages();
    }, []);

    /// Поиск ///
    const [searchTurnoverDataTerm, setSearchTurnoverDataTerm] = useState("");
    const handleTurnoverDataChange = (event) => {
        setSearchTurnoverDataTerm(event.target.value);
    };
    const handleProductsChange = (tags) => {
        setSearchTurnoverDataTerm(tags);
    };

    const filteredTurnoverData = turnoverData?.results?.length > 0 && turnoverData.results.filter((turnover) => {
        // const turnoverDataMatch = (
        //     turnover.title.toLowerCase().includes(searchTurnoverDataTerm.toLowerCase()) ||
        //     turnover.code.toLowerCase().includes(searchTurnoverDataTerm.toLowerCase())
        // );
        const turnoverDataMatch = searchTurnoverDataTerm.length === 0 ||
            searchTurnoverDataTerm.some(tag =>
                turnover.title.toLowerCase().includes(tag.toLowerCase()) ||
                turnover.code.toLowerCase().includes(tag.toLowerCase())
            );
        return !searchTurnoverDataTerm || turnoverDataMatch;
    });

    document.title = "CRMBEK : Обороты";

    return (
        <React.Fragment>
            <div className="page-content pb-5">
                {hasPermissions(['owner']) ||
                getAccessObjects().includes('analytics') ? (
                <Container fluid>
                    <div className="mb-2" style={{marginLeft: '8.3%'}}>
                        <PrintButton json={turnoverData} filters={{
                            dates: filters['dates'],
                            storage: selectedStorage,
                            sales_channel: selectedSalesChannel
                        }} documentType={'turnover'} button={
                            <button
                                style={{ padding: "5px 15px" }}
                                className="btn btn-outline-primary add-btn me-1-bin-2-line"
                                onClick={() => {
                                }}
                            >
                                <img src="/printing.png" alt="printing" />
                                <span style={{ marginLeft: "6px" }}>Печать</span>
                            </button>
                        } />
                    </div>
                    <Col lg={10} style={{ margin: "0 auto" }}>
                        <Card className="">
                            <CardBody>
                                <div className="listjs-table" id="customerList">
                                    <Row className="g-4 mb-3">
                                        <Col className={styles.width}>
                                            <section className={styles.filter_section}>
                                                <h4 className={styles.title}>Обороты</h4>

                                                <div style={{flexGrow: 1}} className={styles.date_range}>
                                                    <Label
                                                        htmlFor="product-field"
                                                        className={`form-label ${styles.filter_label}`}
                                                    >
                                                        Дата
                                                    </Label>
                                                    <div className={`input-group`}>
                                                        <span className="input-group-text" id="basic-addon1"><i className="ri-calendar-2-line"></i></span>
                                                        <Flatpickr
                                                            placeholder="Выберите дату/период"
                                                            className={`form-control`}
                                                            options={{
                                                                mode: "range",
                                                                dateFormat: "d M, Y",
                                                                locale: Russian,
                                                                defaultDate: defaultDateRange,
                                                                onChange: function (selectedDates, dateStr, instance) {
                                                                    const formattedDates = formatDates(selectedDates);
                                                                    if (formattedDates.length === 2) {
                                                                        formattedDates[0] !== formattedDates[1]
                                                                            ? setFilters({ ...filters, dates: { range_start: formattedDates[0], range_end: formattedDates[1] } })
                                                                            : setFilters({ ...filters, dates: { date: formattedDates[0] } });
                                                                    }/* else {
                                                                        let updatedFilters = { ...filters };
                                                                        delete updatedFilters.dates;
                                                                        setFilters(updatedFilters)
                                                                    }*/

                                                                },
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={styles.filter_element}>
                                                    <Label
                                                        htmlFor="product-field"
                                                        className={`form-label ${styles.filter_label}`}
                                                    >
                                                        Товары
                                                    </Label>
                                                    {/*<Input*/}
                                                    {/*    type="text"*/}
                                                    {/*    placeholder="Поиск по товарам"*/}
                                                    {/*    className={`${styles.form_select}`}*/}
                                                    {/*    value={searchTurnoverDataTerm}*/}
                                                    {/*    onChange={handleTurnoverDataChange}*/}
                                                    {/*/>*/}
                                                    <TagInput
                                                        maxWidth='200px'
                                                        tags={searchTurnoverDataTerm}
                                                        onTagsChange={(tags) =>
                                                            handleProductsChange(tags)
                                                        }
                                                    />
                                                </div>
                                                <div className={styles.filter_element}>
                                                    <Label
                                                        htmlFor="product-field"
                                                        className={`form-label ${styles.filter_label}`}
                                                    >
                                                        Склад
                                                    </Label>
                                                    <select
                                                        value={selectedStorage}
                                                        onChange={(e) => {
                                                            const selectedStorage = e.target.value;
                                                            handleStorageChange(selectedStorage);
                                                        }}
                                                        className="form-select"
                                                        aria-label="Default select example"
                                                    >
                                                        <option style={{fontWeight: "bold"}} value="">
                                                            Склад
                                                        </option>
                                                        {storages.map((storage) => (
                                                            <option key={storage.id} value={storage.name}>
                                                                {storage.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className={styles.filter_element}>
                                                    <Label htmlFor="product-field"
                                                           className={`form-label ${styles.filter_label}`}>
                                                        Канал продаж
                                                    </Label>
                                                    <select
                                                        value={selectedSalesChannel}
                                                        onChange={(e) => {
                                                            const selectedSalesChannel = e.target.value;
                                                            handleSalesChannelChange(selectedSalesChannel);
                                                        }}
                                                        className="form-select"
                                                        aria-label="Default select example"
                                                    >
                                                        <option style={{fontWeight: "bold"}} value="">
                                                            Канал продаж
                                                        </option>
                                                        {salesChannels.map((salesChannel) => (
                                                            <option key={salesChannel.id} value={salesChannel.name}>
                                                                {salesChannel.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className={styles.filter_element}>
                                                    <Label htmlFor="product-field"
                                                           className={`form-label ${styles.filter_label}`}>
                                                        Действия
                                                    </Label>
                                                    <select
                                                        value={selectedWithoutTurnover}
                                                        onChange={(e) => {
                                                            const selectedWithoutTurnover = e.target.value;
                                                            handleWithoutTurnoverChange(selectedWithoutTurnover);
                                                        }}
                                                        className="form-select"
                                                        aria-label="Default select example"
                                                    >
                                                        <option value="false">
                                                            Скрыть товары без оборота
                                                        </option>
                                                        <option value="true">
                                                            Показать товары без оборота
                                                        </option>
                                                    </select>
                                                </div>
                                            </section>
                                            {(turnoverData?.turnover && turnoverData?.no_turnover)
                                                ?
                                                <div className="card-body">
                                                    <PieChartAnalytics
                                                        data={[turnoverData.turnover, turnoverData.no_turnover]}
                                                        labels={['Оборот', 'Товары без оборота']}
                                                    />
                                                </div>
                                                :
                                                null
                                            }
                                        </Col>
                                    </Row>
                                </div>

                                {filteredTurnoverData ? (
                                    <div className="listjs-table mt-5" id="customerList">
                                        <Row className="">
                                            <Col className="col-sm-auto">
                                            </Col>
                                        </Row>

                                        <div className="table-responsive table-card" style={{
                                            maxHeight: "400px",
                                            overflowY: "auto",
                                            position: "relative"
                                        }}>

                                            <table className={styles.table_revolutions} id="customerTable"
                                                   style={{width: "100%"}}>
                                                <thead className={styles.title_thead}>
                                                <tr>
                                                    <th></th>
                                                    <th></th>
                                                    <th className={styles.border_l} colSpan="2">
                                                        <div><span>Было</span></div>
                                                    </th>
                                                    <th className={styles.border_l} colSpan="2"><span>Приход</span></th>
                                                    <th className={styles.border_l} colSpan="2"><span>Оборот</span></th>
                                                    <th className={styles.border_l} colSpan="2"><span>Стало</span></th>
                                                </tr>
                                                <tr style={{lineHeight: "normal"}}>
                                                    {/* <th>
                                                            <input
                                                                type="checkbox"
                                                                id="checkBoxAll"
                                                                className="form-check-input"
                                                                onClick={() => checkedAll()}
                                                            />
                                                        </th> */}
                                                    <td>
                                                        <span className="py-1">
                                                            Товар
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span className="py-1">
                                                            Артикул
                                                        </span>
                                                    </td>
                                                    <td className={styles.border_l}>
                                                        <span>
                                                            Кол-во
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span>
                                                            Сумма себес.
                                                        </span>
                                                    </td>
                                                    <td className={styles.border_l}>
                                                        <span>
                                                            Кол-во
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span>
                                                            Сумма себес.
                                                        </span>
                                                    </td>
                                                    <td className={styles.border_l}>
                                                        <span>
                                                            Кол-во
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span>
                                                            Сумма себес.
                                                        </span>
                                                    </td>
                                                    <td className={styles.border_l}>
                                                        <span>
                                                            Кол-во
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span>
                                                            Сумма себес.
                                                        </span>
                                                    </td>
                                                </tr>
                                                </thead>
                                                <tbody className={styles.scroll}>
                                                {loading ? ( // Show loader only when loading
                                                        <Loader/>
                                                    ) :
                                                    (filteredTurnoverData.map((item, key) => (
                                                        <tr key={key}>
                                                            <td className="d-flex align-items-center gap-3"
                                                                style={{marginLeft: '1rem'}}>
                                                                {item.image && (
                                                                    <div
                                                                        className="d-flex align-items-center"
                                                                        style={{width: '45px', height: '45px'}}
                                                                    >
                                                                        <img src={item.image}
                                                                             alt="товар"
                                                                             style={{maxHeight: '50px'}}
                                                                        />
                                                                    </div>
                                                                )}
                                                                <div className="text-start">
                                                                    {item.title}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {item.code}
                                                            </td>
                                                            <td className={styles.border_l}>
                                                                {item?.was.total_quantity}
                                                            </td>
                                                            <td>
                                                                {item?.was.total_cost}
                                                            </td>
                                                            <td className={styles.border_l}>
                                                                {item?.arrival.total_quantity}
                                                            </td>
                                                            <td>
                                                                {item?.arrival.total_cost}
                                                            </td>
                                                            <td className={styles.border_l}>
                                                                {item?.turnover.total_quantity}
                                                            </td>
                                                            <td>
                                                                {item?.turnover.total_cost}
                                                            </td>
                                                            <td className={styles.border_l}>
                                                                {item?.became.total_quantity}
                                                            </td>
                                                            <td>
                                                                {item?.became.total_cost}
                                                            </td>
                                                        </tr>
                                                    )))}
                                                </tbody>
                                                <tfoot className={styles.tfoot}>
                                                {/*<tr>*/}
                                                {/*     <th></th>*/}
                                                {/*    <td>*/}
                                                {/*        <span style={{fontWeight: "bold"}}>*/}
                                                {/*            Сумма*/}
                                                {/*        </span>*/}
                                                {/*    </td>*/}
                                                {/*    <td></td>*/}
                                                {/*    <td></td>*/}
                                                {/*    <td></td>*/}
                                                {/*    <td></td>*/}
                                                {/*    <td></td>*/}
                                                {/*    <td></td>*/}
                                                {/*    <td></td>*/}
                                                {/*    <td></td>*/}
                                                {/*    <td></td>*/}
                                                {/*</tr>*/}
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                ) : <h4 className="text-center mb-5 mt-5" style={{opacity: 0.5}}>Данных по оборотам не найдено</h4>}

                            </CardBody>
                            <ToastContainer closeButton={false}/>
                        </Card>
                    </Col>
                </Container>
                    ) : (
                  <h3 className="text-center my-5" style={{opacity: 0.3}}>У вас нет прав для просмотра данной страницы</h3>
                )}
            </div>
        </React.Fragment>)
}

export default Revolutions;