//Include Both Helper File with needed methods

import {
    getMe, postJwtLogin, postJwtRegisterCustom,
} from "../../../helpers/backend_helper";
import {setAuthorization} from "../../../helpers/api_helper";
import axios from "axios";
import { api } from "../../../config";
// import {getFirebaseBackend} from "../../../helpers/firebase_helper";
import {logoutUserSuccess} from "./reducer";
// import {apiError, loginSuccess, logoutUserSuccess} from "./reducer";


export const loginUser = (user, history, setErrorState) => async (dispatch) => {
    try {
        let response;
        if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {

            // const client_id = process.env.REACT_APP_CLIENT_PASSWORD_ID;
            // const client_secret = process.env.REACT_APP_CLIENT_PASSWORD_SECRET;
            const client_id = api.CLIENT_PASSWORD_ID;
            const client_secret = api.CLIENT_PASSWORD_SECRET;

            const grant_type = 'password';

            axios.defaults.headers.common['Authorization'] = 'null'; // prevent Authorization Header
            response = postJwtLogin({
                username: user.username,
                password: user.password,
                grant_type: grant_type,
                client_id: client_id,
                client_secret: client_secret
            })
        }

        var data = await response;

        if (data?.access_token) {
            setAuthorization(data.access_token);
            let userData = await getMe({});
            if (userData?.email_verified) {
                userData = JSON.stringify(userData);
                sessionStorage.setItem("authUser", userData);
                localStorage.setItem('access_token', data.access_token);
                localStorage.setItem('refresh_token', data.refresh_token);
                console.log('Logged in successfully!');
                history('/')
            }else{
                setErrorState('Пожалуйста подтвердите вашу почту чтобы войти.')
            }

            // dispatch(loginSuccess(data));

            // } else {
            //     console.warn(userData);
            //     setErrorState(userData.error_description);
            //     console.error('Login failed. Please check your credentials.');
            // }
        }else{
            console.error('Login failed.');
            console.error(data);
        }
    } catch (error) {
        // console.warn(error?.response?.status);
        // response.data.error
        if (error?.response?.data  && error?.response?.status === 400) {
            let err = error.response.data.error_description;
            err = err === 'Invalid credentials given.' ? 'Неверные данные для входа' : err
            setErrorState(err);
        }else{
            setErrorState('Не удалось установать соединение с сервером. Попробуйте позже.')
            console.error('Uncaught error:', error);
        }
        // dispatch(apiError(error));
    }
};

export const socialLogin = (type, history) => async (dispatch) => {
    try {
        let response;

        // if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        //     const fireBaseBackend = getFirebaseBackend();
        //     response = fireBaseBackend.socialLoginUser(type);
        // }
        //  else {
          response = postSocialLogin(data);
        // }

        const socialdata = await response;
        if (socialdata) {
            sessionStorage.setItem("authUser", JSON.stringify(response));
            // dispatch(loginSuccess(response));
            history('/dashboard')
        }

    } catch (error) {
        dispatch(apiError(error));
    }
};

export const registerUser = (user, setErrorsState, setSuccessState) => async (dispatch) => {
    try {
        let response;
        if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
            axios.defaults.headers.common['Authorization'] = null; // prevent Authorization Header
            response = postJwtRegisterCustom({
                email: user.email,
                password: user.password,
                password_retype: user.password_retype,
            })
        }

        var data = await response;

        if (data?.email) {
            setSuccessState(true);
        }else{
            console.error('Register failed.');
        }
    } catch (error) {
        // console.warn(error?.response?.status);
        // response.data.error
        if (error?.response?.data?.errors && error?.response?.status === 400) {
            setErrorsState(error.response.data.errors);
        }else{
            setErrorsState('Не удалось установать соединение с сервером. Попробуйте позже.')
            console.error('Uncaught error:', error);
        }
        // dispatch(apiError(error));
    }
};

// export const logoutUser = () => async (dispatch) => {
//     try {
//         sessionStorage.removeItem("authUser");
//         localStorage.removeItem('access_token');
//         localStorage.removeItem('refresh_token');
//
//         let fireBaseBackend = getFirebaseBackend();
//         if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
//             const response = fireBaseBackend.logout;
//             dispatch(logoutUserSuccess(response));
//         } else {
//             dispatch(logoutUserSuccess(true));
//         }
//
//     } catch (error) {
//         dispatch(apiError(error));
//     }
// };


export const logoutUser = () => async (dispatch) => {
    try {
        sessionStorage.removeItem("authUser");
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        dispatch(logoutUserSuccess(true));

    } catch (error) {
        error(error);
    }
};
