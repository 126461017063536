import axios from "axios";
import { api } from "../config";
import {isAuthenticated} from "../Routes/Auth/isAuthenticated";
import {toast} from "react-toastify";


// default
axios.defaults.baseURL = api.API_URL;
// content type
axios.defaults.headers.post["Content-Type"] = "application/json";

// content type
// const token = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).token : null;
const token = localStorage.getItem('access_token') ? localStorage.getItem('access_token') : null;
if(token)
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;

// intercepting to capture errors
axios.interceptors.response.use(
    function (response) {
      return response.data ? response.data : response;
    },
    async function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // console.warn(error.response.status);
      let message;

      switch (error.response.status) {
        case 500:
          message = "Internal Server Error";
          // toast("Не удалось выполнить операцию", { position: "top-right", hideProgressBar: false, className: 'bg-danger text-red', progress: undefined, toastId: "" })
          // toast.error('Не удалось выполнить операцию', { position: "top-right" })
          break;
        case 401:
          // localStorage.removeItem("access_token");
          // console.warn(error.config.url);
          sessionStorage.removeItem("authUser");
          // isAuthenticated();
          // location.reload();
          // isAuthenticated
          // history.push('/login');
          // axios.defaults.headers.common['Authorization'] = null;
          // isAuthenticated();
          message = "Invalid credentials";
          break;
        case 404:
          message = "Sorry! the data you are looking for could not be found";
          break;
        default:
          message = error.message || error;
      }
      return Promise.reject(error);
    }
);
/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
};

class APIClient {
  /**
   * Fetches data from given url
   */

      //  get = (url, params) => {
      //   return axios.get(url, params);
      // };
  get = (url, params) => {
    let response;

    let paramKeys = [];

    if (params) {
      Object.keys(params).map(key => {
        paramKeys.push(key + '=' + params[key]);
        return paramKeys;
      });

      const queryString = paramKeys && paramKeys.length ? paramKeys.join('&') : "";
      response = axios.get(`${url}?${queryString}`, params);
    } else {
      response = axios.get(`${url}`, params);
    }

    return response;
  };
  /**
   * post given data to url
   */
  create = (url, data) => {
    return axios.post(url, data);
  };
  /**
   * post form to url
   */
  post = (url, data) => {
    return axios.post(url, data, {
      headers: {
        'accept': 'application/json',
        'Accept-Language': 'en-US,en;q=0.8',
        'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
      }
    });
  };
  /**
   * Updates data
   */
  update = (url, data) => {
    return axios.patch(url, data);
  };

  put = (url, data) => {
    return axios.put(url, data);
  };
  /**
   * Delete
   */
  delete = (url, config) => {
    return axios.delete(url, { ...config });
  };
  deleteWithData = (url, data) => {
    return axios.delete(url, {
      data: data
    });
  };
}
const getLoggedinUser = () => {
  const user = sessionStorage.getItem("authUser");
  if (!user) {
    return null;
  } else {
    return JSON.parse(user);
  }
};

export { APIClient, setAuthorization, getLoggedinUser };